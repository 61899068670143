import headingIamge from "../../assets/headingIamge.gif";

interface HeadingProps {
  heading: string; // تعريف النوع للخاصية
}

function Heading({ heading }: HeadingProps) {
  return (
    <div className="mb-4 container flex flex-col justify-center items-center -mt-6">
      <img src={headingIamge} className="w-[80px]" alt="" />
      <h1 className="text-3xl font-extrabold leading-none tracking-tight text-gray-900 md:text-4xl lg:text-5xl text-center">
        {heading}
      </h1>
    </div>
  );
}

export default Heading;
