import {
  faFacebook,
  faSnapchat,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="bg-[#180337] p-8 text-white">
      <div className="container grid grid-cols-2 md:grid-cols-4 gap-10">
        <div className="grid grid-cols-1 gap-5 text-md font-semibold md:text-xl">
          <div className="">
            <Link className="hover:text-lime-600 transition-all" to={"#"}>
              من نحن
            </Link>
          </div>
          <div className="">
            <Link className="hover:text-lime-600 transition-all" to={"#"}>
              تواصل معنا
            </Link>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-5 text-lg font-semibold md:text-xl">
          <div className="">
            <Link to={"#"}>
              <FontAwesomeIcon
                className="hover:-translate-y-1 transition-transform"
                icon={faFacebook}
                color="#3c5a9a"
                size="2x"
              />
            </Link>
          </div>
          <div className="">
            <Link to={"#"}>
              <FontAwesomeIcon
                className="hover:-translate-y-1 transition-transform"
                icon={faYoutube}
                color="#ce1312"
                size="2x"
              />
            </Link>
          </div>
          <div className="">
            <Link to={"#"}>
              <FontAwesomeIcon
                className="hover:-translate-y-1 transition-transform"
                icon={faTwitter}
                color="#2daae1"
                size="2x"
              />
            </Link>
          </div>
          <div className="">
            <Link to={"#"}>
              <FontAwesomeIcon
                className="hover:-translate-y-1 transition-transform"
                icon={faSnapchat}
                color="#fffa37"
                size="2x"
              />
            </Link>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-5 text-md font-semibold md:text-xl">
          <div className="">
            <a
              href="https://wa.me/966552021145"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-lime-600 transition-all"
            >
              966552021145+
            </a>
          </div>
          <div className="">
            <a
              className="hover:text-lime-600 transition-all"
              href="mailto:info@7jozati.com"
            >
              info@7jozati.com
            </a>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-5 text-md font-semibold md:text-xl">
          <div className="">
            <Link className="hover:text-lime-600 transition-all" to={"#"}>
              سياسه الخصوصيه
            </Link>
          </div>
          <div className="">
            <Link className="hover:text-lime-600 transition-all" to={"#"}>
              تطبق الاحكام والشروط
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
