import React, { useEffect } from "react";
import ScrollToTop from "../../utils/ScrollToTop";
import Navbar from "./Navbar";
import { Outlet, useLocation } from "react-router";
import Footer from "./Footer";
import Banner from "../Banner";
import { useDispatch } from "react-redux";
import { useGetLogedUserQuery } from "../../redux/Service/api";
import {
  reduxLogin,
  reduxLogout,
  saveUserData,
} from "../../redux/Slices/authSlice";
import { useSelector } from "react-redux";

function RootLayout() {
  const location = useLocation();

  return (
    <>
      <ScrollToTop />
      <Navbar />
      {!(
        location.pathname === "/" ||
        location.pathname === "/categories" ||
        location.pathname === "/worker" ||
        location.pathname === "/workerLogin" ||
        location.pathname === "/workerhome"
      ) && <Banner />}
      <Outlet />
      <Footer />
    </>
  );
}

export default RootLayout;
