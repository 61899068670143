import { Button, Modal, Form, message, Input, Select } from "antd";
import { useState } from "react";

import {
  useAddCityMutation,
  useGetGovernmentsQuery,
} from "../../../redux/Service/api";

function AddCity() {
  const [form] = Form.useForm();
  const [addCityModal, setAddCityModal] = useState(false);
  const [modalKey, setModalKey] = useState(0);

  const { data: allGovernmentsData } = useGetGovernmentsQuery(undefined);

  const allGovernments = allGovernmentsData?.data?.data ?? [];

  const [addCity, { isLoading }] = useAddCityMutation();

  const handleCity = async (values: any) => {
    try {
      const res = await addCity({
        name: values.nameAR,
        name_en: values.nameEN,
        government_id: values.government_id,
      }).unwrap();
      form.resetFields();
      setModalKey((prev) => prev + 1);
      message.success(res?.message || "City added successfully");
      setAddCityModal(false);
    } catch (error) {
      console.error("Error adding city:", error);
      message.error("An error occurred while adding the city.");
    }
  };

  return (
    <>
      <div className="text-center my-3">
        <Button
          className="w-[150px]"
          type="primary"
          onClick={() => setAddCityModal(true)}
        >
          إضافة مدينة
        </Button>
      </div>
      <Modal
        key={modalKey}
        title="إضافة مدينة"
        style={{ top: 20 }}
        open={addCityModal}
        onCancel={() => setAddCityModal(false)}
        width={500} // Adjust width as needed
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleCity}
          initialValues={{
            nameAR: "",
            nameEN: "",
            government_id: "",
          }}
        >
          <Form.Item
            label="اسم المدينة عربي"
            name="nameAR"
            rules={[
              { required: true, message: "Please enter the Arabic name" },
            ]}
          >
            <Input placeholder="إدخال الاسم العربي" />
          </Form.Item>
          <Form.Item
            label="اسم المدينة (لغة أخرى)"
            name="nameEN"
            rules={[
              {
                required: true,
                message: "Please enter the name in another language",
              },
            ]}
          >
            <Input placeholder="إدخال الاسم باللغة الإنجليزية (اختياري)" />
          </Form.Item>
          <Form.Item
            label="المنطقة"
            name="government_id"
            rules={[{ required: true, message: "Please select a government" }]}
          >
            <Select
              showSearch
              placeholder="Select Government"
              optionFilterProp="label"
              options={allGovernments?.map((gov: any) => ({
                value: gov.id,
                label: gov.name,
              }))}
            />
          </Form.Item>
          <div className="flex justify-end">
            <Button htmlType="submit" type="primary" loading={isLoading}>
              حفظ
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}

export default AddCity;
