import bannerImg from '../../assets/Png/rebanner.png'
const Banner = () => {
	return (
		<>
			<div className={`bg-[#167bba] rounded-bl-[450px] mid:rounded-bl-[120px]`}>
				<div className='h-[30vh] w-[100%] flex justify-center'>
					<img className={`w-[18%] mid:w-[35%] h-full`} src={bannerImg} alt="" />
				</div>
			</div>

		</>
	)
}

export default Banner