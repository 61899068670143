import {
  Button,
  Input,
  Modal,
  Space,
  Table,
  TableColumnsType,
  message,
} from "antd";
import { useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { SearchOutlined, ExclamationCircleFilled } from "@ant-design/icons";
// Replace with your government query hook
import {
  useDeleteGovernmentMutation,
  useGetGovernmentsQuery,
} from "../../../redux/Service/api"; // Update path as needed

const { confirm } = Modal;

function TableRegionSettings() {
  const {
    data: governmentsData,
    isError: isGovernmentsError,
    isLoading: isGovernmentsLoading,
  } = useGetGovernmentsQuery(undefined);

  const [deleteGovernment, { isLoading: delIsLoading }] =
    // Replace with your government deletion mutation
    useDeleteGovernmentMutation(); // Update path and mutation name

  const allGovernments =
    governmentsData?.data?.data?.map((item: any, idx: number) => {
      return {
        idx: idx + 1,
        id: item.id,
        name: item.name,

        created_at: item.created_at,
        updated_at: item.updated_at,
      };
    }) ?? [];

  const [filteredInfo, setFilteredInfo] = useState<any>({});
  const [sortedInfo, setSortedInfo] = useState<any>({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<any>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: () => void,
    dataIndex: string
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const handleDeleteGovernment = async (id: any) => {
    confirm({
      title: "هل أنت متأكد أنك تريد حذف هذه المنطقة؟",
      icon: <ExclamationCircleFilled />,
      content: "عند النقر على موافق، سيتم إزالة هذه المنطقة؟.",
      async onOk() {
        try {
          const res = await deleteGovernment(id);
          res.data.message
            ? message.info(res.data.message)
            : message.success("تم حذف المنطقة بنجاح.");
        } catch (error) {
          console.log(error);
        }
      },
    });
  };

  const getColumnSearchProps = (dataIndex: string): any => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }: any) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button type="link" size="small" onClick={() => close()}>
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value: string, record: any) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible: any) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text: any) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  // serch end

  const columns: TableColumnsType<any> = [
    {
      title: "م",
      dataIndex: "idx",
      key: "idx",
      filteredValue: filteredInfo.id || null,
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === "idx" ? sortedInfo.order : null,
      ellipsis: true,
      width: 70,
    },
    {
      title: "اسم المنطقة",
      dataIndex: "name",
      key: "name",
      filteredValue: filteredInfo.name || null,
      ...getColumnSearchProps("name"),
      ellipsis: true,
      width: 150,
    },
    // Add more columns as needed, e.g., country, cities, etc.
    {
      title: "تاريخ الإنشاء",
      dataIndex: "created_at",
      key: "created_at",
      ellipsis: true,
      width: 150,
    },
    {
      title: "تاريخ التحديث",
      dataIndex: "updated_at",
      key: "updated_at",
      ellipsis: true,
      width: 150,
    },
    {
      title: "الإجراءات",
      key: "actions",
      render: (_, government) => (
        <div className="grid grid-cols-2 items-center justify-center gap-2">
          {/* Add edit button if needed */}
          <Button
            type="primary"
            danger
            onClick={() => handleDeleteGovernment(government.id)}
          >
            حذف
          </Button>
        </div>
      ),
      width: 130,
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={allGovernments}
      loading={isGovernmentsLoading}
      onChange={(pagination, filters, sorter) => {
        setFilteredInfo(filters);
        setSortedInfo(sorter);
      }}
      bordered
      rowKey="id"
      pagination={{
        showSizeChanger: true,
        pageSizeOptions: [9, 10, 20, 30, 50],
        showTotal: (total) => `الإجمالي: ${total}`,
      }}
      scroll={{ y: 400, x: 500 }}
    />
  );
}
export default TableRegionSettings;
