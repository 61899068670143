import Sponsored1 from "../../assets/Sponsored1.svg";
import Sponsored2 from "../../assets/Sponsored2.svg";
import Sponsored3 from "../../assets/Sponsored3.svg";
import Sponsored4 from "../../assets/Sponsored4.svg";
import Sponsored5 from "../../assets/Sponsored5.svg";
import Sponsored6 from "../../assets/Sponsored6.svg";
import Sponsored7 from "../../assets/Sponsored7.svg";
import Sponsored8 from "../../assets/Sponsored8.svg";

function Sponsored() {
  return (
    <div className="container mt-7 md:mt-14">
      <h1 className="text-xl md:text-3xl font-bold mb-4">برعايه</h1>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-10 justify-center">
        <div className="grid place-items-center">
          <img src={Sponsored1} alt="Sponsored1" />
        </div>
        <div className="grid place-items-center">
          <img src={Sponsored2} alt="Sponsored2" />
        </div>
        <div className="grid place-items-center">
          <img src={Sponsored3} alt="Sponsored3" />
        </div>
        <div className="grid place-items-center">
          <img src={Sponsored4} alt="Sponsored4" />
        </div>
        <div className="grid place-items-center">
          <img src={Sponsored5} alt="Sponsored4" />
        </div>
        <div className="grid place-items-center">
          <img src={Sponsored6} alt="Sponsored4" />
        </div>
        <div className="grid place-items-center">
          <img src={Sponsored7} alt="Sponsored4" />
        </div>
        <div className="grid place-items-center">
          <img src={Sponsored8} alt="Sponsored4" />
        </div>
      </div>
    </div>
  );
}

export default Sponsored;
