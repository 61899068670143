import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthenticated: false,
    userData: null,
    // ... other state properties
  },
  reducers: {
    reduxLogin: (state, action) => {
      console.log("reduxLogin Payload:", action.payload);
      state.isAuthenticated = true;
      state.userData = action.payload.userData;
      localStorage.setItem("hejozatiToken", action.payload.token);
    },
    reduxLogout: (state) => {
      state.isAuthenticated = false;
      state.userData = null;
      localStorage.removeItem("hejozatiToken");
    },
    saveUserData: (state, action) => {
      state.isAuthenticated = true;
      state.userData = action.payload;
    },
    // ... other reducers
  },
});

export const { reduxLogin, reduxLogout, saveUserData } = authSlice.actions;

export default authSlice.reducer;
