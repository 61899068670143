import { PhoneOutlined, MailOutlined, LinkedinOutlined, FacebookOutlined, XOutlined, InstagramOutlined, WhatsAppOutlined, CopyOutlined } from '@ant-design/icons';
import { Drawer, DrawerProps, RadioChangeEvent } from 'antd';
import { useState } from 'react';


const ContactUs = () => {
	const [open, setOpen] = useState(false);
	const [placement, setPlacement] = useState<DrawerProps['placement']>('right');

	const showDrawer = () => {
		setOpen(true);
	};

	const onChange = (e: RadioChangeEvent) => {
		setPlacement(e.target.value);
	};

	const onClose = () => {
		setOpen(false);
	};
	return (
		<div className='mb-3 pt-12 pb-12 flex flex-col gap-20 items-center container contactUs'>
			<div className='flex flex-col gap-5'>
				<h2 className='flex items-center flex-col gap-2 font-bold text-[20px] miilg:text-[20px] mid:text-[18px]'>
					اتصل بنا
				</h2>
				<p className="text-[#737373] text-[12px]">
					إذا كان لديك أي سؤال
					ونحن سعداء للمساعدة
				</p>
			</div>
			<div className='flex justify-center gap-32 miilg:flex-col miilg:gap-10 items-center'>
				<div className='flex flex-col items-center gap-5'>
					<div className='w-[50px] h-[50px] bg-[#CCFD04] flex justify-center items-center'>
						<PhoneOutlined className='text-[25px]' />

					</div>
					<p>+966555021010</p>
				</div>
				<div className='flex flex-col items-center gap-5'>
					<div className='w-[50px] h-[50px] bg-[#CCFD04] flex justify-center items-center'>
						<MailOutlined className='text-[25px]' />
					</div>
					<p className="text-[#737373]">contact@mafimushkil.services</p>
				</div>
			</div>
			<div className='flex flex-col gap-[20px] items-center'>
				<p className='text-[#737373] '>Get Connected</p>
				<div className='flex justify-center items-center gap-[20px]'>
					<div className='bg-[#282828] p-1	flex justify-center items-center'>
						<LinkedinOutlined className='text-[#ffff] text-[22px]' />
					</div>
					<div className='bg-[#282828] p-1	flex justify-center items-center'>
						<FacebookOutlined className='text-[#ffff] text-[22px]' />
					</div>
					<div className='bg-[#282828] p-1	flex justify-center items-center'>
						<XOutlined className='text-[#ffff] text-[22px]' />
					</div>
					<div className='bg-[#282828] p-1	flex justify-center items-center'>
						<InstagramOutlined className='text-[#ffff] text-[22px]' />
					</div>
					<div className='bg-[#282828] p-1	flex justify-center items-center'>
						<WhatsAppOutlined className='text-[#ffff] text-[22px]' />
					</div>
				</div>
			</div>

			<Drawer
				// title="Drawer with extra actions"
				placement={"bottom"}
				width={500}
				height={300}
				onClose={onClose}
				open={open}
				closeIcon={false}
				extra={
					<></>
				}
				className='h-[100px]'
			>
				<div className='flex flex-col items-center  justify-center h-ful	pt-10'>
					<div className='flex items-center gap-2 w-[300px] justify-start border-b-[1px] pb-5 pt-5 solid border-[#e3e3e3]'>
						<CopyOutlined />
						<p>نسخ رقم الهاتف</p>
					</div>
					<div className='flex items-center gap-2 w-[300px] justify-start border-b-[1px] pb-5 pt-5 solid border-[#e3e3e3]'>
						<PhoneOutlined />
						<p>اتصل بنا
						</p>
					</div>
					<div className='flex items-center gap-2 w-[300px] justify-start border-b-[1px] pb-5 pt-5 solid border-[#e3e3e3]'>
						<CopyOutlined />
						<p>فتح في الواتس اب
						</p>
					</div>
				</div>
			</Drawer>
			<div className='flex items-center gap-2 cursor-pointer' onClick={showDrawer}>


				<p className='flex flex-col items-end text-[#0D0D0D] text-[12px] font-bold'>
					<span>
						Pixel io
					</span>
					<span>
						Technologies
					</span>
				</p>
				<svg className='bg-[#000]' width="22" height="30" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M6.11358 7.44059H7.13717C9.1788 7.44059 8.79217 7.71318 8.79217 5.61315V4.73419V4.58677C8.79217 4.20849 8.48342 3.90252 8.10792 3.90252H7.51546H7.06764H5.85768C5.4794 3.90252 5.17343 3.59377 5.17343 3.21827V2.00553V0.795578C5.17343 0.420075 5.48218 0.111328 5.86046 0.111328H7.07042H8.28037C8.65866 0.111328 8.96462 0.420075 8.96462 0.795578V2.00553V2.60912V3.04582C8.96462 3.4241 9.27337 3.73007 9.64887 3.73007H10.0772H10.6752H11.8713C12.2496 3.73007 12.5555 4.03881 12.5555 4.41432V5.61036V6.80641C12.5555 7.1847 12.2468 7.49066 11.8713 7.49066H10.6752C8.57521 7.49066 8.85058 7.10681 8.85058 9.14844V10.172C8.85058 10.5503 8.54183 10.8563 8.16355 10.8563H7.13996H6.53081H6.26934C5.89106 10.8563 5.58509 11.165 5.58509 11.5405V12.5725V13.7685C5.58509 14.1468 5.27635 14.4528 4.89806 14.4528H3.70201H2.50597C2.12768 14.4528 1.82172 14.144 1.82172 13.7685V12.5725V11.3764C1.82172 10.9981 2.13046 10.6922 2.50597 10.6922H3.70201H4.7423C5.12058 10.6922 5.42655 10.3834 5.42655 10.0079V9.76871V9.15122V8.12763C5.42933 7.74934 5.73808 7.44059 6.11358 7.44059Z" fill="white" />
					<path d="M2.35851 4.28613H2.89255H3.4266C3.80489 4.28613 4.11085 4.59488 4.11085 4.97038V5.50443V6.0357C4.11085 6.41398 3.80211 6.72273 3.4266 6.72273H2.89255H2.35851C1.98022 6.72273 1.67426 6.41398 1.67426 6.0357V5.50443V4.97038C1.67426 4.59488 1.983 4.28613 2.35851 4.28613Z" fill="white" />
					<path d="M0.614537 1.39355H1.00673H1.39892C1.67707 1.39355 1.90237 1.62164 1.90237 1.89701V2.2892V2.68139C1.90237 2.95954 1.67429 3.18484 1.39892 3.18484H1.00673H0.614537C0.336386 3.18484 0.111084 2.95954 0.111084 2.68139V2.2892V1.89701C0.111084 1.61886 0.339167 1.39355 0.614537 1.39355Z" fill="white" />
				</svg>
			</div>
		</div>
	)
}

export default ContactUs