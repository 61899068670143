import "./whatsapp.css";
import whatsappIamge from "../../assets/whatsapp.png";

function Whatsapp() {
  return (
    // <!-- start whatsapp_massenger  -->
    <div className="whatsapp">
      <a
        href="https://api.whatsapp.com/send?phone=9660563067610"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={whatsappIamge} alt="whatsapp" />
      </a>
      <a
        href="https://api.whatsapp.com/send?phone=9660563067610"
        target="_blank"
        rel="noopener noreferrer"
      >
        <p>
          <strong>تواصل معنا</strong>
        </p>
      </a>
    </div>
  );
}

export default Whatsapp;
