import { Button, Input, InputRef, Space, Table } from "antd";
import { useRef, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import Heading from "../../../Components/Heading/Heading";
import { useGetPendingBillsQuery } from "../../../redux/Service/api";

function PendingInvoices() {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const { data, isLoading } = useGetPendingBillsQuery(undefined);

  // Handle search functionality
  const handleSearch = (
    selectedKeys: string[],
    confirm: any,
    dataIndex: string
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }: any) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button type="link" size="small" onClick={() => close()}>
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value: any, record: any) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    render: (text: string) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "رقم الفاتورة",
      dataIndex: "id",
      key: "id",
      ...getColumnSearchProps("id"),
    },
    {
      title: "تاريخ الإنشاء",
      dataIndex: "created_at",
      key: "created_at",
      ...getColumnSearchProps("created_at"),
      render: (text: string) => new Date(text).toLocaleDateString(),
    },
    {
      title: "تاريخ التحديث",
      dataIndex: "updated_at",
      key: "updated_at",
      ...getColumnSearchProps("updated_at"),
      render: (text: string) => new Date(text).toLocaleDateString(),
    },
    {
      title: "السعر",
      dataIndex: "price",
      key: "price",
      ...getColumnSearchProps("price"),
    },
    {
      title: "الخصم",
      dataIndex: "discount",
      key: "discount",
      ...getColumnSearchProps("discount"),
      render: (text: any) => (text ? text : "لا يوجد خصم"),
    },
    {
      title: "الحالة",
      dataIndex: "status",
      key: "status",
      ...getColumnSearchProps("status"),
    },
    {
      title: "طريقة الدفع",
      dataIndex: "payment_type",
      key: "payment_type",
      ...getColumnSearchProps("payment_type"),
      render: (text: any) => (text ? text : "لم يتم توفير طريقة الدفع"),
    },
    {
      title: "مبلغ الدفع",
      dataIndex: "payment_amount",
      key: "payment_amount",
      ...getColumnSearchProps("payment_amount"),
      render: (text: any) => (text ? `جنيه ${text}` : "لم يتم توفير المبلغ"),
    },
    {
      title: "الحالات السابقة",
      dataIndex: "statuses",
      key: "statuses",
      width: 200,
      render: (statuses: any[]) => (
        <div>
          {statuses.map((status, index) => (
            <div key={index}>
              {status.status} -{" "}
              {new Date(status.created_at).toLocaleDateString()}
            </div>
          ))}
        </div>
      ),
    },
  ];

  return (
    <>
      <Heading heading="الفواتير المعلقة" />
      <Table
        columns={columns}
        dataSource={data?.data?.data} // Adjusted to match the structure of the response
        loading={isLoading}
        bordered
        scroll={{ x: 1200, y: 350 }}
        rowKey="id"
      />
    </>
  );
}

export default PendingInvoices;
