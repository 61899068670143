import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";

import { message } from "antd";
import { useGetCategoryDetailsQuery } from "../../redux/Service/api";
import { useState } from "react";
import LoadingSkeltonCard from "../../Components/utils/LoadingSkeltonCard";
import { saveSelectedServices } from "../../redux/Slices/userOrderSlice";
import { useDispatch } from "react-redux";

const CategoryServices = () => {
  const { categoryId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedServices, setselectedServices] = useState<any>([]);
  const selectedServiesIds = selectedServices.map((item: any) => item.id);
  const {
    data: allCategorySevicesData,
    isError: isAllCategoryServicesError,
    isLoading: isAllCategoryServicesLoading,
    error: allCategoryServicesError,
  } = useGetCategoryDetailsQuery(categoryId);
  const categoryServices = allCategorySevicesData?.data ?? null;

  const handleServiceClick = (service: any) => {
    if (selectedServiesIds.includes(service.id)) {
      setselectedServices(
        selectedServices.filter((item: any) => item.id !== service.id)
      );
    } else {
      setselectedServices([...selectedServices, service]);
    }
  };

  const handleContinueClick = (e: any) => {
    e.preventDefault();
    console.log("selectedServices", selectedServices);
    // return;

    if (selectedServices && selectedServices.length > 0) {
      dispatch(saveSelectedServices(selectedServices));
      navigate(
        `/AvailableProviders/${categoryId}/${selectedServiesIds.join("-")}`
      );
    } else {
      message.error("يجب اختيار خدمة واحدة على الاقل");
    }
  };
  return (
    <div className="container pb-20 placeholder">
      <div className="flex justify-between items-center">
        <h2 className=" font-bold text-[20px] w-full flex justify-center text-center miilg:text-[20px] mid:text-[18px] mid:mt-6 mid:mb-6  mt-12 mb-12">
          <span className="ani bg-[#110A62] mid:text-[15px] text-[#ffff] mid:w-[80%] w-[28%] pt-3 pb-3 block">
            {categoryServices?.name || "الخدمات"}
          </span>
        </h2>
      </div>
      <div className="font-bold text-[20px] my-2">اختر خدمة او اكثر</div>
      <div className="flex justify-start flex-wrap gap-5 items-center">
        {isAllCategoryServicesLoading && <LoadingSkeltonCard count={3} />}
        {categoryServices?.services?.map((cat: any) => {
          return (
            <div
              key={cat?.id}
              className={`hover:scale-105 transition-transform duration-300  cursor-pointer rounded-lg ${
                selectedServiesIds.includes(cat.id)
                  ? "border-[2px] border-[#3a5ddd]"
                  : "border-[1px] solid border-[#ccc]"
              }`}
              onClick={() => handleServiceClick(cat)}
            >
              <div className="flex flex-col items-center gap-3 mid:w-full  p-2">
                <div className="w-[270px] mid:w-full h-[200px] mid:h-[250px]">
                  <img src={cat?.image} className="w-full h-full" alt="" />
                </div>
                <span className="mid:text-[15px] font-bold">{cat?.title}</span>
              </div>
            </div>
          );
        })}
      </div>
      <div className=" relative mt-10 flex justify-center items-center pt-3 pb-3">
        <Link
          to={`/AvailableProviders/${categoryId}/${selectedServiesIds.join(
            "-"
          )}`}
          onClick={handleContinueClick}
          className="bg-[#0B247C] text-[#ffff] w-[200px] pt-[8px] rounded-[5px] pb-[8px] flex items-center flex-col gap-2 mt-8 font-bold text-[20px] miilg:text-[18px] mid:text-[12px]"
        >
          <span>التالي</span>
        </Link>
      </div>
    </div>
  );
};

export default CategoryServices;
