import React from "react";
import { Button, Modal, Table, TableColumnsType, message } from "antd";

import {
  useAcceptOrDeclineOrderMutation,
  useGetClientOrdersQuery,
} from "../../redux/Service/api";
import {
  CheckOutlined,
  CloseOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";

const { confirm } = Modal;

const ClientsOrders = () => {
  const [chacngeStatus, { isLoading: delIsLoading }] =
    useAcceptOrDeclineOrderMutation();

  const { data: allCliantOrdersData, isLoading: isAllCliantOrdersLoading } =
    useGetClientOrdersQuery(undefined);

  const allCliantOrders =
    allCliantOrdersData?.data?.data?.map((service: any, idx: number) => {
      return { ...service, idx: idx + 1 };
    }) ?? [];

  const handleDeclineRequest = (id: any) => {
    confirm({
      title: "هل أنت متأكد أنك تريد رفض هذا الطلب؟",
      icon: <ExclamationCircleFilled />,
      content: "بالضغط على موافق سيتم رفض الطلب بشكل دائم.",
      async onOk() {
        try {
          const formData = new FormData();
          formData.append("order_id", id);
          formData.append("status", "cancelled");
          const res: any = await chacngeStatus(formData);
          console.log("res", res);

          message.success(res?.data?.message || "تم رفض الطلب بنجاح");
        } catch (error) {
          console.error(error);
          message.error("فشل في رفض الطلب.");
        }
      },
    });
  };
  const handleApproveRequest = (id: any) => {
    confirm({
      title: "هل أنت متأكد أنك تريد قبول هذا الطلب؟",
      icon: <ExclamationCircleFilled />,
      content: "بالضغط على موافق سيتم قبول الطلب  .",
      async onOk() {
        try {
          const formData = new FormData();
          formData.append("order_id", id);
          formData.append("status", "accepted");
          const res: any = await chacngeStatus(formData);
          console.log("res", res);

          message.success(res?.data?.message || "تم قبول الطلب بنجاح");
        } catch (error) {
          console.error(error);
          message.error("فشل في قبول الطلب.");
        }
      },
    });
  };

  // Table columns
  const ClientServicesColumns: TableColumnsType<any> = [
    {
      title: "م",
      dataIndex: "idx",
      key: "id",
      ellipsis: true,
      width: 70,
    },
    {
      title: "اسم العميل",
      dataIndex: "clientName",
      key: "clientName",
      ellipsis: true,
      width: 150,
      render: (_, record) => <span>{record.client?.name}</span>,
    },
    {
      title: "اسم الخدمة",
      dataIndex: "title",
      key: "title",
      ellipsis: true,
      width: 150,
      render: (_, record) => (
        <div className="flex flex-col gap-2">
          {record.services?.map((service: any) => (
            <span>{service.title}</span>
          ))}
        </div>
      ),
    },
    {
      title: "حالة الطلب",
      dataIndex: "status",
      key: "status",
      ellipsis: true,
      width: 100,
    },
    {
      title: "السعر الكلي",
      dataIndex: "price",
      key: "price",
      ellipsis: true,
      width: 150,
    },
    {
      title: "وصف الخدمة",
      dataIndex: "description",
      key: "description",
      ellipsis: true,
      width: 150,
      render: (_, record) => (
        <div className="flex flex-col gap-2">
          {record.services?.map((service: any) => (
            <span>{service.description}</span>
          ))}
        </div>
      ),
    },
    {
      title: "عدد الاشخاص",
      dataIndex: "person_count",
      key: "person_count",
      ellipsis: true,
      width: 100,
    },
    {
      title: " تاريخ البدأ",
      dataIndex: "from_date",
      key: "from_date",
      ellipsis: true,
      width: 150,
    },
    {
      title: " تاريخ  الانهاء",
      dataIndex: "to_date",
      key: "to_date",
      ellipsis: true,
      width: 150,
    },
    {
      title: "تاريخ انشاء الطلب",
      dataIndex: "created_at",
      key: "created_at",
      ellipsis: true,
      width: 150,
    },
    {
      title: "ملاحظة",
      dataIndex: "note",
      key: "note",
      ellipsis: true,
      width: 150,
    },
    {
      title: "الإجراءات",
      key: "actions",
      render: (_, service) => (
        <div className="flex gap-2">
          <Button
            type="text"
            icon={<CheckOutlined />}
            className="text-green-500"
            onClick={() => handleApproveRequest(service.id)}
          />
          <Button
            type="text"
            icon={<CloseOutlined />}
            danger
            onClick={() => handleDeclineRequest(service.id)}
          />
        </div>
      ),
      width: 130,
    },
  ];
  return (
    <div className="container mt-7 md:mt-14 flex flex-col items-center">
      {/* Add Service Button */}
      <div className="flex justify-between mb-3 w-full items-center text-[#110A62]">
        <p>طلبات العملاء</p>
      </div>
      {/* Table */}
      <Table
        columns={ClientServicesColumns}
        dataSource={allCliantOrders}
        loading={isAllCliantOrdersLoading || delIsLoading}
        bordered
        rowKey="id"
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: [9, 10, 20, 30, 50],
          showTotal: (total) => `الإجمالي: ${total}`,
        }}
        scroll={{ y: 400, x: 500 }}
      />

      {/*  */}
    </div>
  );
};

export default ClientsOrders;
