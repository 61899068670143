import ServicesIDo from "./ServicesIDo";
import ClientsOrders from "./ClientsOrders";

const MyServices = () => {
  return (
    <>
      {/* ------------------------------------ */}
      <ServicesIDo />
      <ClientsOrders />
    </>
  );
};

export default MyServices;
