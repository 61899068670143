import { Button, Form, Input, Table, TableColumnsType, message } from "antd";
import { useState } from "react";
import { useGetAccountStatementsQuery } from "../../../redux/Service/api";
import { SearchOutlined } from "@ant-design/icons";

function TableAccountStatement() {
  const [customerId, setCustomerId] = useState("");
  const [userData, setUserData] = useState<any>(null);
  const [ordersData, setOrdersData] = useState([]);
  const [myOrdersData, setMyOrdersData] = useState([]);

  const { data, error, isError, refetch, isFetching } =
    useGetAccountStatementsQuery(customerId, {
      skip: !customerId,
    });

  const handleSearch = () => {
    if (!customerId) {
      message.warning("يرجى إدخال رقم العميل.");
      return;
    }

    refetch();

    if (isError || error) {
      message.error("فشل في جلب البيانات.");
      setUserData(null);
      setOrdersData([]);
      setMyOrdersData([]);
    } else if (data && data.status === 1) {
      if (data.data?.role != "user") {
        message.error("لا يوجد عميل بهذا الرقم.");
      } else {
        setUserData(data.data);
        setOrdersData(data.data.orders || []);
        setMyOrdersData(data.data.my_orders || []);
        message.success("تم جلب البيانات بنجاح!");
      }
    } else {
      message.error("لم يتم العثور على بيانات للعميل المدخل.");
      setUserData(null);
      setOrdersData([]);
      setMyOrdersData([]);
    }
  };

  const userColumns: TableColumnsType<any> = [
    { title: "رقم العميل", dataIndex: "id", key: "id" },
    { title: "الاسم", dataIndex: "name", key: "name" },
    { title: "البريد الإلكتروني", dataIndex: "email", key: "email" },
    { title: "رقم الهاتف", dataIndex: "phone", key: "phone" },
    { title: "الدور", dataIndex: "role", key: "role" },
    { title: "تاريخ الإنشاء", dataIndex: "created_at", key: "created_at" },
  ];

  const orderColumns: TableColumnsType<any> = [
    { title: "رقم الطلب", dataIndex: "id", key: "id" },
    { title: "رقم الخدمة", dataIndex: "service_id", key: "service_id" },
    { title: "رقم العميل", dataIndex: "client_id", key: "client_id" },
    { title: "رقم المقدم", dataIndex: "provider_id", key: "provider_id" },
    { title: "السعر", dataIndex: "price", key: "price" },
    { title: "الحالة", dataIndex: "status", key: "status" },
    {
      title: "المبلغ المدفوع",
      dataIndex: "payment_amount",
      key: "payment_amount",
    },
    { title: "العمولة", dataIndex: "percent", key: "percent" },
    { title: "تاريخ الإنشاء", dataIndex: "created_at", key: "created_at" },
  ];

  const myOrderColumns: TableColumnsType<any> = [
    { title: "رقم الطلب", dataIndex: "id", key: "id" },
    { title: "رقم الخدمة", dataIndex: "service_id", key: "service_id" },
    { title: "رقم العميل", dataIndex: "client_id", key: "client_id" },
    { title: "رقم المقدم", dataIndex: "provider_id", key: "provider_id" },
    { title: "السعر", dataIndex: "price", key: "price" },
    { title: "الحالة", dataIndex: "status", key: "status" },
    {
      title: "المبلغ المدفوع",
      dataIndex: "payment_amount",
      key: "payment_amount",
    },
    { title: "العمولة", dataIndex: "percent", key: "percent" },
    { title: "تاريخ الإنشاء", dataIndex: "created_at", key: "created_at" },
  ];

  return (
    <div>
      <Form layout="inline" className="mb-5 flex justify-center">
        <Form.Item label="رقم العميل">
          <Input
            value={customerId}
            onChange={(e) => setCustomerId(e.target.value)}
            placeholder="أدخل رقم العميل"
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            icon={<SearchOutlined />}
            onClick={handleSearch}
            loading={isFetching}
          >
            بحث
          </Button>
        </Form.Item>
      </Form>

      <div>
        <h3 className="my-3 font-bold text-[1rem]">تفاصيل العميل</h3>
        <Table
          columns={userColumns}
          dataSource={[userData]}
          rowKey="id"
          bordered
          pagination={false}
        />
      </div>

      {ordersData.length > 0 && userData?.role !== "user" && (
        <div>
          <h3 className="my-3 font-bold text-[1rem]">طلبات العميل</h3>
          <Table
            columns={orderColumns}
            dataSource={ordersData}
            rowKey="id"
            bordered
            scroll={{ y: 350 }}
          />
        </div>
      )}

      <div>
        <h3 className="my-3 font-bold text-[1rem]">الطلبات الخاصة بالعميل</h3>
        <Table
          columns={myOrderColumns}
          dataSource={myOrdersData}
          rowKey="id"
          bordered
          scroll={{ y: 350 }}
        />
      </div>
    </div>
  );
}

export default TableAccountStatement;
