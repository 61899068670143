import { Button, Form, Input } from 'antd';
import './Verification.scss'
import { useNavigate } from 'react-router';
const Verification = () => {
	const Navigate = useNavigate();
	const HundelConfirmEmail = () => {
		Navigate('/verificationCode')
	}
	return (
		<div className='min-h-[60vh] flex gap-[60px] items-center justify-center flex-col w-full container'>

			<h2 className='flex items-center flex-col gap-2 mt-8 font-bold text-[25px] miilg:text-[20px] mid:text-[18px]'>
				تأكيد البريد الالكترونى
			</h2>
			<div className="flex justify-center flex-col items-center confirmEmail w-[50%] miilg:w-[80%] mid:w-full">
				<div className="flex justify-center items-center gap-[30px] w-full">
					<Form.Item name="email" label="البريد الالكتروني" rules={[{ required: false }]}>
						<Input className='w-full text-[13px]' placeholder='Emdad @ info.coma' />
					</Form.Item>
					<svg width="51" className='w-[35px]' height="49" viewBox="0 0 51 49" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M50.9403 16.6203L50.055 17.1568L45.6454 19.817L26.7557 31.2064L26.5173 31.3575L26.4661 31.3877L25.5043 31.9696L25.4702 31.9848L24.6785 31.5087L24.5337 31.418L6.63986 20.6257L5.42263 19.8926L0.0595664 16.658L0 16.6203L5.42263 13.3478L24.8573 1.62598H26.0831L45.6454 13.4233L46.2925 13.8164L50.9403 16.6203Z" fill="#C0874A" />
						<path d="M46.6031 1.53747V38.2743C46.6031 39.1171 45.8182 39.8041 44.8459 39.8041H6.20467C5.24134 39.8041 4.45642 39.1171 4.45642 38.2743V1.53747C4.45642 0.686766 5.24134 0 6.20467 0H44.8459C45.8181 0 46.6031 0.686766 46.6031 1.53747Z" fill="#69B25F" />
						<path d="M30.0118 7.1875H11.0354V9.61625H30.0118V7.1875Z" fill="#A2CC86" />
						<path d="M30.0118 6.80371H11.0354V9.23246H30.0118V6.80371Z" fill="#53A654" />
						<path d="M39.8724 20.5459H11.0354V22.9747H39.8724V20.5459Z" fill="#A2CC86" />
						<path d="M39.8724 20.1621H11.0354V22.5909H39.8724V20.1621Z" fill="#53A654" />
						<path d="M39.8724 27.2256H11.0354V29.6543H39.8724V27.2256Z" fill="#A2CC86" />
						<path d="M39.8724 26.8418H11.0354V29.2706H39.8724V26.8418Z" fill="#53A654" />
						<path d="M39.8724 13.8672H11.0354V16.2959H39.8724V13.8672Z" fill="#A2CC86" />
						<path d="M39.8724 13.4834H11.0354V15.9122H39.8724V13.4834Z" fill="#53A654" />
						<path d="M25.5298 30.8279L25.2319 30.9967L19.4687 34.2296L18.762 34.6301L1.23436 44.4623L0.0595703 45.1159V16.54L7.1167 20.4969L24.5679 30.2868L24.704 30.3642L25.5043 30.8139L25.5298 30.8279Z" fill="#F0BA7D" />
						<path d="M50.8243 16.0106C50.9209 15.9564 51 16 51 16.1076V44.2994C51 44.407 50.9981 44.4934 50.9957 44.4914C50.9933 44.4894 50.9819 44.4816 50.9701 44.4738C50.9584 44.4661 50.9411 44.4549 50.9318 44.4492C50.9224 44.4434 50.9128 44.4387 50.9105 44.4387C50.9081 44.4387 50.8271 44.3943 50.7305 44.3401L26.4461 30.7202C26.3495 30.666 26.1915 30.5769 26.0951 30.5223L26.0115 30.4749C25.9151 30.4203 25.7673 30.3361 25.683 30.2877C25.5987 30.2394 25.609 30.1558 25.7058 30.1017L26.3243 29.7568C26.4211 29.7028 26.5061 29.6554 26.513 29.6516C26.5201 29.6478 26.5316 29.6413 26.5386 29.6375C26.5457 29.6337 26.6306 29.5863 26.7274 29.5322L27.2693 29.2297C27.3661 29.1758 27.5243 29.0871 27.621 29.0329L50.8243 16.0106Z" fill="#E5A864" />
						<path opacity="0.68" d="M37.5669 10.8422C39.6676 10.8422 41.3706 9.20606 41.3706 7.18772C41.3706 5.16939 39.6676 3.5332 37.5669 3.5332C35.4662 3.5332 33.7632 5.16939 33.7632 7.18772C33.7632 9.20606 35.4662 10.8422 37.5669 10.8422Z" fill="#EEF3CA" />
						<path d="M51 40.6975V45.0815H50.9489L50.2168 45.1142H2.75809L2.72412 44.4353C3.58395 43.871 4.72448 43.1103 5.46517 42.6277L5.56727 42.5623L8.05312 40.9183L11.9349 38.342L13.0585 37.5977L24.8488 29.7869L25.6404 29.2551C25.8276 29.1408 26.0405 29.0425 26.2703 28.9772C26.5427 28.8872 26.8406 28.8299 27.1386 28.8054C27.2407 28.7972 27.3343 28.7891 27.4365 28.7972C27.5386 28.7808 27.6409 28.7808 27.743 28.7808C28.0154 28.7727 28.2794 28.7972 28.5347 28.8462C28.807 28.8953 29.054 28.9772 29.2753 29.0916L38.2137 33.8681L42.6318 36.2317L43.7811 36.8452L47.9438 39.0698L50.6848 40.5338C50.7615 40.5748 50.8466 40.6157 50.9318 40.6648C50.9489 40.673 50.9744 40.6894 51 40.6975Z" fill="#C0874A" />
						<path d="M51 42.2436V45.1144H0.0595703V42.2436C0.953465 41.72 2.12826 41.0086 2.89435 40.5669L2.99645 40.5015L5.5588 38.9721L9.56828 36.5839L10.726 35.8887L22.8908 28.6339L23.708 28.1432C24.1337 27.8978 24.687 27.7751 25.2319 27.7669C25.334 27.7587 25.4276 27.7587 25.5298 27.7669C25.632 27.7587 25.7341 27.7587 25.8363 27.7669C26.0747 27.7669 26.3046 27.7915 26.5259 27.8405C26.8324 27.8978 27.1132 28.0042 27.3516 28.1432L28.5349 28.8465L36.0432 33.3287L40.3336 35.8888L41.4489 36.5594L45.501 38.9722L48.1653 40.5671C48.242 40.6081 48.3186 40.6488 48.4037 40.6979C48.9486 41.0334 49.6465 41.4505 50.302 41.8349C50.5403 41.9819 50.7787 42.1209 51 42.2436Z" fill="#ECB168" />
						<path opacity="0.2" d="M46.5989 1.53757V1.94651C46.5989 1.09599 45.8156 0.408939 44.8452 0.408939H6.20576C5.24394 0.408939 4.45224 1.0959 4.45224 1.94651V1.53757C4.45214 0.686957 5.24394 0 6.20576 0H44.8453C45.8156 0 46.5989 0.686957 46.5989 1.53757Z" fill="#BADB9E" />
					</svg>

				</div>
				<Button
					onClick={HundelConfirmEmail}

					className='bg-[#21005D] text-[#ffffff] mid:text-[12px] w-[50%] pt-2 pb-2'>
					تأكيد
				</Button>
			</div>

		</div>
	)
}

export default Verification