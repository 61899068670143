import {
  Button,
  Modal,
  Form,
  message,
  Input,
  Select,
  Upload,
  Radio,
} from "antd";
import { useEffect, useState } from "react";
import { DownloadOutlined } from "@ant-design/icons";
import {
  useEditServiceMutation,
  useGetAllCategoriesQuery,
  useGetFullServiceDetailsQuery,
} from "../../../redux/Service/api";

function EditService({
  openEditModal,
  serviceIdForEdit,
  setOpenEditModal,
}: any) {
  const [modalKey1, setModalKey1] = useState(0);
  const [form] = Form.useForm();
  const [file, setFile] = useState<any>(null);

  const [isPercent, setIsPercent] = useState(false);

  const {
    data: serviceDetails,
    isLoading: isDetailsLoading,
    isError: isDetailsError,
  } = useGetFullServiceDetailsQuery(serviceIdForEdit, {
    skip: !serviceIdForEdit, // Skip query if no serviceIdForEdit
  });

  // Fetch all categories
  const { data: allCategoriesData } = useGetAllCategoriesQuery(undefined);
  const allCategories = allCategoriesData?.data?.data ?? [];

  // Edit service mutation
  const [editService, { isLoading: isEditLoading }] = useEditServiceMutation();

  // Handle file change
  const handleFileChange = ({ fileList }: any) => {
    if (fileList.length > 0) {
      setFile(fileList[0].originFileObj);
    } else {
      setFile(null);
    }
  };

  // Handle form submission
  const handleEditService = async (values: any) => {
    console.log("values", values);

    const formData = new FormData();
    if (typeof file === "object" && !Array.isArray(file)) {
      formData.append("photo", file);
    }
    formData.append("title", values.title);
    formData.append("description", values.description);
    formData.append("price", values.price);
    formData.append("high_price", values.high_price);
    formData.append("category_id", values.category_id);
    formData.append("is_percent", isPercent ? "1" : "0");
    formData.append("percent", values.percent);
    formData.append("max_percent", values.max_percent);

    try {
      const res = await editService({
        id: serviceIdForEdit,
        formData: formData,
      }).unwrap();
      message.success(res?.message || "Service updated successfully");
      setOpenEditModal(false);
    } catch (error) {
      console.error("Error updating service:", error);
      message.error("An error occurred while updating the service.");
    }
  };

  // Set initial form values when service details are fetched
  useEffect(() => {
    if (serviceDetails) {
      console.log(serviceDetails);

      const {
        title,
        description,
        price,
        high_price,
        category,
        is_percent,
        percent,
        max_percent,
        image,
      } = serviceDetails.data;
      form.setFieldsValue({
        title,
        description,
        price,
        high_price,
        category_id: category.id,
        is_percent: is_percent ? "percent" : "value",
        percent,
        max_percent,
      });
      setIsPercent(is_percent == 1);
      console.log(image);

      setFile([
        {
          uid: "-1",
          name: "Main-image",
          status: "done",
          url: image,
        },
      ]);
      setModalKey1((prev) => prev + 11);
    }
  }, [serviceDetails, form]);

  return (
    <Modal
      title="تعديل الخدمة"
      style={{ top: 20 }}
      open={openEditModal}
      onCancel={() => {
        setOpenEditModal(false);
      }}
      width={800}
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleEditService}
        initialValues={{
          title: "",
          description: "",
          price: "",
          high_price: "",
          category_id: "",
          percent: "",
          max_percent: "",
        }}
      >
        <Form.Item
          label="عنوان الخدمة"
          name="title"
          rules={[
            { required: true, message: "Please enter the service title" },
          ]}
        >
          <Input placeholder="Enter service title" />
        </Form.Item>

        <Form.Item
          label="الوصف"
          name="description"
          rules={[
            { required: true, message: "Please enter the service description" },
          ]}
        >
          <Input.TextArea placeholder="Enter service description" rows={4} />
        </Form.Item>

        <Form.Item
          label="السعر"
          name="price"
          rules={[{ required: true, message: "Please enter the price" }]}
        >
          <Input type="number" placeholder="Enter price" />
        </Form.Item>

        <Form.Item label="السعر الأعلى" name="high_price">
          <Input type="number" placeholder="Enter high price" />
        </Form.Item>

        <Form.Item
          label="فئة الخدمة"
          name="category_id"
          rules={[{ required: true, message: "Please select a category" }]}
        >
          <Select
            placeholder="Select Category"
            options={allCategories?.map((category: any) => ({
              value: category.id,
              label: category.name,
            }))}
          />
        </Form.Item>

        <Form.Item label="نوع العمولة" required>
          <Radio.Group
            key={modalKey1}
            onChange={(e) => setIsPercent(e.target.value === "percent")}
            value={isPercent ? "percent" : "value"}
          >
            <Radio value="value"> قيمة </Radio>
            <Radio value="percent"> نسبة </Radio>
          </Radio.Group>
        </Form.Item>

        {isPercent ? (
          <Form.Item
            label="النسبة (%)"
            name="percent"
            rules={[{ required: true, message: "Please enter the percentage" }]}
          >
            <Input
              type="number"
              placeholder="Enter percentage"
              min={0}
              max={100}
            />
          </Form.Item>
        ) : (
          <Form.Item
            label="القيمة"
            name="percent"
            rules={[{ required: true, message: "Please enter the amount" }]}
          >
            <Input type="number" placeholder="Enter percentage" />
          </Form.Item>
        )}

        <Form.Item
          label="اقصي عمولة"
          name="max_percent"
          rules={[{ required: true, message: "Please enter the amount" }]}
        >
          <Input type="number" placeholder="Enter max commission" />
        </Form.Item>

        <Form.Item
          label="الصورة"
          valuePropName="file"
          rules={[{ required: false, message: "Please upload an image" }]}
        >
          <Upload
            key={modalKey1}
            customRequest={({ file, onSuccess }: any) => {
              setTimeout(() => onSuccess("ok"), 0);
            }}
            onChange={handleFileChange}
            listType="picture"
            maxCount={1}
            defaultFileList={file}
          >
            <Button icon={<DownloadOutlined />}> اختر صورة (Max: 1) </Button>
          </Upload>
        </Form.Item>

        <div className="flex justify-end">
          <Button htmlType="submit" type="primary" loading={isEditLoading}>
            حفظ
          </Button>
        </div>
      </Form>
    </Modal>
  );
}

export default EditService;
