import React from 'react'
import AboutUsGallary from './AboutUsGallary'
import { Link } from 'react-router-dom'

const AboutUs = () => {
	return (
		<div className='mt-10 flex flex-col h-full gap-8 mid:gap-5 container mb-20'>
			<h2 className='flex items-center flex-col gap-2 mt-8 font-bold text-[35px] miilg:text-[20px] mid:text-[15px]'>
				لا تخلى الوقت يروح
				واحجز كل ماتتمناه
			</h2>
			<AboutUsGallary />
			<div className={`text-center `}>
				<Link to={`/Login`} className={`pr-20 pl-20 pt-[4px] pb-[4px] bg-[#466FFF] text-[#fff] text-[17px] font-bold`}>التالي</Link>
			</div>
		</div>
	)
}

export default AboutUs