import { RightOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router";
import { useSetProvidersMutation } from "../../redux/Service/api";
import { useEffect } from "react";
import { Button, Skeleton } from "antd";
import LoadingSkeltonCard from "../../Components/utils/LoadingSkeltonCard";
import {
  saveSelectedProvider,
  saveSelectedServices,
} from "../../redux/Slices/userOrderSlice";
import { useDispatch } from "react-redux";
import NavigateBack from "../../Components/utils/NavigateBack";

const AvailableProviders = () => {
  const dispatch = useDispatch();

  const { categoryId, selectedServices } = useParams();
  const selectedServicesArray = selectedServices?.split("-") || [];

  const [getAvailableProviders, { data, isLoading, isError }] =
    useSetProvidersMutation();

  const availableProviders = data?.data || [];

  function getProviders() {
    const formData = new FormData();
    selectedServicesArray.forEach((serviceId, idx) => {
      formData.append(`service_id[${idx}]`, serviceId);
    });
    getAvailableProviders(formData).unwrap();
  }

  useEffect(() => {
    if (selectedServices) {
      getProviders();
    }
  }, [selectedServices]);

  const navigate = useNavigate();

  const handleSelectProvider = (provider: any) => {
    dispatch(saveSelectedProvider(provider));

    navigate(`/OrderForm`);
  };

  return (
    <div className="container pb-12 pt-5">
      <h2 className="flex mb-5 mt-5 items-center justify-between w-[60%] gap-2 font-bold text-[25px] miilg:text-[20px] mid:text-[18px]">
        <NavigateBack url={`/ourservices/${categoryId}`} />
        <span className="ani bg-[#110A62] mid:text-[15px] text-center text-[#ffff] mid:w-[80%] w-[28%] pt-3 pb-3 block">
          {`المزودين`}
        </span>
      </h2>
      <div className="flex justify-start gap-[10px] flex-wrap items-center">
        {isLoading ? (
          <LoadingSkeltonCard count={1} />
        ) : availableProviders.length === 0 ? (
          // Handle Empty State
          <div className="text-center w-full mt-10">
            <p className="text-gray-600 text-[18px]">
              لا يوجد مزودين متاحين حالياً
            </p>
          </div>
        ) : (
          // Render Available Providers
          availableProviders?.map((provider: any) => (
            <div
              key={provider.id}
              // onClick={() => navigate("/OrderDetails")}
              className="flex gap-4 flex-col justify-between flex-nowrap rounded-[8px] bg-[#E2E8FF] pt-3 pb-3 cursor-pointer pl-2 pr-2 mid:min-w-[100%] min-w-[32%]"
            >
              <div className="flex items-center">
                <img
                  src={provider.image}
                  className="ml-2 w-[80px] h-[80px] rounded-[50%]"
                  alt=""
                />
                <div className="flex flex-col gap-[20px] w-[65%] mid:w-[80%] ">
                  <h3 className="flex items-center justify-between ">
                    <span>{provider.name}</span>
                  </h3>
                  <div className="flex gap-[7px]">
                    <p>المبلغ المقدم:</p>
                    <p>{provider.total_price} ريال</p>
                  </div>
                </div>
              </div>
              <Button onClick={() => handleSelectProvider(provider)}>
                اختار هذا المقدم
              </Button>
            </div>
          ))
        )}
        {isError && (
          <div className="text-red-500 mt-4">
            حدث خطأ أثناء جلب البيانات. يرجى المحاولة مرة أخرى.
          </div>
        )}
      </div>
    </div>
  );
};

export default AvailableProviders;
