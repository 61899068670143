import LandingCatogeries from "../../assets/Png/landingcatogeries.png";

import order1 from "../../assets/Png/order1.jpeg";

import { Fragment, useState } from "react";
import "./Categories.scss";
import CategoriesDrawer from "./CategoriesDrawer";
import { useGetAllCategoriesQuery } from "../../redux/Service/api";

const Categories = () => {
  const [checkedValues, setCheckedValues] = useState<number[]>([]);

  const {
    data: allCategoriessData,
    isError: isAllCategoriesError,
    isLoading: isAllCategoriesLoading,
    error: allCategoriesError,
  } = useGetAllCategoriesQuery(null);
  const allCategories = allCategoriessData?.data?.data ?? null;
  // console.log("allCategories", allCategories);

  const onChange = (checkedValues: number[]) => {
    console.log("Checked values: ", checkedValues);
    setCheckedValues(checkedValues);
  };

  const Records = allCategories?.map((row: any) => {
    return (
      <Fragment key={row?.id}>
        <div className="flex flex-col items-center gap-3 mid:w-full border-[1px] solid border-[#ccc] p-2">
          <div className="w-[270px] mid:w-full h-[200px] mid:h-[250px]">
            <img
              src={row?.image}
              className="w-full h-full"
              alt=""
              onError={(e) => {
                e.currentTarget.src = order1;
              }}
            />
          </div>
          <p>{row?.name}</p>
          <CategoriesDrawer onChange={onChange} AllRowId={row?.id} />
        </div>
      </Fragment>
    );
  });

  return (
    <div className="container pb-20 pt-0 categories">
      <div className="h-[350px]">
        <img src={LandingCatogeries} className="h-full w-full" alt="" />
      </div>
      <div className="flex flex-col items-center">
        <h2 className=" font-bold text-[20px] w-full flex justify-center text-center miilg:text-[20px] mid:text-[18px] mid:mt-6 mid:mb-6  mt-12 mb-12">
          <span className="ani bg-[#110A62] mid:text-[15px] text-[#ffff] mid:w-[80%] w-[28%] pt-3 pb-3 block">
            كشته بريه
          </span>
        </h2>
        <div className="flex justify-start flex-wrap gap-5 items-center">
          {isAllCategoriesLoading && <> LOading ...</>}
          {Records}
        </div>
      </div>
    </div>
  );
};

export default Categories;
