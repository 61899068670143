import {
  Button,
  Modal,
  Form,
  message,
  Input,
  Select,
  Upload,
  Radio,
} from "antd";
import { useState } from "react";
import { DownloadOutlined } from "@ant-design/icons";
import {
  useAddServiceMutation,
  useGetAllCategoriesQuery,
} from "../../../redux/Service/api";

function AddService() {
  const [form] = Form.useForm();
  const [addServiceModal, setAddServiceModal] = useState(false);
  const [modalKey, setModalKey] = useState(0);
  const [file, setFile] = useState<null | File>(null);
  const [isPercent, setIsPercent] = useState(false);

  const { data: allCategoriesData } = useGetAllCategoriesQuery(undefined);
  const allCategories = allCategoriesData?.data?.data ?? [];
  const [addService, { isLoading }] = useAddServiceMutation();

  const handleFileChange = ({ fileList }: any) => {
    if (fileList.length > 0) {
      setFile(fileList[0].originFileObj);
    } else {
      setFile(null);
    }
  };

  const handleAddService = async (values: any) => {
    if (!file) {
      message.error("Please upload a file.");
      return;
    }

    const formData = new FormData();
    formData.append("photo", file);
    formData.append("title", values.title);
    formData.append("description", values.description);
    formData.append("price", values.price);
    formData.append("high_price", values.high_price);
    formData.append("category_id", values.category_id);
    formData.append("is_percent", isPercent ? "1" : "0");
    formData.append("percent", values.percent);
    formData.append("max_percent", values.max_percent);

    try {
      const res = await addService(formData).unwrap();
      form.resetFields();
      setModalKey((prev) => prev + 1);
      message.success(res?.message || "Service added successfully");
      setAddServiceModal(false);
    } catch (error) {
      console.error("Error adding service:", error);
      message.error("An error occurred while adding the service.");
    }
  };

  return (
    <>
      <div className="text-center my-3">
        <Button
          className="w-[150px]"
          type="primary"
          onClick={() => setAddServiceModal(true)}
        >
          إضافة خدمة
        </Button>
      </div>
      <Modal
        key={modalKey}
        title="إضافة خدمة"
        style={{ top: 20 }}
        open={addServiceModal}
        onCancel={() => setAddServiceModal(false)}
        width={800}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleAddService}
          initialValues={{
            title: "",
            description: "",
            price: "",
            high_price: "",
            category_id: "",
            percent: "",
          }}
        >
          <Form.Item
            label="عنوان الخدمة"
            name="title"
            rules={[
              { required: true, message: "Please enter the service title" },
            ]}
          >
            <Input placeholder="Enter service title" />
          </Form.Item>

          <Form.Item
            label="الوصف"
            name="description"
            rules={[
              {
                required: true,
                message: "Please enter the service description",
              },
            ]}
          >
            <Input.TextArea placeholder="Enter service description" rows={4} />
          </Form.Item>

          <Form.Item
            label="السعر"
            name="price"
            rules={[{ required: true, message: "Please enter the price" }]}
          >
            <Input type="number" placeholder="Enter price" />
          </Form.Item>

          <Form.Item label="السعر الأعلى" name="high_price">
            <Input type="number" placeholder="Enter high price" />
          </Form.Item>

          <Form.Item
            label="فئة الخدمة"
            name="category_id"
            rules={[{ required: true, message: "Please select a category" }]}
          >
            <Select
              placeholder="Select Category"
              options={allCategories.map((category: any) => ({
                value: category.id,
                label: category.name,
              }))}
            />
          </Form.Item>

          <Form.Item label="نوع العمولة" required>
            <Radio.Group
              onChange={(e) => setIsPercent(e.target.value === "percent")}
              value={isPercent ? "percent" : "value"}
            >
              <Radio value="value"> قيمة </Radio>
              <Radio value="percent"> نسبة </Radio>
            </Radio.Group>
          </Form.Item>

          {isPercent ? (
            <Form.Item
              label="النسبة (%)"
              name="percent"
              rules={[
                { required: true, message: "Please enter the percentage" },
              ]}
            >
              <Input
                type="number"
                placeholder="Enter percentage"
                min={0}
                max={100}
              />
            </Form.Item>
          ) : (
            <Form.Item
              label="القيمة"
              name="percent"
              rules={[{ required: true, message: "Please enter the amount" }]}
            >
              <Input type="number" placeholder="Enter percentage" />
            </Form.Item>
          )}

          <Form.Item
            label="اقصي عمولة"
            name="max_percent"
            rules={[{ required: true, message: "Please enter the amount" }]}
          >
            <Input type="number" placeholder="Enter max commission" />
          </Form.Item>

          <Form.Item
            label="الصورة"
            valuePropName="file"
            rules={[{ required: true, message: "Please upload an image" }]}
          >
            <Upload
              customRequest={({ file, onSuccess }: any) => {
                setTimeout(() => onSuccess("ok"), 0);
              }}
              onChange={handleFileChange}
              listType="picture"
              maxCount={1}
            >
              <Button icon={<DownloadOutlined />}> اختر صورة (Max: 1) </Button>
            </Upload>
          </Form.Item>

          <div className="flex justify-end">
            <Button htmlType="submit" type="primary" loading={isLoading}>
              حفظ
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}

export default AddService;
