import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  baseUrl: "https://service.prosofteg.com/",
  prepareHeaders: (headers, { endpoint }) => {
    const token = localStorage.getItem("hejozatiToken");
    const language = localStorage.getItem("language");

    headers.set("Accept-Language", language ? language : "ar");
    headers.set("Accept", "application/json");

    if (token && endpoint !== "login" && endpoint !== "register") {
      headers.set("Authorization", `Bearer ${token}`);
    }

    return headers;
  },
});

export const api = createApi({
  reducerPath: "api",
  baseQuery,
  endpoints: (builder) => ({
    // ********* Auth & User Management ********* //
    register: builder.mutation({
      query: (userData) => ({
        url: "api/auth/register",
        method: "POST",
        body: userData,
      }),
      invalidatesTags: ["User"],
    }),
    login: builder.mutation({
      query: (loginData) => ({
        url: "api/auth/login",
        method: "POST",
        body: loginData,
      }),
      invalidatesTags: ["User"],
    }),
    logout: builder.mutation({
      query: () => ({
        url: "api/auth/logout",
        method: "POST",
      }),
      invalidatesTags: ["User"],
    }),
    getLogedUser: builder.query({
      query: () => "api/auth/show",
      providesTags: ["User"],
    }),
    updateUserData: builder.mutation({
      query: (userData) => ({
        url: "api/auth/update",
        method: "POST",
        body: userData,
      }),
      invalidatesTags: ["User"],
    }),
    createNewUser: builder.mutation({
      query: (userData) => ({
        url: "api/admin/store",
        method: "POST",
        body: userData,
      }),
      invalidatesTags: ["User"],
    }),
    adminGetAllUser: builder.query({
      query: () => "api/admin",
      providesTags: ["User"],
    }),
    deleteUser: builder.mutation({
      query: (userId) => ({
        url: `api/admin/delete/${userId}`,
        method: "POST",
      }),
      invalidatesTags: ["User"],
    }),

    // User with services
    setProviders: builder.mutation({
      query: (formData) => ({
        url: `api/users/orders/get-provider`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["userManagement"],
    }),
    createOrder: builder.mutation({
      query: (formData) => ({
        url: `api/users/orders`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["userManagement"],
    }),

    requestToBeProvider: builder.mutation({
      query: (formData) => ({
        url: `api/users/provider-request`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["userManagement", "User", "myServices"],
    }),
    getRequestsToBeProvider: builder.query({
      query: () => "api/admin/provider-requests",
      providesTags: ["userManagement", "User", "myServices"],
    }),
    changeRequestToBeProvider: builder.mutation({
      query: ({ id, formData }) => ({
        url: `api/admin/provider-requests/update/${id}`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["userManagement", "User", "myServices"],
    }),
    getMyOrders: builder.query({
      query: () => "api/auth/show/my-orders",
      providesTags: ["userManagement", "User", "myServices"],
    }),

    // Provider
    getProviderServices: builder.query({
      query: () => "api/users/provider-services",
      providesTags: ["myServices"],
    }),
    addProviderService: builder.mutation({
      query: (formData) => ({
        url: "api/users/provider-services",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["myServices"],
    }),
    deleteProviderService: builder.mutation({
      query: (serviceId) => ({
        url: `api/users/provider-services/${serviceId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["myServices"],
    }),

    getClientOrders: builder.query({
      query: () => "api/users/client-orders",
      providesTags: ["myServices"],
    }),

    acceptOrDeclineOrder: builder.mutation({
      query: (formData) => ({
        url: `api/users/client-orders`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["userManagement", "User", "myServices"],
    }),

    // Cutomers
    getCustomers: builder.query({
      query: () => "api/admin?role[]=user",
      providesTags: ["customers", "User"],
    }),

    deleteCustomers: builder.mutation({
      query: (userId) => ({
        url: `api/admin/delete/${userId}`,
        method: "POST",
      }),
      invalidatesTags: ["customers", "User"],
    }),

    // Providers
    getProviders: builder.query({
      query: () => "api/admin?role[]=provider",
      providesTags: ["providers", "User"],
    }),

    deleteProviders: builder.mutation({
      query: (userId) => ({
        url: `api/admin/delete/${userId}`,
        method: "POST",
      }),
      invalidatesTags: ["providers", "User"],
    }),

    // AccountStatements
    getAccountStatements: builder.query({
      query: (statementId) => `api/admin/orders/${statementId}`,
      providesTags: ["statements", "customers", "User"],
    }),

    // ********* ServicesTypes ********* //
    getAllCategories: builder.query({
      query: () => "api/categories",
      providesTags: ["categories", "services"],
    }),
    getCategoryDetails: builder.query({
      query: (catId) => `api/categories/${catId}`,
      providesTags: ["categories", "services"],
    }),
    addCategory: builder.mutation({
      query: (formData) => ({
        url: "api/admin/categories",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["categories", "services"],
    }),
    deleteCategory: builder.mutation({
      query: (catId) => ({
        url: `api/admin/categories/${catId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["categories", "services"],
    }),
    getFullCategoryDetails: builder.query({
      query: (id) => `api/admin/categories/${id}/edit`,
      providesTags: ["categories", "services"],
    }),
    editCategory: builder.mutation({
      query: ({ id, formData }) => ({
        url: `api/admin/categories/update/${id}`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["categories", "services"],
    }),

    // ********* Services ********* //
    getAllServices: builder.query({
      query: () => "api/services",
      providesTags: ["services"],
    }),
    getServiceDetails: builder.query({
      query: (serviceId) => `api/services/${serviceId}`,
      providesTags: ["services"],
    }),
    addService: builder.mutation({
      query: (formData) => ({
        url: "api/admin/services",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["services"],
    }),
    deleteService: builder.mutation({
      query: (serviceId) => ({
        url: `api/admin/services/${serviceId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["services"],
    }),
    getFullServiceDetails: builder.query({
      query: (id) => `api/admin/services/update/${id}/edit`,
      providesTags: ["services"],
    }),
    editService: builder.mutation({
      query: ({ id, formData }) => ({
        url: `api/admin/services/update/${id}`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["services"],
    }),

    // Cities
    getCities: builder.query({
      query: () => "api/cities",
      providesTags: ["cities"],
    }),
    addCity: builder.mutation({
      query: (formData) => ({
        url: "api/admin/cities",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["cities"],
    }),
    deleteCity: builder.mutation({
      query: (cityId) => ({
        url: `api/admin/cities/${cityId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["cities"],
    }),
    // Governments
    getGovernments: builder.query({
      query: () => "api/governments",
      providesTags: ["governments"],
    }),
    addGovernment: builder.mutation({
      query: (formData) => ({
        url: "api/admin/governments",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["governments"],
    }),
    deleteGovernment: builder.mutation({
      query: (governmentId) => ({
        url: `api/admin/governments/${governmentId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["governments"],
    }),

    // Banners - Sliders

    getBanners: builder.query({
      query: () => "api/sliders",
      providesTags: ["sliders"],
    }),
    addBanner: builder.mutation({
      query: (formData) => ({
        url: "api/admin/sliders",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["sliders"],
    }),
    deleteBanner: builder.mutation({
      query: (sliderId) => ({
        url: `api/admin/sliders/${sliderId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["sliders"],
    }),

    // **************** News **************** //
    getAllNews: builder.query({
      query: () => "api/news",
      providesTags: ["news"],
    }),
    getOneNews: builder.query({
      query: (id) => `api/news/${id}`,
      providesTags: ["news"],
    }),

    addNews: builder.mutation({
      query: (articleBody) => {
        return {
          url: "api/admin/news",
          method: "POST",
          body: articleBody,
        };
      },
      invalidatesTags: ["news"],
    }),
    deleteNews: builder.mutation({
      query: (tipId) => ({
        url: `api/admin/news/${tipId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["news"],
    }),
    getFullNewsDetails: builder.query({
      query: (id) => `api/admin/news/${id}/edit`,
      providesTags: ["news"],
    }),
    editNews: builder.mutation({
      query: ({ id, articleBody }) => {
        return {
          url: `api/admin/news/${id}`,
          method: "POST",
          body: articleBody,
        };
      },
      invalidatesTags: ["news"],
    }),
    // **************** articles **************** //
    getAllArticles: builder.query({
      query: () => "api/articles",
      providesTags: ["articles"],
    }),
    getOneArticle: builder.query({
      query: (id) => `api/articles/${id}`,
      providesTags: ["articles"],
    }),

    addArticle: builder.mutation({
      query: (articleBody) => {
        return {
          url: "api/admin/articles",
          method: "POST",
          body: articleBody,
        };
      },
      invalidatesTags: ["articles"],
    }),
    deleteArticle: builder.mutation({
      query: (tipId) => ({
        url: `api/admin/articles/${tipId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["articles"],
    }),
    getFullArticleDetails: builder.query({
      query: (id) => `api/admin/articles/${id}/edit`,
      providesTags: ["articles"],
    }),
    editArticle: builder.mutation({
      query: ({ id, articleBody }) => {
        return {
          url: `api/admin/articles/${id}`,
          method: "POST",
          body: articleBody,
        };
      },
      invalidatesTags: ["articles"],
    }),

    // Bills
    getAllBills: builder.query({
      query: () => "api/admin/orders",
      providesTags: ["bills"],
    }),

    getPendingBills: builder.query({
      query: () => "api/admin/orders?status=pending",
      providesTags: ["bills"],
    }),

    getComplitedBills: builder.query({
      query: () => "api/admin/orders?status=completed",
      providesTags: ["bills"],
    }),

    getProcessingBills: builder.query({
      query: () => "api/admin/orders?status=processing",
      providesTags: ["bills"],
    }),

    getCancelledBills: builder.query({
      query: () => "api/admin/orders?status=cancelled",
      providesTags: ["bills"],
    }),

    // ComplaintsAndSuggestions
    getComplaintsAndSuggestions: builder.query({
      query: () => "api/admin/feedback",
      providesTags: ["feedback"],
    }),
  }),
});

export const {
  // Auth & User Management
  useRegisterMutation,
  useLoginMutation,
  useLogoutMutation,
  useGetLogedUserQuery,
  useLazyGetLogedUserQuery,
  useUpdateUserDataMutation,
  useCreateNewUserMutation,
  useAdminGetAllUserQuery,
  useDeleteUserMutation,

  // User with services
  useSetProvidersMutation,
  useCreateOrderMutation,
  useRequestToBeProviderMutation,
  useGetRequestsToBeProviderQuery,
  useChangeRequestToBeProviderMutation,
  useGetMyOrdersQuery,

  // Provider
  useGetProviderServicesQuery,
  useAddProviderServiceMutation,
  useDeleteProviderServiceMutation,
  useGetClientOrdersQuery,
  useAcceptOrDeclineOrderMutation,

  // Customers
  useGetCustomersQuery,
  useDeleteCustomersMutation,

  // Providers
  useGetProvidersQuery,
  useDeleteProvidersMutation,

  // Categories
  useGetAllCategoriesQuery,
  useGetCategoryDetailsQuery,
  useAddCategoryMutation,
  useDeleteCategoryMutation,
  useGetFullCategoryDetailsQuery,
  useEditCategoryMutation,

  // Services
  useGetAllServicesQuery,
  useGetServiceDetailsQuery,
  useAddServiceMutation,
  useDeleteServiceMutation,
  useGetFullServiceDetailsQuery,
  useEditServiceMutation,

  // Cities
  useGetCitiesQuery,
  useAddCityMutation,
  useDeleteCityMutation,
  // Governments
  useGetGovernmentsQuery,
  useAddGovernmentMutation,
  useDeleteGovernmentMutation,

  // Banners
  useGetBannersQuery,
  useAddBannerMutation,
  useDeleteBannerMutation,

  // News
  useGetAllNewsQuery,
  useGetOneNewsQuery,
  useAddNewsMutation,
  useDeleteNewsMutation,
  useEditNewsMutation,
  useGetFullNewsDetailsQuery,

  // Articles
  useGetAllArticlesQuery,
  useGetOneArticleQuery,
  useAddArticleMutation,
  useDeleteArticleMutation,
  useEditArticleMutation,
  useGetFullArticleDetailsQuery,

  // AccountStatements
  useGetAccountStatementsQuery,

  // Bills
  useGetAllBillsQuery,
  useGetPendingBillsQuery,
  useGetComplitedBillsQuery,
  useGetProcessingBillsQuery,
  useGetCancelledBillsQuery,

  // ComplaintsAndSuggestions
  useGetComplaintsAndSuggestionsQuery,
} = api;
