import { LeftOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import defaultAvatar from "../../assets/Png/Avater.png";

const MyProfile = () => {
  const userData = useSelector((state: any) => state.auth.userData);

  // User data fields to display
  const userDetails = [
    { label: "الاسم", value: userData?.name || "غير متوفر" },
    { label: "البريد الإلكتروني", value: userData?.email || "غير متوفر" },
    { label: "رقم الهاتف", value: userData?.phone || "غير متوفر" },
    {
      label: "تاريخ الإنشاء",
      value: userData?.created_at
        ? new Date(userData.created_at).toLocaleDateString()
        : "غير متوفر",
    },
    {
      label: "تاريخ التحديث",
      value: userData?.updated_at
        ? new Date(userData.updated_at).toLocaleDateString()
        : "غير متوفر",
    },
  ];

  return (
    <div className="mb-10">
      {/* Header Section */}
      <div className="bg-[#ffff] mb-3 pt-7 pb-7">
        <div className="container">
          <div className="flex justify-between w-full items-center">
            <h2 className="flex items-center flex-col gap-2 font-bold text-[20px] miilg:text-[20px] mid:text-[18px]">
              ملفى الشخصى
            </h2>
            <Link to={"/"}>
              <LeftOutlined />
            </Link>
          </div>
        </div>
      </div>

      {/* User Data Section */}
      <div className="container">
        <div className="bg-white p-6 rounded-lg shadow-md max-w-[600px] mx-auto">
          <h3 className="text-xl font-bold mb-6 border-b pb-2">
            تفاصيل المستخدم
          </h3>

          {/* Display User Image or Default Avatar */}
          <div className="flex justify-center mb-6">
            <img
              src={userData?.image || defaultAvatar} // Use default avatar if no image exists
              alt="صورة المستخدم"
              className="w-32 h-32 rounded-full object-cover border-4 border-gray-200"
            />
          </div>

          {/* Display User Details */}
          <div className="space-y-4">
            {userDetails.map((item, index) => (
              <div key={index} className="flex justify-between items-center">
                <span className="text-gray-600 font-medium">{item.label}:</span>
                <span className="text-gray-800">{item.value}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
