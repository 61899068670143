import React from "react";
import { Link } from "react-router-dom";
import Download1 from "../../assets/Download1.svg";
import Download2 from "../../assets/Download2.svg";

function Download() {
  return (
    <div className="mb-10 py-10 bg-gray-900">
      <div className="container flex justify-center flex-wrap gap-5">
        <Link to={"/"}>
          <img src={Download1} alt="" />
        </Link>
        <Link to={"/"}>
          <img src={Download2} alt="" />
        </Link>
      </div>
    </div>
  );
}

export default Download;
