import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { Button, message } from "antd";
import dayjs from "dayjs";
import NavigateBack from "../../Components/utils/NavigateBack";
import { useCreateOrderMutation } from "../../redux/Service/api";
import { useDispatch } from "react-redux";
import { clearOrderData } from "../../redux/Slices/userOrderSlice";

const OrderDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedCategory, userFormData, selectedServices, selectedProvider } =
    useSelector((state: any) => state.userOrder.orderData);
  const [createOrder, { isLoading, isError }] = useCreateOrderMutation();

  const handleOrderClick = (state: any) => {
    const formData = new FormData();

    formData.append("provider_id", selectedProvider.id);
    formData.append("address", userFormData.address);
    formData.append(
      "from_date",
      dayjs(userFormData.from_date).format("YYYY-MM-DD")
    );
    formData.append(
      "to_date",
      dayjs(userFormData.to_date).format("YYYY-MM-DD")
    );
    formData.append("person_count", userFormData.person_count);
    selectedServices.forEach((service: any, idx: number) => {
      formData.append(`service_id[${idx}]`, service.id);
    });

    console.log("formData", formData);
    // return;

    createOrder(formData)
      .unwrap()
      .then((res) => {
        if (res.status === 1) {
          message.success(res.message || "تم تقديم الطلب بنجاح");
          dispatch(clearOrderData());
          navigate("/");
        } else {
          message.error(res.message || "فشل في تقديم الطلب");
        }
      });
  };

  return (
    <div className="container pb-12 pt-5">
      <h2 className="flex mb-5 mt-5 items-center justify-between w-[60%] gap-2 font-bold text-[25px] miilg:text-[20px] mid:text-[18px]">
        <NavigateBack url="/OrderForm" />
        <span className="ani bg-[#110A62] mid:text-[15px] text-center text-[#ffff] mid:w-[80%] w-[28%] pt-3 pb-3 block">
          مراجعه الطلب
        </span>
      </h2>

      <div className="flex justify-center gap-[15px] miilg:flex-col py-2">
        {/* User Form Data */}
        <div className="bg-[#E2E8FF] p-5 flex flex-col gap-[20px] w-[50%] miilg:w-[100%]">
          <div className="flex justify-between items-center">
            <p className="text-[15px] text-[#545454]">العنوان:</p>
            <p className="text-[11px] font-bold">
              {userFormData?.address || "غير متوفر"}
            </p>
          </div>
          <div>
            <div className="flex justify-between w-[95%] items-center mb-5">
              <p className="text-[13px] text-[#545454]">تاريخ الطلب</p>
              <p className="text-[12px]">
                {`${dayjs(new Date()).format("YYYY-MM-DD")}` || "غير متوفر"}
              </p>
            </div>
            <div className="flex justify-between w-[95%] items-center mb-5">
              <p className="text-[13px] text-[#545454]">تاريخ الخدمة</p>
              <p className="text-[12px]">
                {`${dayjs(userFormData?.from_date).format("YYYY-MM-DD")}` ||
                  "غير متوفر"}
              </p>
            </div>
            <div className="flex justify-between w-[95%] items-center mb-5">
              <p className="text-[13px] text-[#545454]">ملاحظات</p>
              <p className="text-[12px]">
                {userFormData?.note || "لا يوجد ملاحظات"}
              </p>
            </div>
            <div className="flex justify-between w-[95%] items-center mb-5">
              <p className="text-[13px] text-[#545454]">عدد الأفراد</p>
              <p className="text-[12px]">
                {userFormData?.person_count || "غير متوفر"}
              </p>
            </div>
          </div>
        </div>

        {/* Service and Provider Details */}
        <div className="bg-[#E2E8FF] p-5 pb-10 flex flex-col gap-[20px] w-[50%] miilg:w-[100%]">
          <div>
            <div className="flex justify-between w-[95%] items-center mb-5">
              <p className="text-[13px] text-[#545454]">نوع الخدمة</p>
              <p className="text-[12px] text-[#0B107A] font-bold">
                {selectedCategory?.name || "غير متوفر"}
              </p>
            </div>
            <div className="flex justify-between w-[95%] items-center mb-5">
              <p className="text-[13px] text-[#545454]">اسم مزود الخدمة</p>
              <p className="text-[12px] text-[#0B107A]">
                {selectedProvider?.name || "غير متوفر"}
              </p>
            </div>
            <div className="flex justify-between w-[95%] items-center mb-5">
              <p className="text-[13px] text-[#545454]">إجمالي السعر</p>
              <p className="text-[12px]">
                {selectedProvider?.total_price || "غير متوفر"} ريال سعودي
              </p>
            </div>
          </div>

          <div className="flex flex-col">
            <p className="text-[13px] mb-[20px] text-[#545454]">
              الخدمات المختارة
            </p>
            <div className="flex gap-[20px] gap-y-[50px] miilg:w-[100%] miilg:flex-wrap">
              {selectedServices?.length > 0 ? (
                selectedServices.map((service: any) => (
                  <div key={service.id} className="w-[5rem] h-[5rem]">
                    <img
                      src={service.image}
                      className="rounded-[50%] h-full w-full object-cover"
                      alt={service.title}
                    />
                    <h3 className="text-[10px] text-center mt-[10px] text-[#090357]">
                      {service.title}
                    </h3>
                  </div>
                ))
              ) : (
                <p className="text-[12px]">لا توجد خدمات مختارة</p>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Go to Payment Button */}
      <div className="text-center mt-7">
        <Button
          loading={isLoading}
          className="bg-[#0B247C] text-[#ffff] pr-5 pl-5 text-[12px]"
          onClick={handleOrderClick}
        >
          تأكيد الطلب
        </Button>
      </div>
    </div>
  );
};

export default OrderDetails;
