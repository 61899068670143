import { Button, Modal, Form, message, Input, Select, Upload } from "antd";
import { useState } from "react";
import { DownloadOutlined } from "@ant-design/icons";
import {
  useAddCategoryMutation,
  useGetCitiesQuery,
  useGetGovernmentsQuery,
} from "../../../redux/Service/api";

function AddServiceType() {
  const [form] = Form.useForm();
  const [addServiceTypeModal, setAddServiceTypeModal] = useState(false);
  const [modalKey, setModalKey] = useState(0);
  const [file, setFile] = useState<null | File>(null);

  const { data: allCitiesData } = useGetCitiesQuery(undefined);
  const { data: allGovernmentsData } = useGetGovernmentsQuery(undefined);

  const allCities = allCitiesData?.data?.data ?? [];
  const allGovernments = allGovernmentsData?.data?.data ?? [];
  const [addServiceType, { isLoading }] = useAddCategoryMutation();

  const handleFileChange = ({ fileList }: any) => {
    if (fileList.length > 0) {
      setFile(fileList[0].originFileObj);
    } else {
      setFile(null);
    }
  };

  const handleServiceType = async (values: any) => {
    if (!file) {
      message.error("Please upload a file.");
      return;
    }

    const formData = new FormData();
    formData.append("image", file);
    formData.append("name", values.nameAR);
    formData.append("name_en", values.nameEN);
    // formData.append("government_id", values.government_id);
    // values.city_id.forEach((cityId: string) =>
    //   formData.append("cities[]", cityId)
    // );

    try {
      const res = await addServiceType(formData).unwrap();
      form.resetFields();
      setModalKey((prev) => prev + 1);
      message.success(res?.message || "Category added successfully");
      setAddServiceTypeModal(false);
    } catch (error) {
      console.error("Error adding category:", error);
      message.error("An error occurred while adding the category.");
    }
  };

  return (
    <>
      <div className="text-center my-3">
        <Button
          className="w-[150px]"
          type="primary"
          onClick={() => setAddServiceTypeModal(true)}
        >
          اضافه
        </Button>
      </div>
      <Modal
        key={modalKey}
        title="اضافه نوع خدمه"
        style={{ top: 20 }}
        open={addServiceTypeModal}
        onCancel={() => setAddServiceTypeModal(false)}
        width={800}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleServiceType}
          initialValues={{
            nameAR: "",
            nameEN: "",
          }}
        >
          <Form.Item
            label="اسم النوع عربى"
            name="nameAR"
            rules={[
              { required: true, message: "Please enter the Arabic name" },
            ]}
          >
            <Input placeholder="Enter Arabic name" />
          </Form.Item>
          <Form.Item
            label="اسم النوع لغه اخرى"
            name="nameEN"
            rules={[
              {
                required: true,
                message: "Please enter the other language name",
              },
            ]}
          >
            <Input placeholder="Enter English name" />
          </Form.Item>
          {/* <Form.Item
            label="المنطقه"
            name="government_id"
            rules={[{ required: true, message: "Please select a government" }]}
          >
            <Select
              showSearch
              placeholder="Select Government"
              optionFilterProp="label"
              options={allGovernments?.map((gov: any) => ({
                value: gov.id,
                label: gov.name,
              }))}
            />
          </Form.Item> */}
          {/* <Form.Item
            label="المدينه"
            name="city_id"
            rules={[
              { required: true, message: "Please select at least one city" },
            ]}
          >
            <Select
              mode="multiple"
              showSearch
              placeholder="Select Cities"
              optionFilterProp="label"
              options={allCities?.map((city: any) => ({
                value: city.id,
                label: city.name,
              }))}
            />
          </Form.Item> */}
          <Form.Item
            label="الصوره"
            valuePropName="file"
            rules={[{ required: true, message: "Please upload an image" }]}
          >
            <Upload
              customRequest={({ file, onSuccess }: any) => {
                setTimeout(() => onSuccess("ok"), 0);
              }}
              onChange={handleFileChange}
              listType="picture"
              maxCount={1}
            >
              <Button icon={<DownloadOutlined />}> اختر صوره (Max: 1) </Button>
            </Upload>
          </Form.Item>
          <div className="flex justify-end">
            <Button htmlType="submit" type="primary" loading={isLoading}>
              حفظ
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}

export default AddServiceType;
