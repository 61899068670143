import { RightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import { Button, Form, Input, DatePicker, InputNumber } from "antd";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { saveOrderForm } from "../../redux/Slices/userOrderSlice";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import NavigateBack from "../../Components/utils/NavigateBack";

const OrderForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { userFormData, selectedServices, selectedCategory } = useSelector(
    (state: any) => state.userOrder.orderData
  );

  useEffect(() => {
    if (userFormData) {
      form.setFieldsValue({
        ...userFormData,
        from_date: dayjs(userFormData.from_date),
        to_date: dayjs(userFormData.to_date),
      });
    }
  }, [userFormData]);

  const handleSubmit = (values: any) => {
    console.log("Form Submitted:", values);
    dispatch(saveOrderForm(values));
    navigate("/orderReview");
    // Dispatch or handle the form values here
  };

  return (
    <div className="container pb-12 pt-5">
      <h2 className="flex mb-5 mt-5 items-center justify-between w-[60%] gap-2 font-bold text-[25px] miilg:text-[20px] mid:text-[18px]">
        <NavigateBack
          url={`/AvailableProviders/${selectedCategory?.id}/${selectedServices
            ?.map((item: any) => item.id)
            .join("-")}`}
        />
        <span className="ani bg-[#110A62] mid:text-[15px] text-center text-[#ffff] mid:w-[80%] w-[28%] pt-3 pb-3 block">
          {`البيانات`}
        </span>
      </h2>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        className="max-w-[600px] mx-auto"
      >
        {/* Address Input */}
        <Form.Item
          label="العنوان"
          name="address"
          rules={[{ required: true, message: "الرجاء إدخال العنوان" }]}
        >
          <Input placeholder="أدخل العنوان" />
        </Form.Item>

        {/* From Date Input */}
        <Form.Item
          label="تاريخ البدء"
          name="from_date"
          rules={[{ required: true, message: "الرجاء اختيار تاريخ البدء" }]}
        >
          <DatePicker
            format="YYYY-MM-DD"
            className="w-full"
            placeholder="اختر تاريخ البدء"
          />
        </Form.Item>

        {/* To Date Input */}
        <Form.Item
          label="تاريخ الانتهاء"
          name="to_date"
          rules={[
            { required: true, message: "الرجاء اختيار تاريخ الانتهاء" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (
                  !value ||
                  dayjs(value).isAfter(dayjs(getFieldValue("from_date")))
                ) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("تاريخ الانتهاء يجب أن يكون بعد تاريخ البدء")
                );
              },
            }),
          ]}
        >
          <DatePicker
            format="YYYY-MM-DD"
            className="w-full"
            placeholder="اختر تاريخ الانتهاء"
          />
        </Form.Item>

        {/* Person Count Input */}
        <Form.Item
          label="عدد الأفراد"
          name="person_count"
          rules={[
            { required: true, message: "الرجاء إدخال عدد الأفراد" },
            { type: "number", min: 1, message: "يجب أن يكون العدد أكبر من 0" },
          ]}
        >
          <InputNumber
            min={1}
            className="w-full"
            placeholder="أدخل عدد الأفراد"
          />
        </Form.Item>

        {/* Notes Input */}
        <Form.Item label="ملاحظات" name="note">
          <Input.TextArea rows={4} placeholder="أدخل أي ملاحظات" />
        </Form.Item>

        {/* Submit Button */}
        <Form.Item>
          <Button type="primary" htmlType="submit" className="w-full">
            التالي
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default OrderForm;
