import React, { useEffect, useState } from "react";
import { Modal, Upload, message, Button, Input, Spin } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import {
  useEditArticleMutation,
  useGetFullArticleDetailsQuery,
} from "../../../redux/Service/api";

const { TextArea } = Input;

const EditArticle = ({
  setOpenEditModal,
  openEditModal,
  articleIdForEdit,
  rerenderEditKey,
}) => {
  const [modalKey, setModalKey] = useState(0);
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState("");
  const [titleEN, setTitleEN] = useState("");
  const [content, setContent] = useState("");
  const [contentEN, setContentEN] = useState("");

  const {
    data: articleDetailsData,
    isLoading: isArticleLoading,
    isError: isArticleError,
  } = useGetFullArticleDetailsQuery(articleIdForEdit);

  const articleDetails = articleDetailsData?.data;

  useEffect(() => {
    if (articleDetails) {
      setTitle(articleDetails?.title);
      setTitleEN(articleDetails?.title_en);
      setContent(articleDetails?.content);
      setContentEN(articleDetails?.content_en);
      setFile([
        {
          uid: "-1",
          name: "Main-image",
          status: "done",
          url: articleDetails?.photo,
        },
      ]);
    }
  }, [articleDetails, rerenderEditKey]);

  const [editArticle, { isLoading: isEditLoading }] = useEditArticleMutation();

  const handleFileChange = (fileList) => {
    if (fileList.length > 0) {
      setFile(fileList[0].originFileObj);
    } else {
      setFile(null);
    }
  };

  const handleEditArticle = async () => {
    if (!file) {
      message.error("يرجى تحميل الصورة");
      return;
    }
    if (!title) {
      message.error("يرجى إدخال عنوان المقال");
      return;
    }
    if (!titleEN) {
      message.error("يرجى إدخال العنوان باللغة الإنجليزية");
      return;
    }
    if (!content) {
      message.error("يرجى إدخال محتوى المقال");
      return;
    }
    if (!contentEN) {
      message.error("يرجى إدخال المحتوى باللغة الإنجليزية");
      return;
    }

    const formData = new FormData();
    if (typeof file === "object" && !Array.isArray(file)) {
      formData.append("photo", file);
    }
    formData.append("title", title);
    formData.append("title_en", titleEN);
    formData.append("content", content);
    formData.append("content_en", contentEN);

    try {
      const res = await editArticle({
        id: articleIdForEdit,
        articleBody: formData,
      });
      setFile(null);
      setTitle("");
      setTitleEN("");
      setContent("");
      setContentEN("");
      setModalKey((prev) => prev + 1);
      res?.data?.message
        ? message.info(res.data.message)
        : message.success("تم تعديل المقال بنجاح");
      setOpenEditModal(false);
    } catch (error) {
      console.error("Error editing article:", error);
      message.error("حدث خطأ أثناء تعديل المقال. حاول مرة أخرى.");
    }
  };

  return (
    <Modal
      key={modalKey}
      title="تعديل مقال"
      centered
      open={openEditModal}
      onCancel={() => setOpenEditModal(false)}
      width={800}
      footer={null}
    >
      {isArticleLoading && (
        <div className="text-center my-4">
          <Spin size="large" />
          <p>جاري تحميل تفاصيل المقال...</p>
        </div>
      )}
      {isArticleError && (
        <p className="text-red-500">حدث خطأ أثناء تحميل التفاصيل</p>
      )}
      {!isArticleLoading && !isArticleError && (
        <div className="space-y-6">
          <Input
            placeholder="عنوان المقال"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-full"
          />
          <Input
            placeholder="العنوان باللغة الإنجليزية"
            value={titleEN}
            onChange={(e) => setTitleEN(e.target.value)}
            className="w-full"
          />
          <TextArea
            placeholder="محتوى المقال"
            rows={4}
            value={content}
            onChange={(e) => setContent(e.target.value)}
            className="w-full"
          />
          <TextArea
            placeholder="المحتوى باللغة الإنجليزية"
            rows={4}
            value={contentEN}
            onChange={(e) => setContentEN(e.target.value)}
            className="w-full"
          />
          <Upload
            customRequest={({ file, onSuccess }) => {
              try {
                onSuccess();
              } catch (error) {
                console.error("Error handling file:", error);
              }
            }}
            onChange={({ fileList }) => handleFileChange(fileList)}
            listType="picture"
            maxCount={1}
            defaultFileList={file}
          >
            <Button icon={<UploadOutlined />}>تحميل صورة (حد أقصى: 1)</Button>
          </Upload>
          <div className="text-right">
            <Button
              type="primary"
              onClick={handleEditArticle}
              loading={isEditLoading}
            >
              تعديل المقال
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default EditArticle;
