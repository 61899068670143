import Heading from "../../../Components/Heading/Heading";
import ModalCommissionSettings from "./Modal/ModalCommissionSettings";
import TableCommissionSettings from "./TableCommissionSettings";

function CommissionSettings() {
  return (
    <>
      <Heading heading="الخدمات" />
      <ModalCommissionSettings />
      <TableCommissionSettings />
    </>
  );
}

export default CommissionSettings;
