import { useState } from "react";
import Heading from "../../../Components/Heading/Heading";
import AddService from "./AddService";
import TableServiceSettings from "./TableServiceSettings";
import EditService from "./EditService";

function ServiceSettings() {
  const [serviceIdForEdit, setServiceIdForEdit] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);

  return (
    <>
      <Heading heading="الخدمات" />
      <AddService />
      <EditService
        openEditModal={openEditModal}
        serviceIdForEdit={serviceIdForEdit}
        setOpenEditModal={setOpenEditModal}
      />
      <TableServiceSettings
        setServiceIdForEdit={setServiceIdForEdit}
        setOpenEditModal={setOpenEditModal}
      />
    </>
  );
}

export default ServiceSettings;
