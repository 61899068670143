import LatestNews1 from "../../assets/LatestNews1.svg";
import LatestNews2 from "../../assets/LatestNews2.svg";
import { useGetAllNewsQuery } from "../../redux/Service/api";
function LatestNews() {
  const { data, isLoading, isError } = useGetAllNewsQuery(undefined);

  const allNews = data?.data ?? [];

  console.log("allNews", allNews);

  return (
    <div className="container mt-7 md:mt-14">
      <h1 className="text-xl md:text-3xl font-bold mb-4">اخر الاخبار </h1>
      <div className="grid grid-cols-1 gap-5 md:gap-10">
        {(isLoading || isError) && (
          <div
            className="h-[200px] md:h-[400px] rounded-tl-3xl relative"
            style={{
              backgroundImage: `url(${LatestNews1})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <h1 className="bg-[#294BA1E0] text-white text-sm md:text-3xl tracking-wider absolute left-0 bottom-5 w-full p-3 md:p-5 ">
              السعودية تلغي رسوم تراخيص الفنادق في مسعى لدعم السياحة
            </h1>
          </div>
        )}
        {allNews?.map((item: any) => (
          <div
            key={item.id}
            className="h-[200px] md:h-[400px] rounded-tl-3xl relative"
            style={{
              backgroundImage: `url(${item.photo})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <h1 className="bg-[#294BA1E0] text-white text-sm md:text-3xl tracking-wider absolute left-0 bottom-5 w-full p-3 md:p-5 ">
              {item.title}
            </h1>
          </div>
        ))}
      </div>
    </div>
  );
}

export default LatestNews;
