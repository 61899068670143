import { createSlice } from "@reduxjs/toolkit";

// Utility function to save order data to localStorage
const saveToLocalStorage = (key, data) => {
  try {
    localStorage.setItem(key, JSON.stringify(data));
  } catch (error) {
    console.error("Failed to save to localStorage:", error);
  }
};

// Utility function to load order data from localStorage
const loadFromLocalStorage = (key) => {
  try {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  } catch (error) {
    console.error("Failed to load from localStorage:", error);
    return null;
  }
};

const userOrderSlice = createSlice({
  name: "userOrder",
  initialState: {
    orderData: loadFromLocalStorage("userOrder") || {
      selectedCategory: null,
      selectedServices: [],
      selectedProvider: null,
      userFormData: null,
    },
  },
  reducers: {
    saveSelectedCategory: (state, action) => {
      state.orderData.selectedCategory = action.payload;
      saveToLocalStorage("userOrder", state.orderData);
    },
    saveSelectedServices: (state, action) => {
      state.orderData.selectedServices = action.payload;
      saveToLocalStorage("userOrder", state.orderData);
    },
    saveSelectedProvider: (state, action) => {
      state.orderData.selectedProvider = action.payload;
      saveToLocalStorage("userOrder", state.orderData);
    },
    saveOrderForm: (state, action) => {
      state.orderData.userFormData = action.payload;
      saveToLocalStorage("userOrder", state.orderData);
    },
    clearOrderData: (state) => {
      state.orderData = {
        selectedServices: [],
        selectedProvider: null,
        userFormData: null,
      };
      localStorage.removeItem("userOrder");
    },
  },
});

export const {
  saveSelectedCategory,
  saveSelectedServices,
  saveSelectedProvider,
  saveOrderForm,
  clearOrderData,
} = userOrderSlice.actions;

export default userOrderSlice.reducer;
