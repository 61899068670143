import { useState } from "react";
import Heading from "../../../Components/Heading/Heading";
import AddServiceType from "./AddServiceType";
import TableTypesOfServices from "./TableTypesOfServices";
import EditServiceType from "./EditServiceType";

function TypesOfServices() {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [categoryIdForEdit, setCategoryIdForEdit] = useState(null);
  return (
    <>
      <Heading heading="انواع  الخدمات" />
      <AddServiceType />
      <EditServiceType
        setOpenEditModal={setOpenEditModal}
        openEditModal={openEditModal}
        categoryIdForEdit={categoryIdForEdit}
        setCategoryIdForEdit={setCategoryIdForEdit}
      />
      <TableTypesOfServices
        setCategoryIdForEdit={setCategoryIdForEdit}
        setOpenEditModal={setOpenEditModal}
      />
    </>
  );
}

export default TypesOfServices;
