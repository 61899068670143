import React from "react";

function Reports() {
  return (
    <div>
      <h1>232</h1>
      <h1>232</h1>
      <h1>232</h1>
    </div>
  );
}

export default Reports;
