import { Button, Form, Input, Modal } from 'antd'
import './VerificationCode.scss'
import { useState } from 'react';
import { CheckCircleOutlined } from '@ant-design/icons'; // Example icon
import { Link } from 'react-router-dom';

const info = () => {
	Modal.info({
		title: 'This is a notification message',
		content: (
			<div>
				<p>some messages...some messages...</p>
				<p>some messages...some messages...</p>
			</div>
		),
		onOk() { },
	});
};


const VerificationCode = () => {
	const [IsActiveCode, setIsActiveCode] = useState(false);
	console.log("IsActiveCode", IsActiveCode);

	const HundelActiveCode = () => {
		setIsActiveCode(true);
		if (IsActiveCode) {
			// Modal.success({
			// 	content: 'تم التفعيل بنجاح',
			// 	okButtonProps: { style: { display: 'none' } },
			// 	icon: <CheckCircleOutlined style={{ color: '#52c41a' }} className=' rounded-[50%] text-[#000] bg-green-900' />, // Custom icon and color
			// });


		}

	}
	return (
		<div className='min-h-[70vh] mb-8 flex gap-[60px] items-center justify-center VerificationCode flex-col w-full container'>
			{
				IsActiveCode === true ?
					<div className='bg-[#F2FAFFE0] h-[40vh] w-[40%] miilg:w-[80%]  flex items-center flex-col justify-center gap-[30px]'>
						<div className='w-[70px] rounded-[50%] h-[70px] bg-[#0FCC5D] flex justify-center items-center'>
							<CheckCircleOutlined className='text-[30px]' />
						</div>
						<h2 className='flex items-center flex-col gap-2 font-bold text-[25px] miilg:text-[20px] mid:text-[18px]'>تم التفعيل بنجاح</h2>
						<Link to={'/'} className='text-[#CCFD04] block w-[40%] pt-2 pb-2 rounded-[5px] text-center text-[14px] font-bold bg-[#282828]'>
							عوده
						</Link>
					</div>
					:
					<>
						<div className='flex flex-col items-center gap-[18px]'>
							<svg width="91" height="81" viewBox="0 0 91 50" fill="none" xmlns="http://www.w3.org/2000/svg">
								<ellipse cx="45.3312" cy="40.3312" rx="45.3312" ry="40.3312" fill="white" />
								<path opacity="0.2" d="M36.7084 51.9514L30.9363 56.2684C30.7313 56.4217 30.4813 56.5198 30.2159 56.551C29.9504 56.5823 29.6804 56.5455 29.4376 56.4449C29.1949 56.3443 28.9895 56.1841 28.8455 55.9832C28.7016 55.7823 28.6251 55.549 28.6251 55.3107V30.4718C28.6251 30.14 28.7733 29.8218 29.037 29.5872C29.3007 29.3525 29.6584 29.2207 30.0314 29.2207H60.9689C61.3418 29.2207 61.6995 29.3525 61.9632 29.5872C62.227 29.8218 62.3751 30.14 62.3751 30.4718V50.4901C62.3751 50.8219 62.227 51.1402 61.9632 51.3748C61.6995 51.6094 61.3418 51.7413 60.9689 51.7413H37.3563L36.7084 51.9514Z" fill="#282828" />
								<path d="M36.7084 51.9514L30.9363 56.2684C30.7313 56.4217 30.4813 56.5198 30.2159 56.551C29.9504 56.5823 29.6804 56.5455 29.4376 56.4449C29.1949 56.3443 28.9895 56.1841 28.8455 55.9832C28.7016 55.7823 28.6251 55.549 28.6251 55.3107V30.4718C28.6251 30.14 28.7733 29.8218 29.037 29.5872C29.3007 29.3525 29.6584 29.2207 30.0314 29.2207H60.9689C61.3418 29.2207 61.6995 29.3525 61.9632 29.5872C62.227 29.8218 62.3751 30.14 62.3751 30.4718V50.4901C62.3751 50.8219 62.227 51.1402 61.9632 51.3748C61.6995 51.6094 61.3418 51.7413 60.9689 51.7413H37.3563L36.7084 51.9514Z" stroke="#282828" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
								<path d="M39.8751 37.9785H51.1251" stroke="#282828" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
								<path d="M39.8751 42.9834H51.1251" stroke="#282828" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
							</svg>

							<h2 className='flex items-center flex-col gap-2 font-bold text-[25px] miilg:text-[20px] mid:text-[18px]'>
								ادخل الرقم للتفعيل
							</h2>
							<p className='text-[14px] text-[#6F7485] '>
								يرجى التاكد من الرقم المرسل
							</p>
						</div>

						<div className="flex justify-center flex-col items-center confirmEmail w-[50%] miilg:w-[80%] mid:w-full">
							<div className="flex justify-center items-center gap-[30px] w-full">
								<Form.Item name="email" rules={[{ required: false }]}>
									<Input className='w-full text-[13px] h-[36px] rounded-none text-center font-bold bg-[#F6F6F6]' placeholder="كود التفعيل" />
								</Form.Item>
							</div>
							<Button
								onClick={HundelActiveCode}

								className='bg-[#21005D] text-[#ffffff] mid:text-[12px] w-[50%] pt-2 pb-2'>
								تفعيل
							</Button>
							<p className='text-[12px] cursor-pointer text-[#CCFD04] font-bold mt-6'>
								إعاده الارسال
							</p>
						</div>

					</>
			}


		</div>)
}

export default VerificationCode