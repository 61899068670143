import { Table, TableColumnsType } from "antd";
import { useGetMyOrdersQuery } from "../../redux/Service/api";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const MyOrders = () => {
  const { isAuthenticated } = useSelector((state: any) => state.auth);

  const { data: allOrdersData, isLoading: isAllOrdersLoading } =
    useGetMyOrdersQuery(undefined);

  const allOrders =
    allOrdersData?.data?.map((order: any, idx: number) => ({
      ...order,
      idx: idx + 1,
    })) ?? [];

  console.log("allOrders", allOrders);

  // Table columns
  const columns: TableColumnsType<any> = [
    {
      title: "م",
      dataIndex: "idx",
      key: "id",
      ellipsis: true,
      width: 70,
    },
    {
      title: "اسم الخدمة",
      dataIndex: "title",
      key: "title",
      ellipsis: true,
      width: 150,
      render: (_, record) => (
        <div className="flex flex-col gap-2" key={record.id}>
          {record.services?.map((service: any) => (
            <span>{service.title}</span>
          ))}
        </div>
      ),
    },
    {
      title: "حالة الخدمة",
      dataIndex: "status",
      key: "status",
      ellipsis: true,
      width: 150,
    },
    {
      title: "السعر الكلي",
      dataIndex: "price",
      key: "price",
      ellipsis: true,
      width: 150,
    },
    {
      title: "تاريخ الانشاء",
      dataIndex: "created_at",
      key: "created_at",
      ellipsis: true,
      width: 150,
    },
    {
      title: "تاريخ التحديث",
      dataIndex: "updated_at",
      key: "updated_at",
      ellipsis: true,
      width: 150,
    },
  ];

  if (!isAuthenticated) {
    return (
      <div className="flex justify-center items-center h-96 rounded-lg shadow-lg bg-gray-100">
        <div className="text-center p-8">
          <p className="text-xl font-semibold text-gray-700 mb-6">
            يرجي تسجيل الدخول للوصول لهذه الصفحة
          </p>
          <Link
            to="/login"
            className="px-6  py-2 bg-blue-500 text-white rounded-md shadow hover:bg-blue-600 transition duration-300"
          >
            تسجيل الدخول
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="container  flex flex-col items-center mt-4">
      {/* Add Service Button */}
      <div className="flex justify-center w-full items-center text-[#110A62] mb-4">
        <span className="ani bg-[#110A62] mid:text-[15px] text-center text-[#ffff] mid:w-[80%] w-[28%] pt-3 pb-3 block">
          طلباتي
        </span>
      </div>
      {/* Table */}
      <Table
        columns={columns}
        dataSource={allOrders}
        loading={isAllOrdersLoading}
        bordered
        rowKey="id"
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: [9, 10, 20, 30, 50],
          showTotal: (total) => `الإجمالي: ${total}`,
        }}
        scroll={{ y: 400, x: 500 }}
      />

      {/*  */}
    </div>
  );
};

export default MyOrders;
