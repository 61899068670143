import { Skeleton } from "antd";
import React from "react";

const LoadingSkeltonCard = ({ count = 1 }) => {
  return (
    <>
      {Array.from({ length: count }).map((_, index) => (
        <div
          key={index}
          className="flex gap-4 flex-col justify-between flex-nowrap rounded-[8px] bg-[#E2E8FF] pt-3 pb-3 pl-2 pr-2 mid:min-w-[100%] min-w-[32%]"
        >
          <Skeleton.Avatar active size={80} shape="circle" />
          <Skeleton active paragraph={{ rows: 2 }} title={false} />
        </div>
      ))}
    </>
  );
};

export default LoadingSkeltonCard;
