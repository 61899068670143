import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Table,
  TableColumnsType,
  message,
} from "antd";
import React, { useState } from "react";
import {
  useAddProviderServiceMutation,
  useDeleteProviderServiceMutation,
  useGetAllServicesQuery,
  useGetProviderServicesQuery,
} from "../../redux/Service/api";
import { ExclamationCircleFilled } from "@ant-design/icons";

const { confirm } = Modal;
const { Option } = Select;
const ServicesIDo = () => {
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  // Form for adding a new service
  const [form] = Form.useForm();
  // Fetch all services for the "Add Service" modal
  const { data: allServicesData, isLoading: isAllServicesLoading } =
    useGetAllServicesQuery(undefined);

  const allServices = allServicesData?.data?.data ?? [];

  // Fetch provider services
  const {
    data: providerServicesData,
    isError: isProviderServicesError,
    isLoading: isProviderServicesLoading,
  } = useGetProviderServicesQuery(undefined);

  const providerServices =
    providerServicesData?.data?.data?.map((service: any, idx: number) => {
      return { ...service, idx: idx + 1 };
    }) ?? [];

  // Mutations
  const [deleteProviderService, { isLoading: delIsLoading }] =
    useDeleteProviderServiceMutation();
  const [addProviderService, { isLoading: addIsLoading }] =
    useAddProviderServiceMutation();
  // State for modal and filters
  const handleAddService = async (values: any) => {
    try {
      const res = await addProviderService(values).unwrap();
      message.success("تم إضافة الخدمة بنجاح.");
      setIsAddModalVisible(false);
      form.resetFields();
    } catch (error) {
      message.error("فشل إضافة الخدمة. الرجاء المحاولة مرة أخرى.");
    }
  };
  // Delete service confirmation
  const handleDeleteService = async (id: any) => {
    confirm({
      title: "هل أنت متأكد أنك تريد حذف هذه الخدمة؟",
      icon: <ExclamationCircleFilled />,
      content: "عند النقر على موافق، سيتم إزالة هذه الخدمة.",
      async onOk() {
        try {
          const res = await deleteProviderService(id);
          res.data.message
            ? message.info(res.data.message)
            : message.success("تم حذف الخدمة بنجاح.");
        } catch (error) {
          console.log(error);
        }
      },
    });
  };
  // Table columns
  const columns: TableColumnsType<any> = [
    {
      title: "م",
      dataIndex: "idx",
      key: "id",
      ellipsis: true,
      width: 70,
    },
    {
      title: "اسم الخدمة",
      dataIndex: "title",
      key: "title",
      ellipsis: true,
      width: 150,
    },
    {
      title: "السعر",
      dataIndex: "price",
      key: "price",
      ellipsis: true,
      width: 150,
    },
    {
      title: "وصف الخدمة",
      dataIndex: "description",
      key: "description",
      ellipsis: true,
      width: 150,
    },
    {
      title: "تاريخ الانشاء",
      dataIndex: "created_at",
      key: "created_at",
      ellipsis: true,
      width: 150,
    },
    {
      title: "الإجراءات",
      key: "actions",
      render: (_, service) => (
        <div className="grid grid-cols-2 items-center justify-center gap-2">
          <Button
            type="primary"
            danger
            onClick={() => handleDeleteService(service.id)}
          >
            حذف
          </Button>
        </div>
      ),
      width: 130,
    },
  ];

  return (
    <div className="container mt-7 md:mt-14 flex flex-col items-center">
      {/* Add Service Button */}
      <div className="flex justify-between w-full items-center text-[#110A62]">
        <p>الخدمات التي اقدمها</p>
        <Button
          type="primary"
          onClick={() => setIsAddModalVisible(true)}
          style={{ marginBottom: 16 }}
        >
          إضافة خدمة
        </Button>
      </div>
      {/* Table */}
      <Table
        columns={columns}
        dataSource={providerServices}
        loading={isProviderServicesLoading}
        bordered
        rowKey="id"
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: [9, 10, 20, 30, 50],
          showTotal: (total) => `الإجمالي: ${total}`,
        }}
        scroll={{ y: 400, x: 500 }}
      />

      {/* Add Service Modal */}
      <Modal
        title="إضافة خدمة جديدة"
        open={isAddModalVisible}
        onCancel={() => setIsAddModalVisible(false)}
        footer={null}
      >
        <Form form={form} onFinish={handleAddService}>
          <Form.Item
            name="service_id"
            label="الخدمة"
            rules={[{ required: true, message: "الرجاء اختيار الخدمة" }]}
          >
            <Select loading={isAllServicesLoading}>
              {allServices.map((service: any) => (
                <Option key={service.id} value={service.id}>
                  {service.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="price"
            label="السعر"
            rules={[{ required: true, message: "الرجاء إدخال السعر" }]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={addIsLoading}>
              إضافة
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/*  */}
    </div>
  );
};

export default ServicesIDo;
