import { Link, useNavigate } from "react-router-dom";
import "./ourservices.scss";
import { useGetAllCategoriesQuery } from "../../redux/Service/api";
import { Spin, Alert } from "antd";
import { useDispatch } from "react-redux";
import { saveSelectedCategory } from "../../redux/Slices/userOrderSlice";

const Ourservices = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    data: allCategoriessData,
    isError: isAllCategoriesError,
    isLoading: isAllCategoriesLoading,
    error: allCategoriesError,
  } = useGetAllCategoriesQuery(null);

  const allCategories = allCategoriessData?.data?.data ?? [];

  const handleSelectCategory = (category: any) => {
    dispatch(saveSelectedCategory(category));
    navigate(`/ourservices/${category?.id}`);
  };

  return (
    <div className="bg-[#fff] Ourservices pb-20">
      <div className="flex flex-col items-center">
        <h2 className="font-bold text-[20px] w-full flex justify-center text-center miilg:text-[20px] mid:text-[18px] mid:mt-6 mid:mb-6 mt-12 mb-12">
          <span className="ani bg-[#110A62] mid:text-[15px] text-[#ffff] mid:w-[80%] w-[28%] pt-3 pb-3 block">
            خدماتنا
          </span>
        </h2>
        <div className="flex justify-start flex-wrap gap-5 items-center">
          {isAllCategoriesLoading && (
            // Loading Spinner
            <div className="w-full flex justify-center items-center">
              <Spin size="large" />
            </div>
          )}
          {!isAllCategoriesLoading && isAllCategoriesError && (
            // Error Message
            <div className="w-full flex justify-center items-center">
              <Alert
                message="خطأ"
                description="حدث خطأ أثناء جلب البيانات. يرجى المحاولة مرة أخرى لاحقاً."
                type="error"
                showIcon
              />
            </div>
          )}
          {!isAllCategoriesLoading &&
            !isAllCategoriesError &&
            allCategories.length === 0 && (
              // Empty State
              <div className="w-full flex justify-center items-center">
                <p className="text-gray-600 text-[18px]">
                  لا توجد خدمات متاحة حالياً
                </p>
              </div>
            )}
          {!isAllCategoriesLoading &&
            !isAllCategoriesError &&
            allCategories.map((cat: any) => (
              <Link
                to={`/ourservices/${cat?.id}`}
                onClick={(e) => {
                  e.preventDefault();
                  handleSelectCategory(cat);
                }}
                key={cat?.id}
                className="hover:scale-105 transition-all duration-300"
              >
                <div className="flex flex-col items-center gap-3 mid:w-full border-[1px] solid border-[#ccc] p-2">
                  <div className="w-[270px] mid:w-full h-[200px] mid:h-[250px]">
                    <img
                      src={cat?.image}
                      className="w-full h-full"
                      alt={cat?.name}
                    />
                  </div>
                  <p>{cat?.name}</p>
                </div>
              </Link>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Ourservices;
