import { Link } from "react-router-dom";
import workerbanner from "../../assets/Png/worker.jpeg";
import workericon1 from "../../assets/Png/workericon1.png";
import workericon2 from "../../assets/Png/workericon2.png";
import workericon3 from "../../assets/Png/workericon3.png";
import { useSelector } from "react-redux";
const JoinWorker = () => {
  const userData = useSelector((state: any) => state.auth.userData);
  return (
    <div className="">
      <img
        src={workerbanner}
        className="w-full h-[100vh] object-cover miilg:object-fill miilg:h-[50vh]"
        alt=""
      />
      <div className="container pb-12 pt-12">
        <h2 className="flex mb-10 items-center flex-col gap-2 font-bold text-[25px] miilg:text-[20px] mid:text-[18px]">
          انضم لفريق عمل مزودى الخدمه ؟
        </h2>
        <div className="flex justify-between items-center flex-wrap gap-y-[30px]">
          <div className="flex items-center justify-center flex-col gap-[10px] min-w-[49%] miilg:min-w-[100%]">
            <img src={workericon1} className="w-[60px] h-[60px] " alt="" />
            <div>
              <h3 className="text-[14px] mb-[10px] text-center">
                زياده فرص العمل
              </h3>
              <p className="text-[12px] text-[#636363] text-center">
                قم بتوسيع قاعدة عملائك واستمتع بالمرونة ساعات العمل
              </p>
            </div>
          </div>

          <div className="flex items-center justify-center gap-[10px] flex-col min-w-[49%] miilg:min-w-[100%]">
            <img src={workericon2} className="w-[60px] h-[60px]" alt="" />
            <div>
              <h3 className="text-[14px] mb-[10px] text-center">
                اكتسب خبرات وشهره من خلالنا
              </h3>
              <p className="text-[12px] text-[#636363] text-center">
                بناء المصداقية من خلال مراجعات المستخدم واعرض عملك.
              </p>
            </div>
          </div>

          <div className="flex items-center justify-center gap-[10px] flex-col min-w-[49%] miilg:min-w-[100%]">
            <img src={workericon3} className="w-[60px] h-[60px]" alt="" />
            <div>
              <h3 className="text-[14px] mb-[10px] text-center">
                زياده فرص العمل
              </h3>
              <p className="text-[12px] text-[#636363] text-center">
                ستمتع بعملية دفع خالية من المتاعب مع أرباح آمنة ومباشرة مودعة في
                حسابك حسا
              </p>
            </div>
          </div>
        </div>
        <Link
          to={`${userData.role === "user" ? "/workerLogin" : "/"}`}
          className="mt-10 block w-[200px] mb-0 mr-auto ml-auto bg-[#21005D] text-[#ffff] text-center text-[12px] font-bold pt-3 pb-3 rounded-[5px]"
        >
          {userData.role === "user"
            ? "انضم لفريق عمل"
            : "انت بالفعل عضو في فريق عمل"}
        </Link>
      </div>
    </div>
  );
};

export default JoinWorker;
