// import ModalSystemUsers from "./Modal/ModalCustomer";
// import TableSystemUsers from "./TableCustomer";

import Heading from "../../../Components/Heading/Heading";
import AddCity from "./AddCity";
import TableCitySettings from "./TableCitySettings";

function CitySettings() {
  return (
    <>
      <Heading heading="إعدادت المدن" />
      <AddCity />
      <TableCitySettings />
    </>
  );
}

export default CitySettings;
