import { AccountBookOutlined, CloseOutlined, EnvironmentOutlined, FileImageOutlined, RightOutlined, UploadOutlined } from "@ant-design/icons"
import CategoriesDrawer from "../Categories/CategoriesDrawer";
import catogery1 from '../../assets/Png/catogery1.png'
import catogery2 from '../../assets/Png/catogery2.png'
import order1 from '../../assets/Png/order1.jpeg'
import order2 from '../../assets/Png/order2.png'
import { Button, DatePicker, DatePickerProps, Input, Upload, Form } from "antd";
import TextArea from "antd/es/input/TextArea";
import './PlaceHolder.scss'
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
const PlaceHolder = () => {
	const Navigate = useNavigate()
	const onChange: DatePickerProps['onChange'] = (date, dateString) => {
		console.log(date, dateString);
	};
	return (
		<div className="container pb-20 placeholder">
			<div className="flex justify-between w-[50%] mid:w-[100%] items-center">
				<RightOutlined onClick={() => Navigate('/categories')} />
				<h2 className=' font-bold text-[20px] w-full flex justify-end text-center miilg:text-[20px] mid:text-[18px] mt-12 mb-12'>
					<span className='ani text-center text-[#110A62] text-[#ffff] pt-3 pb-3 block'>
						كشته بريه
					</span>
				</h2>
			</div>
			<div className='flex justify-start flex-wrap gap-5 items-center'>
				<div className='flex flex-col items-center gap-3 mid:w-full border-[1px] solid border-[#ccc] p-2'>
					<div className='w-[270px] mid:w-full h-[200px] mid:h-[250px]'>
						<img src={catogery1} className='w-full h-full' alt="" />
					</div>
					<p>
						ضيـافة وقـهـوة
					</p>

				</div>
				<div className='flex flex-col items-center gap-3 mid:w-full border-[1px] solid border-[#ccc] p-2'>

					<div className='w-[270px] mid:w-full h-[200px] mid:h-[250px]'>

						<img src={order1} className='w-full h-full' alt="" />
					</div>
					<p>
						حفله شواء
					</p>
				</div>
				<div className='flex flex-col items-center gap-3 mid:w-full border-[1px] solid border-[#ccc] p-2'>

					<div className='w-[270px] mid:w-full h-[200px] mid:h-[250px]'>

						<img src={catogery2} className='w-full h-full' alt="" />
					</div>
					<p>
						تجهيز خيام
					</p>
				</div>
				<div className='flex flex-col items-center gap-3 mid:w-full border-[1px] solid border-[#ccc] p-2'>

					<div className='w-[270px] mid:w-full h-[200px] mid:h-[250px]'>

						<img src={order2} className='w-full h-full' alt="" />
					</div>
					<p>
						معد قهوه
					</p>
				</div>
			</div>
			<div className="relative flex items-center mt-10 bg-[#E2E8FF]" >
				<Input placeholder="إضافه عنوان" className="w-full h-[40px] text-[#000] bg-[#E2E8FF]" />
				<EnvironmentOutlined className=" absolute left-[10px] text-[20px]" />

			</div>
			<div className="relative flex items-center mt-10 bg-[#E2E8FF]" >
				<Input placeholder="إضافه عنوان" className="w-full h-[40px] text-[#000] bg-[#E2E8FF]" />
				<EnvironmentOutlined className=" absolute left-[10px] text-[20px]" />

			</div>
			<div className="bg-[#E2E8FF] mt-10 p-3 flex justify-between items-center">
				<span className="mid:text-[12px] font-bold">من</span>
				<DatePicker onChange={onChange} className="w-[40%]" />
				<span className="mid:text-[12px] font-bold">الي</span>
				<DatePicker onChange={onChange} className="w-[40%]" />

			</div>
			<div className="relative flex items-center  w-fit mr-auto ml-auto pr-3 pl-3 mt-10 pt-3 pb-3 bg-[#E2E8FF]  justify-center gap-[30px]" >
				<label className="mid:text-[12px] font-bold">عدد الافراد </label>
				<Input placeholder="إضافه عنوان" value={"15"} className="text-center font-bold h-[40px] text-[#000] w-[200px]" />
			</div>
			<div className="bg-[#E2E8FF] relative mt-10 flex justify-between items-center">
				<TextArea rows={3} placeholder="إضافه ملاحظات ( اختيارى )" maxLength={6}
					className="border-0 focus:bg-transparent focus:border-none bg-transparent" />
				<AccountBookOutlined className=" absolute left-[10px] text-[20px]" />
			</div>
			<div className="bg-[#E2E8FF] relative mt-10 flex justify-between items-center pt-3 pb-3">
				<Form.Item
					name="upload"
					label=""
					valuePropName="fileList"
					className='mb-0 w-full'
				// getValueFromEvent={normFile}
				// extra="longgggggggggggggggggggggggggggggggggg"
				>
					<Upload name="logo" action="/upload.do" listType="picture" className="flex">
						<div className="flex justify-between w-full pl-3">
							<Button className='bg-[#E2E8FF] border-0'>
								<span className="mid:text-[12px] font-bold">
									إضافه صور  ( اختيارى )
								</span>
							</Button>
							<FileImageOutlined className=" text-[20px]" />
						</div>
					</Upload>
				</Form.Item>
			</div>
			<div className=" relative mt-10 flex justify-center items-center pt-3 pb-3">
				<Link to={"/companyCatogery"}
					className='bg-[#0B247C] text-[#ffff] w-[200px] pt-[8px] rounded-[5px] pb-[8px] flex items-center flex-col gap-2 mt-8 font-bold text-[20px] miilg:text-[18px] mid:text-[12px]'>
					تاكيد الطلب
				</Link>
			</div>
		</div>
	)
}

export default PlaceHolder