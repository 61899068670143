import "./styles.css";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Layout, Menu, MenuProps, theme } from "antd";
import { useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { LevelKeysProps, MenuItem } from "./SystemSettings/Type";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faFileInvoiceDollar,
  faGear,
  faHandHoldingDollar,
  faUsersBetweenLines,
} from "@fortawesome/free-solid-svg-icons";

const { Header, Sider, Content } = Layout;

const menuSlider: MenuItem[] = [
  {
    key: "",
    icon: <UserOutlined className="!text-xl !font-black" />,
    label: "لوحه التحكم",
  },
  {
    key: "2",
    icon: <FontAwesomeIcon icon={faGear} className="!text-xl !font-black" />,
    label: "إعدادت النظام",
    children: [
      // {
      //   key: "21",
      //   label: <p>إعدادت النظام</p>,
      // },
      { key: "SystemUsers", label: "مستخدمى النظام" },
      { key: "CitySettings", label: "اعدادات المدن" },
      { key: "RegionSettings", label: "إعدادت المناطق" },
      { key: "TypesOfServices", label: <Link to={"#"}>انواع الخدمات</Link> },
      { key: "ServiceSettings", label: <Link to={"#"}>إعدادت الخدمات</Link> },
      // {
      //   key: "CommissionSettings",
      //   label: <Link to={"#"}>اعدادات العموله</Link>,
      // },
      { key: "SliderSettings", label: <Link to={"#"}>اعدادات السليدر</Link> },
      { key: "NewsSettings", label: <Link to={"#"}>اعدادات الاخبار</Link> },
      { key: "ArticleSettings", label: <Link to={"#"}>اعدادات المقال</Link> },
    ],
  },
  {
    key: "3",
    icon: (
      <FontAwesomeIcon
        icon={faUsersBetweenLines}
        className="!text-xl !font-black"
      />
    ),
    label: " العملاء",
    children: [
      { key: "Customer", label: "العملاء" },
      { key: "AccountStatement", label: <Link to={"#"}>كشف الحساب</Link> },
      {
        key: "ClientToProvider",
        label: <Link to={"#"}>طلبات التحويل لمزودين</Link>,
      },
    ],
  },
  {
    key: "4",
    icon: (
      <FontAwesomeIcon icon={faAddressCard} className="!text-xl !font-black" />
    ),
    label: "مزودى الخدمه",
    children: [
      { key: "ServiceProviders", label: <Link to={"#"}>مزودى الخدمه</Link> },
      {
        key: "ServiceProviderStatement",
        label: <Link to={"#"}>كشف حساب</Link>,
      },
    ],
  },
  {
    key: "5",
    icon: (
      <FontAwesomeIcon
        icon={faFileInvoiceDollar}
        className="!text-xl !font-black"
      />
    ),
    label: "فاتوره الخدمات",
    children: [
      {
        key: "BillingReport",
        label: <Link to={"#"}>كل الفواتير</Link>,
      },
      {
        key: "ExecutedInvoices",
        label: <Link to={"#"}>الفواتير المنفذه</Link>,
      },
      {
        key: "CancelledInvoices",
        label: <Link to={"#"}>الفواتير الملغاه</Link>,
      },
      {
        key: "processingInvoices",
        label: <Link to={"#"}>الفواتير الجاريه</Link>,
      },
      {
        key: "pindingInvoices",
        label: <Link to={"#"}>الفواتير المعلقه</Link>,
      },
    ],
  },
  {
    key: "6",
    icon: (
      <FontAwesomeIcon
        icon={faHandHoldingDollar}
        className="!text-xl !font-black"
      />
    ),
    label: "الحسابات",
    children: [
      // { key: "IncomingTransfer", label: <Link to={"#"}>تحويل وارد</Link> },
      // { key: "OutgoingTransfer", label: <Link to={"#"}>تحويل صادر</Link> },
      // { key: "Reports", label: <Link to={"#"}>التقارير</Link> },
      {
        key: "ComplaintsAndSuggestions",
        label: <Link to={"#"}>الشكاوى والاقترحات</Link>,
      },
    ],
  },
];

const getLevelKeys = (items1: LevelKeysProps[]) => {
  const key: Record<string, number> = {};
  const func = (items2: LevelKeysProps[], level = 1) => {
    items2.forEach((item) => {
      if (item.key) {
        key[item.key] = level;
      }
      if (item.children) {
        func(item.children, level + 1);
      }
    });
  };
  func(items1);
  return key;
};

function Dashboard() {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  // بدايه اذا تم فاتح 2 منيو سوف يقفل الاول
  const [stateOpenKeys, setStateOpenKeys] = useState(["1", "1"]);
  const onOpenChange: MenuProps["onOpenChange"] = (openKeys) => {
    const currentOpenKey = openKeys.find(
      (key) => stateOpenKeys.indexOf(key) === -1
    );
    if (currentOpenKey !== undefined) {
      const repeatIndex = openKeys
        .filter((key) => key !== currentOpenKey)
        .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);
      setStateOpenKeys(
        openKeys
          .filter((_, index) => index !== repeatIndex)
          .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey])
      );
    } else {
      setStateOpenKeys(openKeys);
    }
  };

  const levelKeys = getLevelKeys(menuSlider as LevelKeysProps[]);
  // نهايه اذا تم فاتح 2 منيو سوف يقفل الاول

  // تشغيل الرابط بنا علي اسم key
  const handleClick = (event: any) => {
    const clickTab = event.key;
    navigate(`/Dashboard/${clickTab}`);
  };
  // تشغيل الرابط بنا علي اسم key.
  return (
    <Layout className="slider-Dashboard">
      <Sider
        // يظهر زرار تلقائي له تحكم في قفل slide وفتحها
        // breakpoint="xxl"
        // collapsedWidth="0"
        collapsed={collapsed}
        collapsible
        onCollapse={(collapsed, type) => {
          setCollapsed(collapsed);
        }}
        width={200}
        style={{
          position: "fixed",
          height: "100vh",
          top: 0,
          bottom: 0,
          zIndex: 1000,
        }}
      >
        <div
          className="demo-logo-vertical cursor-pointer"
          onClick={() => navigate("/")}
        >
          <img
            src="https://readymadeui.com/readymadeui.svg"
            width={100}
            alt="logo"
          />
        </div>
        <Menu
          className="relative"
          theme="dark"
          mode="inline"
          defaultSelectedKeys={["ControlPanel"]}
          openKeys={stateOpenKeys}
          items={menuSlider}
          onClick={handleClick}
          onOpenChange={onOpenChange}
        />
        <Button
          className="absolute left-[-40px] top-[15%] !bg-blue-700 !text-white
          hover:!text-[#cacaca] hover:!bg-blue-600 active:!text-blue-900 rounded-r-none"
          type="text"
          icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={() => {
            console.log("Button clicked");
            setCollapsed(!collapsed);
          }}
          style={{
            fontSize: "16px",
            width: 40,
            height: 40,
            zIndex: 1000,
          }}
        />
      </Sider>
      <Layout>
        {/* <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        /> */}
        <Content
          className="min-h-full"
          style={{
            marginRight: `${collapsed ? "80px" : "200px"}`,
            padding: "1.2rem",
            // minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
}

export default Dashboard;
