import Heading from "../../../Components/Heading/Heading";
import AddUser from "./AddUser";
import TableSystemUsers from "./TableSystemUsers";

function SystemUsers() {
  return (
    <>
      <Heading heading="مستخدمي النظام" />
      <AddUser />
      <TableSystemUsers />
    </>
  );
}

export default SystemUsers;
