import { Select } from "antd";
import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import type { FormProps } from "antd";
import { Button, Checkbox, Form, Input } from "antd";
import "./register.scss";
import { useRegisterMutation } from "../../redux/Service/api";

type FieldType = {
  username: string;
  country?: string;
  email: string;
  password: string;
  remember?: string;
  confirmPassword: string;
  place: string;
  phone: string;
};
const { Option } = Select;

function Registration() {
  const [form] = Form.useForm();
  console.log(form);

  const Navigate = useNavigate();
  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };
  const HundelRegusterOk = () => {
    Navigate("/");
  };

  const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {
    console.log("onFinish Success:", values);
    const formData = new FormData();

    formData.append("name", values.username);
    formData.append("email", values.email);
    formData.append("password", values.password);
    formData.append("phone", values.phone);

    try {
      const resRegister = await register(formData).unwrap();
      const token = resRegister?.data?.token;
      console.log("token", token);
      if (token) {
        window.localStorage.setItem("hejozatiToken", token);
        Navigate("/Login");
      }
    } catch (err) {
      console.error("Registration failed: ", err);
    }
  };

  const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (
    errorInfo
  ) => {
    console.log("Failed:", errorInfo);
  };
  const [register, { isLoading, isSuccess, isError, error }] =
    useRegisterMutation();

  return (
    <div className="font-[sans-serif] bg-white container my-10 register">
      <div className="grid md:grid-cols-1 items-center gap-8 h-full">
        <div className="flex items-center flex-col p-6 h-full w-full">
          <div className="mb-12">
            <h3 className="text-[#133A9F] md:text-3xl text-2xl font-extrabold max-md:text-center">
              إنشاء حساب جديد
            </h3>
          </div>
          <Form
            className=" w-full flex flex-col gap-[20px]"
            name="basic"
            initialValues={{ remember: true, place: "", country: "" }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            form={form}
          >
            <Form.Item<FieldType>
              label="Username"
              name="username"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <div className="relative flex items-center">
                <Input
                  placeholder="الاسم بالكامل"
                  className="w-full bg-transparent text-sm border-b border-gray-300 focus:border-blue-500 px-2 py-2 outline-none"
                />
                <svg
                  width="28"
                  height="33"
                  className="w-[18px] h-[18px] absolute left-2"
                  viewBox="0 0 28 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M24.2741 27.0586C22.6066 23.665 19.9889 21.2837 16.9472 20.2738C18.426 19.3884 19.6086 17.9423 20.3042 16.1685C20.9998 14.3947 21.1679 12.3967 20.7814 10.4966C20.3949 8.59653 19.4763 6.90514 18.1738 5.69517C16.8712 4.48519 15.2606 3.82715 13.6017 3.82715C11.9428 3.82715 10.3322 4.48519 9.02969 5.69517C7.72715 6.90514 6.80858 8.59653 6.42208 10.4966C6.03558 12.3967 6.20368 14.3947 6.89928 16.1685C7.59488 17.9423 8.77743 19.3884 10.2563 20.2738C7.21453 21.2824 4.5968 23.6637 2.92934 27.0586C2.88355 27.1434 2.85281 27.2383 2.83896 27.3375C2.8251 27.4367 2.82843 27.5381 2.84874 27.6358C2.86904 27.7334 2.9059 27.8252 2.95711 27.9056C3.00831 27.986 3.0728 28.0534 3.14669 28.1037C3.22058 28.154 3.30235 28.1862 3.38707 28.1983C3.4718 28.2105 3.55773 28.2023 3.63969 28.1743C3.72166 28.1463 3.79797 28.099 3.86403 28.0354C3.93009 27.9717 3.98454 27.893 4.0241 27.8039C6.05016 23.6811 9.62977 21.2203 13.6017 21.2203C17.5737 21.2203 21.1533 23.6811 23.1793 27.8039C23.2189 27.893 23.2733 27.9717 23.3394 28.0354C23.4055 28.099 23.4818 28.1463 23.5637 28.1743C23.6457 28.2023 23.7316 28.2105 23.8164 28.1983C23.9011 28.1862 23.9829 28.154 24.0568 28.1037C24.1306 28.0534 24.1951 27.986 24.2463 27.9056C24.2975 27.8252 24.3344 27.7334 24.3547 27.6358C24.375 27.5381 24.3783 27.4367 24.3645 27.3375C24.3506 27.2383 24.3199 27.1434 24.2741 27.0586ZM7.48453 12.525C7.48453 11.1001 7.8433 9.70712 8.51547 8.52232C9.18763 7.33751 10.143 6.41407 11.2608 5.86876C12.3785 5.32346 13.6085 5.18078 14.7951 5.45877C15.9817 5.73677 17.0717 6.42295 17.9272 7.43054C18.7827 8.43814 19.3653 9.72189 19.6014 11.1195C19.8374 12.517 19.7163 13.9657 19.2533 15.2821C18.7903 16.5986 18.0062 17.7238 17.0002 18.5155C15.9943 19.3072 14.8116 19.7297 13.6017 19.7297C11.9799 19.7274 10.4251 18.9676 9.27838 17.617C8.1316 16.2663 7.48649 14.4351 7.48453 12.525Z"
                    fill="#471AA0"
                  />
                </svg>
              </div>
            </Form.Item>

            <Form.Item<FieldType>
              label="المنطقة"
              name="place"
              rules={[{ required: true, message: "Please input your place!" }]}
            >
              <div className="relative flex items-center ">
                <Select
                  placeholder="Select a option and change input text above"
                  onChange={(val) => form.setFieldsValue({ place: val })}
                  allowClear
                >
                  <Option value="male">male</Option>
                  <Option value="female">female</Option>
                  <Option value="other">other</Option>
                </Select>
                <svg
                  width="16"
                  className="w-[18px] h-[18px] absolute left-2"
                  height="18"
                  viewBox="0 0 16 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.10917 15.9935C7.97573 16.0875 7.85135 16.1728 7.73771 16.2493C7.61646 16.1721 7.48198 16.0833 7.33615 15.9832C6.64854 15.5109 5.75677 14.8176 4.87852 13.9529C3.0771 12.1792 1.5625 9.9151 1.5625 7.5C1.5625 4.18629 4.36072 1.5 7.8125 1.5C11.2643 1.5 14.0625 4.18629 14.0625 7.5C14.0625 9.8892 12.4811 12.1468 10.6139 13.9393C9.70792 14.8091 8.79625 15.5099 8.10917 15.9935ZM15.625 7.5C15.625 13.5 7.8125 18 7.8125 18C7.42187 18 0 13.5 0 7.5C0 3.35786 3.49777 0 7.8125 0C12.1272 0 15.625 3.35786 15.625 7.5ZM9.375 7.5C9.375 8.3284 8.67542 9 7.8125 9C6.94958 9 6.25 8.3284 6.25 7.5C6.25 6.67157 6.94958 6 7.8125 6C8.67542 6 9.375 6.67157 9.375 7.5ZM10.9375 7.5C10.9375 9.1569 9.53844 10.5 7.8125 10.5C6.08656 10.5 4.6875 9.1569 4.6875 7.5C4.6875 5.84315 6.08656 4.5 7.8125 4.5C9.53844 4.5 10.9375 5.84315 10.9375 7.5Z"
                    fill="#080341"
                  />
                </svg>
              </div>
            </Form.Item>

            <Form.Item<FieldType>
              label="المنطقة"
              name="country"
              rules={[
                { required: false, message: "Please input your country!" },
              ]}
            >
              <div className="relative flex items-center mt-7">
                <Select
                  defaultValue="اختيار المدينة"
                  // style={{ width: 200 }}
                  onChange={(val) => form.setFieldsValue({ country: val })}
                  className={`w-full`}
                  options={[
                    {
                      label: <span>المدينة</span>,
                      title: "region",
                      options: [
                        { label: <span>طنطا</span>, value: "egypt" },
                        { label: <span>القاهرة</span>, value: "soaudi" },
                      ],
                    },
                  ]}
                />
                <svg
                  width="21"
                  className="w-[18px] h-[18px] absolute left-2"
                  height="22"
                  viewBox="0 0 21 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.2482 2.10302H14.8448C14.6138 2.10302 14.4265 2.29921 14.4265 2.54126V10.6085H11.5564V7.02767V0.438238C11.5564 0.196195 11.369 0 11.138 0H8.62164C8.39064 0 8.20333 0.196195 8.20333 0.438238V6.58939H7.27892V4.16333C7.27892 3.92129 7.0916 3.72509 6.8606 3.72509C6.6296 3.72509 6.44228 3.92129 6.44228 4.16333V6.58939H5.98971C5.75871 6.58939 5.57139 6.78558 5.57139 7.02763V9.15969H3.4303V7.53784C3.4303 7.29579 3.24298 7.0996 3.01198 7.0996C2.78098 7.0996 2.59366 7.29579 2.59366 7.53784V9.15973H1.75181C1.52081 9.15973 1.3335 9.35593 1.3335 9.59797V21.5617C1.3335 21.8038 1.52081 22 1.75181 22H19.2482C19.4792 22 19.6665 21.8038 19.6665 21.5617V2.54126C19.6665 2.29921 19.4792 2.10302 19.2482 2.10302ZM5.57135 21.1235H2.17013V10.0363H5.57135V21.1235ZM10.7197 21.1235H6.40799V7.46591H10.7197V21.1235H10.7197ZM10.7197 6.58939H9.03996V0.876477H10.7197V6.58939ZM14.4261 21.1235H11.5564V11.4849H14.4261V21.1235ZM18.8299 21.1235H15.2631V2.9795H18.8299V21.1235Z"
                    fill="#0B247C"
                  />
                  <path
                    d="M7.64024 8.55225C7.40924 8.55225 7.22192 8.74844 7.22192 8.99048V9.57237C7.22192 9.81441 7.4092 10.0106 7.64024 10.0106C7.87124 10.0106 8.05856 9.81441 8.05856 9.57237V8.99048C8.05856 8.74848 7.87124 8.55225 7.64024 8.55225Z"
                    fill="#0B247C"
                  />
                  <path
                    d="M9.42467 8.55225C9.19367 8.55225 9.00635 8.74844 9.00635 8.99048V9.57237C9.00635 9.81441 9.19367 10.0106 9.42467 10.0106C9.65567 10.0106 9.84298 9.81441 9.84298 9.57237V8.99048C9.84298 8.74848 9.65567 8.55225 9.42467 8.55225Z"
                    fill="#0B247C"
                  />
                  <path
                    d="M9.87071 4.5332C9.63971 4.5332 9.45239 4.7294 9.45239 4.97144V5.55332C9.45239 5.79537 9.63971 5.99156 9.87071 5.99156C10.1017 5.99156 10.289 5.79537 10.289 5.55332V4.97144C10.2891 4.7294 10.1018 4.5332 9.87071 4.5332Z"
                    fill="#0B247C"
                  />
                  <path
                    d="M9.87071 2.24316C9.63971 2.24316 9.45239 2.43936 9.45239 2.6814V3.26329C9.45239 3.50533 9.63971 3.70152 9.87071 3.70152C10.1017 3.70152 10.289 3.50533 10.289 3.26329V2.6814C10.2891 2.43936 10.1018 2.24316 9.87071 2.24316Z"
                    fill="#0B247C"
                  />
                  <path
                    d="M7.64024 11.1035C7.40924 11.1035 7.22192 11.2997 7.22192 11.5418V12.1236C7.22192 12.3657 7.4092 12.5619 7.64024 12.5619C7.87124 12.5619 8.05856 12.3657 8.05856 12.1236V11.5418C8.05856 11.2997 7.87124 11.1035 7.64024 11.1035Z"
                    fill="#0B247C"
                  />
                  <path
                    d="M9.42467 11.1035C9.19367 11.1035 9.00635 11.2997 9.00635 11.5418V12.1236C9.00635 12.3657 9.19367 12.5619 9.42467 12.5619C9.65567 12.5619 9.84298 12.3657 9.84298 12.1236V11.5418C9.84298 11.2997 9.65567 11.1035 9.42467 11.1035Z"
                    fill="#0B247C"
                  />
                  <path
                    d="M7.64024 13.4761C7.40924 13.4761 7.22192 13.6723 7.22192 13.9143V14.4962C7.22192 14.7383 7.4092 14.9345 7.64024 14.9345C7.87124 14.9345 8.05856 14.7383 8.05856 14.4962V13.9144C8.05856 13.6723 7.87124 13.4761 7.64024 13.4761Z"
                    fill="#0B247C"
                  />
                  <path
                    d="M9.42467 13.4761C9.19367 13.4761 9.00635 13.6723 9.00635 13.9143V14.4962C9.00635 14.7383 9.19367 14.9345 9.42467 14.9345C9.65567 14.9345 9.84298 14.7383 9.84298 14.4962V13.9144C9.84298 13.6723 9.65567 13.4761 9.42467 13.4761Z"
                    fill="#0B247C"
                  />
                  <path
                    d="M7.64024 16.0273C7.40924 16.0273 7.22192 16.2235 7.22192 16.4656V17.0475C7.22192 17.2896 7.4092 17.4857 7.64024 17.4857C7.87124 17.4857 8.05856 17.2896 8.05856 17.0475V16.4656C8.05856 16.2235 7.87124 16.0273 7.64024 16.0273Z"
                    fill="#0B247C"
                  />
                  <path
                    d="M9.42467 16.0273C9.19367 16.0273 9.00635 16.2235 9.00635 16.4656V17.0475C9.00635 17.2896 9.19367 17.4857 9.42467 17.4857C9.65567 17.4857 9.84298 17.2896 9.84298 17.0475V16.4656C9.84298 16.2235 9.65567 16.0273 9.42467 16.0273Z"
                    fill="#0B247C"
                  />
                  <path
                    d="M7.64024 18.5786C7.40924 18.5786 7.22192 18.7748 7.22192 19.0169V19.5988C7.22192 19.8408 7.4092 20.037 7.64024 20.037C7.87124 20.037 8.05856 19.8408 8.05856 19.5988V19.0169C8.05856 18.7748 7.87124 18.5786 7.64024 18.5786Z"
                    fill="#0B247C"
                  />
                  <path
                    d="M9.42467 18.5786C9.19367 18.5786 9.00635 18.7748 9.00635 19.0169V19.5988C9.00635 19.8408 9.19367 20.037 9.42467 20.037C9.65567 20.037 9.84298 19.8408 9.84298 19.5988V19.0169C9.84298 18.7748 9.65567 18.5786 9.42467 18.5786Z"
                    fill="#0B247C"
                  />
                  <path
                    d="M16.339 8.55225C16.108 8.55225 15.9207 8.74844 15.9207 8.99048V9.57237C15.9207 9.81441 16.108 10.0106 16.339 10.0106C16.57 10.0106 16.7573 9.81441 16.7573 9.57237V8.99048C16.7573 8.74848 16.57 8.55225 16.339 8.55225Z"
                    fill="#0B247C"
                  />
                  <path
                    d="M17.9003 8.55225C17.6693 8.55225 17.4819 8.74844 17.4819 8.99048V9.57237C17.4819 9.81441 17.6693 10.0106 17.9003 10.0106C18.1313 10.0106 18.3186 9.81441 18.3186 9.57237V8.99048C18.3186 8.74848 18.1313 8.55225 17.9003 8.55225Z"
                    fill="#0B247C"
                  />
                  <path
                    d="M16.339 11.1035C16.108 11.1035 15.9207 11.2997 15.9207 11.5418V12.1236C15.9207 12.3657 16.108 12.5619 16.339 12.5619C16.57 12.5619 16.7573 12.3657 16.7573 12.1236V11.5418C16.7573 11.2997 16.57 11.1035 16.339 11.1035Z"
                    fill="#0B247C"
                  />
                  <path
                    d="M17.9003 11.1035C17.6693 11.1035 17.4819 11.2997 17.4819 11.5418V12.1236C17.4819 12.3657 17.6693 12.5619 17.9003 12.5619C18.1313 12.5619 18.3186 12.3657 18.3186 12.1236V11.5418C18.3186 11.2997 18.1313 11.1035 17.9003 11.1035Z"
                    fill="#0B247C"
                  />
                  <path
                    d="M16.339 13.4761C16.108 13.4761 15.9207 13.6723 15.9207 13.9143V14.4962C15.9207 14.7383 16.108 14.9345 16.339 14.9345C16.57 14.9345 16.7573 14.7383 16.7573 14.4962V13.9144C16.7573 13.6723 16.57 13.4761 16.339 13.4761Z"
                    fill="#0B247C"
                  />
                  <path
                    d="M17.9003 13.4761C17.6693 13.4761 17.4819 13.6723 17.4819 13.9143V14.4962C17.4819 14.7383 17.6693 14.9345 17.9003 14.9345C18.1313 14.9345 18.3186 14.7383 18.3186 14.4962V13.9144C18.3186 13.6723 18.1313 13.4761 17.9003 13.4761Z"
                    fill="#0B247C"
                  />
                  <path
                    d="M16.339 3.68213C16.108 3.68213 15.9207 3.87832 15.9207 4.12037V4.70229C15.9207 4.94434 16.108 5.14053 16.339 5.14053C16.57 5.14053 16.7573 4.94434 16.7573 4.70229V4.12037C16.7573 3.87832 16.57 3.68213 16.339 3.68213Z"
                    fill="#0B247C"
                  />
                  <path
                    d="M17.9003 3.68213C17.6693 3.68213 17.4819 3.87832 17.4819 4.12037V4.70229C17.4819 4.94434 17.6693 5.14053 17.9003 5.14053C18.1313 5.14053 18.3186 4.94434 18.3186 4.70229V4.12037C18.3186 3.87832 18.1313 3.68213 17.9003 3.68213Z"
                    fill="#0B247C"
                  />
                  <path
                    d="M16.339 6.0542C16.108 6.0542 15.9207 6.25039 15.9207 6.49244V7.07432C15.9207 7.31636 16.108 7.51256 16.339 7.51256C16.57 7.51256 16.7573 7.31636 16.7573 7.07432V6.49244C16.7573 6.25039 16.57 6.0542 16.339 6.0542Z"
                    fill="#0B247C"
                  />
                  <path
                    d="M17.9003 6.0542C17.6693 6.0542 17.4819 6.25039 17.4819 6.49244V7.07432C17.4819 7.31636 17.6693 7.51256 17.9003 7.51256C18.1313 7.51256 18.3186 7.31636 18.3186 7.07432V6.49244C18.3186 6.25039 18.1313 6.0542 17.9003 6.0542Z"
                    fill="#0B247C"
                  />
                  <path
                    d="M16.339 16.0273C16.108 16.0273 15.9207 16.2235 15.9207 16.4656V17.0475C15.9207 17.2896 16.108 17.4857 16.339 17.4857C16.57 17.4857 16.7573 17.2896 16.7573 17.0475V16.4656C16.7573 16.2235 16.57 16.0273 16.339 16.0273Z"
                    fill="#0B247C"
                  />
                  <path
                    d="M17.9003 16.0273C17.6693 16.0273 17.4819 16.2235 17.4819 16.4656V17.0475C17.4819 17.2896 17.6693 17.4857 17.9003 17.4857C18.1313 17.4857 18.3186 17.2896 18.3186 17.0475V16.4656C18.3186 16.2235 18.1313 16.0273 17.9003 16.0273Z"
                    fill="#0B247C"
                  />
                  <path
                    d="M16.339 18.5786C16.108 18.5786 15.9207 18.7748 15.9207 19.0169V19.5988C15.9207 19.8408 16.108 20.037 16.339 20.037C16.57 20.037 16.7573 19.8408 16.7573 19.5988V19.0169C16.7573 18.7748 16.57 18.5786 16.339 18.5786Z"
                    fill="#0B247C"
                  />
                  <path
                    d="M17.9003 18.5786C17.6693 18.5786 17.4819 18.7748 17.4819 19.0169V19.5988C17.4819 19.8408 17.6693 20.037 17.9003 20.037C18.1313 20.037 18.3186 19.8408 18.3186 19.5988V19.0169C18.3186 18.7748 18.1313 18.5786 17.9003 18.5786Z"
                    fill="#0B247C"
                  />
                  <path
                    d="M4.65126 10.8887H3.13463C2.90363 10.8887 2.71631 11.0849 2.71631 11.3269C2.71631 11.569 2.90363 11.7651 3.13463 11.7651H4.6513C4.8823 11.7651 5.06962 11.569 5.06962 11.3269C5.06962 11.0849 4.8823 10.8887 4.65126 10.8887Z"
                    fill="#0B247C"
                  />
                  <path
                    d="M4.65126 12.5869H3.13463C2.90363 12.5869 2.71631 12.7831 2.71631 13.0252C2.71631 13.2672 2.90363 13.4634 3.13463 13.4634H4.6513C4.8823 13.4634 5.06962 13.2672 5.06962 13.0252C5.06962 12.7831 4.8823 12.5869 4.65126 12.5869Z"
                    fill="#0B247C"
                  />
                  <path
                    d="M4.65126 14.3394H3.13463C2.90363 14.3394 2.71631 14.5356 2.71631 14.7776C2.71631 15.0196 2.90363 15.2158 3.13463 15.2158H4.6513C4.8823 15.2158 5.06962 15.0196 5.06962 14.7776C5.06962 14.5356 4.8823 14.3394 4.65126 14.3394Z"
                    fill="#0B247C"
                  />
                  <path
                    d="M4.65126 16.0371H3.13463C2.90363 16.0371 2.71631 16.2333 2.71631 16.4753C2.71631 16.7174 2.90363 16.9136 3.13463 16.9136H4.6513C4.8823 16.9136 5.06962 16.7174 5.06962 16.4753C5.06962 16.2333 4.8823 16.0371 4.65126 16.0371Z"
                    fill="#0B247C"
                  />
                  <path
                    d="M4.65126 17.79H3.13463C2.90363 17.79 2.71631 17.9862 2.71631 18.2283C2.71631 18.4703 2.90363 18.6665 3.13463 18.6665H4.6513C4.8823 18.6665 5.06962 18.4703 5.06962 18.2283C5.06962 17.9863 4.8823 17.79 4.65126 17.79Z"
                    fill="#0B247C"
                  />
                  <path
                    d="M4.65126 19.4878H3.13463C2.90363 19.4878 2.71631 19.684 2.71631 19.926C2.71631 20.1681 2.90363 20.3643 3.13463 20.3643H4.6513C4.8823 20.3643 5.06962 20.1681 5.06962 19.926C5.06962 19.684 4.8823 19.4878 4.65126 19.4878Z"
                    fill="#0B247C"
                  />
                  <path
                    d="M13.4841 12.104H12.4134C12.1824 12.104 11.9951 12.3002 11.9951 12.5422C11.9951 12.7843 12.1824 12.9805 12.4134 12.9805H13.4841C13.7151 12.9805 13.9024 12.7843 13.9024 12.5422C13.9024 12.3002 13.7151 12.104 13.4841 12.104Z"
                    fill="#0B247C"
                  />
                  <path
                    d="M13.4841 14.1602H12.4134C12.1824 14.1602 11.9951 14.3564 11.9951 14.5984C11.9951 14.8404 12.1824 15.0366 12.4134 15.0366H13.4841C13.7151 15.0366 13.9024 14.8404 13.9024 14.5984C13.9024 14.3564 13.7151 14.1602 13.4841 14.1602Z"
                    fill="#0B247C"
                  />
                  <path
                    d="M13.4841 16.2632H12.4134C12.1824 16.2632 11.9951 16.4594 11.9951 16.7014C11.9951 16.9435 12.1824 17.1397 12.4134 17.1397H13.4841C13.7151 17.1397 13.9024 16.9435 13.9024 16.7014C13.9024 16.4594 13.7151 16.2632 13.4841 16.2632Z"
                    fill="#0B247C"
                  />
                  <path
                    d="M13.4841 18.4131H12.4134C12.1824 18.4131 11.9951 18.6093 11.9951 18.8513C11.9951 19.0934 12.1824 19.2896 12.4134 19.2896H13.4841C13.7151 19.2896 13.9024 19.0934 13.9024 18.8513C13.9024 18.6093 13.7151 18.4131 13.4841 18.4131Z"
                    fill="#081F73"
                  />
                  <path
                    d="M6.86046 1.88428C6.62946 1.88428 6.44214 2.08047 6.44214 2.32252V2.84841C6.44214 3.09045 6.62946 3.28665 6.86046 3.28665C7.09146 3.28665 7.27878 3.09045 7.27878 2.84841V2.32252C7.27878 2.08047 7.09146 1.88428 6.86046 1.88428Z"
                    fill="#0B247C"
                  />
                </svg>
              </div>
            </Form.Item>

            <div className="mt-6">
              <Form.Item<FieldType>
                label="البريد الاليكترونى"
                name="email"
                rules={[
                  { required: false, message: "Please input your country!" },
                ]}
              >
                <div className="relative flex items-center">
                  <input
                    name="البريد الاليكترونى"
                    type="text"
                    required
                    className="w-full bg-transparent text-sm border-[1px] rounded-[10px] border-gray-300 focus:border-blue-500 px-2 py-2 outline-none"
                    placeholder="Enter email"
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#bbb"
                    stroke="#bbb"
                    className="w-[18px] h-[18px] absolute left-2"
                    viewBox="0 0 682.667 682.667"
                  >
                    <defs>
                      <clipPath id="a" clipPathUnits="userSpaceOnUse">
                        <path
                          d="M0 512h512V0H0Z"
                          data-original="#000000"
                        ></path>
                      </clipPath>
                    </defs>
                    <g
                      clipPath="url(#a)"
                      transform="matrix(1.33 0 0 -1.33 0 682.667)"
                    >
                      <path
                        fill="none"
                        strokeMiterlimit="10"
                        strokeWidth="40"
                        d="M452 444H60c-22.091 0-40-17.909-40-40v-39.446l212.127-157.782c14.17-10.54 33.576-10.54 47.746 0L492 364.554V404c0 22.091-17.909 40-40 40Z"
                        data-original="#000000"
                      ></path>
                      <path
                        d="M472 274.9V107.999c0-11.027-8.972-20-20-20H60c-11.028 0-20 8.973-20 20V274.9L0 304.652V107.999c0-33.084 26.916-60 60-60h392c33.084 0 60 26.916 60 60v196.653Z"
                        data-original="#000000"
                      ></path>
                    </g>
                  </svg>
                </div>
              </Form.Item>
            </div>

            <div className="mt-6">
              <Form.Item<FieldType>
                label="رقم الهاتف"
                name="phone"
                rules={[
                  { required: false, message: "Please input your phone!" },
                ]}
              >
                <div className="relative flex items-center">
                  <input
                    name="رقم الهاتف"
                    type="text"
                    required
                    className="w-full bg-transparent text-sm border-[1px] rounded-[10px] border-gray-300 focus:border-blue-500 px-2 py-2 outline-none"
                    placeholder="Enter email"
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#bbb"
                    stroke="#bbb"
                    className="w-[18px] h-[18px] absolute left-2"
                    viewBox="0 0 682.667 682.667"
                  >
                    <defs>
                      <clipPath id="a" clipPathUnits="userSpaceOnUse">
                        <path
                          d="M0 512h512V0H0Z"
                          data-original="#000000"
                        ></path>
                      </clipPath>
                    </defs>
                    <g
                      clipPath="url(#a)"
                      transform="matrix(1.33 0 0 -1.33 0 682.667)"
                    >
                      <path
                        fill="none"
                        strokeMiterlimit="10"
                        strokeWidth="40"
                        d="M452 444H60c-22.091 0-40-17.909-40-40v-39.446l212.127-157.782c14.17-10.54 33.576-10.54 47.746 0L492 364.554V404c0 22.091-17.909 40-40 40Z"
                        data-original="#000000"
                      ></path>
                      <path
                        d="M472 274.9V107.999c0-11.027-8.972-20-20-20H60c-11.028 0-20 8.973-20 20V274.9L0 304.652V107.999c0-33.084 26.916-60 60-60h392c33.084 0 60 26.916 60 60v196.653Z"
                        data-original="#000000"
                      ></path>
                    </g>
                  </svg>
                </div>
              </Form.Item>
            </div>
            <div className="mt-6">
              <Form.Item<FieldType>
                label="كلمه السر"
                name="password"
                rules={[
                  { required: false, message: "Please input your country!" },
                ]}
              >
                <div className="relative flex items-center">
                  <Input
                    placeholder="كلمه السر"
                    className="w-full text-sm border-[1px] rounded-[10px] border-gray-300 focus:border-blue-500 px-2 py-2 outline-none"
                  />
                  <svg
                    width="28"
                    height="30"
                    className="w-[18px] h-[18px] absolute left-2 cursor-pointer"
                    viewBox="0 0 28 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.9077 21.25C13.3025 21.25 12.7221 20.9866 12.2941 20.5178C11.8661 20.0489 11.6257 19.413 11.6257 18.75C11.6257 17.3625 12.6412 16.25 13.9077 16.25C14.513 16.25 15.0934 16.5134 15.5214 16.9822C15.9494 17.4511 16.1898 18.087 16.1898 18.75C16.1898 19.413 15.9494 20.0489 15.5214 20.5178C15.0934 20.9866 14.513 21.25 13.9077 21.25ZM20.7539 25V12.5H7.06159V25H20.7539ZM20.7539 10C21.3591 10 21.9396 10.2634 22.3676 10.7322C22.7955 11.2011 23.036 11.837 23.036 12.5V25C23.036 25.663 22.7955 26.2989 22.3676 26.7678C21.9396 27.2366 21.3591 27.5 20.7539 27.5H7.06159C6.45635 27.5 5.87591 27.2366 5.44794 26.7678C5.01997 26.2989 4.77954 25.663 4.77954 25V12.5C4.77954 11.1125 5.79505 10 7.06159 10H8.20262V7.5C8.20262 5.8424 8.80369 4.25269 9.87361 3.08058C10.9435 1.90848 12.3947 1.25 13.9077 1.25C14.657 1.25 15.3988 1.41166 16.091 1.72575C16.7832 2.03984 17.4121 2.50022 17.9419 3.08058C18.4717 3.66095 18.8919 4.34994 19.1786 5.10823C19.4653 5.86651 19.6129 6.67924 19.6129 7.5V10H20.7539ZM13.9077 3.75C12.9999 3.75 12.1292 4.14509 11.4873 4.84835C10.8453 5.55161 10.4847 6.50544 10.4847 7.5V10H17.3308V7.5C17.3308 6.50544 16.9702 5.55161 16.3282 4.84835C15.6863 4.14509 14.8156 3.75 13.9077 3.75Z"
                      fill="#471AA0"
                    />
                  </svg>
                </div>
              </Form.Item>
            </div>

            <div className="mt-6">
              <Form.Item<FieldType>
                label="إعاده كلمه السر"
                name="confirmPassword"
                rules={[
                  { required: false, message: "Please input your country!" },
                ]}
              >
                <div className="relative flex items-center">
                  <Input
                    placeholder="إعاده كلمه السر"
                    className="w-full text-sm border-[1px] rounded-[10px] border-gray-300 focus:border-blue-500 px-2 py-2 outline-none"
                  />
                  <svg
                    width="28"
                    height="30"
                    className="w-[18px] h-[18px] absolute left-2 cursor-pointer"
                    viewBox="0 0 28 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.9077 21.25C13.3025 21.25 12.7221 20.9866 12.2941 20.5178C11.8661 20.0489 11.6257 19.413 11.6257 18.75C11.6257 17.3625 12.6412 16.25 13.9077 16.25C14.513 16.25 15.0934 16.5134 15.5214 16.9822C15.9494 17.4511 16.1898 18.087 16.1898 18.75C16.1898 19.413 15.9494 20.0489 15.5214 20.5178C15.0934 20.9866 14.513 21.25 13.9077 21.25ZM20.7539 25V12.5H7.06159V25H20.7539ZM20.7539 10C21.3591 10 21.9396 10.2634 22.3676 10.7322C22.7955 11.2011 23.036 11.837 23.036 12.5V25C23.036 25.663 22.7955 26.2989 22.3676 26.7678C21.9396 27.2366 21.3591 27.5 20.7539 27.5H7.06159C6.45635 27.5 5.87591 27.2366 5.44794 26.7678C5.01997 26.2989 4.77954 25.663 4.77954 25V12.5C4.77954 11.1125 5.79505 10 7.06159 10H8.20262V7.5C8.20262 5.8424 8.80369 4.25269 9.87361 3.08058C10.9435 1.90848 12.3947 1.25 13.9077 1.25C14.657 1.25 15.3988 1.41166 16.091 1.72575C16.7832 2.03984 17.4121 2.50022 17.9419 3.08058C18.4717 3.66095 18.8919 4.34994 19.1786 5.10823C19.4653 5.86651 19.6129 6.67924 19.6129 7.5V10H20.7539ZM13.9077 3.75C12.9999 3.75 12.1292 4.14509 11.4873 4.84835C10.8453 5.55161 10.4847 6.50544 10.4847 7.5V10H17.3308V7.5C17.3308 6.50544 16.9702 5.55161 16.3282 4.84835C15.6863 4.14509 14.8156 3.75 13.9077 3.75Z"
                      fill="#471AA0"
                    />
                  </svg>
                </div>
              </Form.Item>
            </div>

            <Form.Item className="mt-[100px]">
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default Registration;
