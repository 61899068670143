import Hero1 from "../../assets/Hero1.svg";
import Hero2 from "../../assets/Hero2.svg";

function Hero() {
  return (
    <div className="">
      <div className="container grid grid-cols-1 gap-5 md:gap-10">
        <div className="grid grid-cols-1 md:grid-cols-3 justify-center gap-5 items-center border-[3px] px-5 py-10 rounded-b-3xl shadow-lg">
          <img
            src={Hero1}
            alt="hero"
            className="rounded-full md:rounded-none w-[150px] md:w-auto"
          />
          <h2 className="md:col-span-2 text-2xl md:text-4xl font-bold text-[#180337] md:leading-[3rem]">
            مرحبا بكم فى منصه حجوزاتى لتقديم الخدمات منصه حجوزاتى هى المنصه
            الاولى فى المملكه تجمع كل المناسبات واجتماعات الاصدقاء والشله فى
            مكان واحد
          </h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 justify-center gap-5 items-center border-[3px] px-5 py-10 rounded-b-3xl shadow-lg">
          <img
            src={Hero2}
            alt="hero"
            className="rounded-full md:rounded-none w-[150px] md:w-auto"
          />
          <h2 className="md:col-span-2 text-2xl md:text-4xl font-bold text-[#180337] md:leading-[3rem]">
            بعيونا راح تراها أجمل
            <div className="mt-5 md:mt-10"></div>
            ريح بالك ولا تحتار وفرنا لك قائمه متنوعه من الخدمات ومزودى الخدمه
            لاتشيل هم
          </h2>
        </div>
      </div>
    </div>
  );
}

export default Hero;
