import { Button, Modal, Form, message, Input } from "antd";
import { useState } from "react";
import { useAddGovernmentMutation } from "../../../redux/Service/api";

function AddGovernment() {
  const [form] = Form.useForm();
  const [addGovernmentModal, setAddGovernmentModal] = useState(false);
  const [modalKey, setModalKey] = useState(0);

  const [addGovernment, { isLoading }] = useAddGovernmentMutation(); // Replace with your government creation mutation

  const handleGovernment = async (values: any) => {
    try {
      const res = await addGovernment({
        name: values.nameAR,
        name_en: values.nameEN,
        country_id: 1,
      }).unwrap();
      form.resetFields();
      setModalKey((prev) => prev + 1);
      message.success(res?.message || "Government added successfully");
      setAddGovernmentModal(false);
    } catch (error) {
      console.error("Error adding government:", error);
      message.error("An error occurred while adding the government.");
    }
  };

  return (
    <>
      <div className="text-center my-3">
        <Button
          className="w-[150px]"
          type="primary"
          onClick={() => setAddGovernmentModal(true)}
        >
          إضافة منطقة
        </Button>
      </div>
      <Modal
        key={modalKey}
        title="إضافة منطقة"
        style={{ top: 20 }}
        open={addGovernmentModal}
        onCancel={() => setAddGovernmentModal(false)}
        width={500} // Adjust width as needed
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleGovernment}
          initialValues={{
            nameAR: "",
            nameEN: "",
          }}
        >
          <Form.Item
            label="اسم المنطقة عربي"
            name="nameAR"
            rules={[
              { required: true, message: "Please enter the Arabic name" },
            ]}
          >
            <Input placeholder="إدخال الاسم العربي" />
          </Form.Item>
          <Form.Item
            label="اسم المنطقة (لغة أخرى)"
            name="nameEN"
            rules={[
              {
                required: true,
                message: "Please enter the name in another language",
              },
            ]}
          >
            <Input placeholder="إدخال الاسم باللغة الإنجليزية (اختياري)" />
          </Form.Item>
          <div className="flex justify-end">
            <Button htmlType="submit" type="primary" loading={isLoading}>
              حفظ
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}

export default AddGovernment;
