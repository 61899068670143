import Heading from "../../../Components/Heading/Heading";
import ModalIncomingTransfer from "./Modal/ModalIncomingTransfer";
import TableServiceSettings from "./TableIncomingTransfer";

function IncomingTransfer() {
  return (
    <>
      <Heading heading="تحويل وارد" />
      <ModalIncomingTransfer />
      <TableServiceSettings />
    </>
  );
}

export default IncomingTransfer;
