import { CloseOutlined } from "@ant-design/icons";
import { RootState } from "../../redux/store";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  useGetLogedUserQuery,
  useLogoutMutation,
} from "../../redux/Service/api";
import {
  reduxLogin,
  reduxLogout,
  saveUserData,
} from "../../redux/Slices/authSlice";
import { useDispatch } from "react-redux";
import { Button, Spin } from "antd";

function Navbar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated, userData } = useSelector((state: any) => state.auth);
  console.log("isAuthenticated", isAuthenticated);

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);

  const [logout, { isLoading: logoutLoading, isError }] = useLogoutMutation();

  const {
    data,
    isError: getUserError,
    isLoading,
    refetch,
  } = useGetLogedUserQuery(undefined);

  const handleLogoutClick = async () => {
    try {
      await logout(undefined);
      refetch();
      dispatch(reduxLogout());
      navigate("/");
      window.location.reload();
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  useEffect(() => {
    if (!isAuthenticated && data && data?.status == 1) {
      console.log("data", data?.data?.user);
      // dispatch(reduxLogin(data?.data?.user));
      dispatch(saveUserData(data?.data?.user));
    }
  }, [dispatch, isAuthenticated, isLoading]);

  return (
    <header className="bg-[#294BA1E0] sticky top-0 z-50 w-full items-center ">
      <div className="container flex h-16 items-center gap-8">
        <button
          onClick={toggleMenu}
          className="block rounded bg-gray-100 p-2.5 text-gray-600 transition hover:text-gray-600/75 md:hidden"
        >
          <span className="sr-only">Toggle menu</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="size-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>

        <div className="flex flex-1 items-center justify-end md:justify-between">
          <nav
            aria-label="Global"
            className={`${
              isOpen ? "block" : "hidden"
            } md:flex h-[100vh] md:h-auto fixed md:relative top-0 left-0 w-[250px] md:w-auto bg-slate-600 md:bg-transparent py-6 px-4 md:p-0`}
          >
            <div
              onClick={toggleMenu}
              className="md:hidden absolute top-3 left-3 cursor-pointer w-[25px] h-[25px] rounded bg-slate-400 hover:bg-slate-300 transition-all flex items-center justify-center"
            >
              <CloseOutlined className="text-lg text-white pointer-events-auto" />
            </div>
            <ul className="flex-col md:flex-row flex md:items-center gap-6 md:gap-8 text-md text-white font-medium">
              <li>
                <Link
                  className="py-3 px-2 md:py-0 md:px-0 w-full block transition hover:text-lime-600"
                  to="/"
                >
                  الصفحه الرئسيه
                </Link>
              </li>

              <li>
                <Link
                  className="py-3 px-2 md:py-0 md:px-0 w-full block transition hover:text-lime-600"
                  to="ourservices"
                >
                  جميع خدماتنا
                </Link>
              </li>

              <li>
                <Link
                  className="py-3 px-2 md:py-0 md:px-0 w-full block transition hover:text-lime-600"
                  to="aboutUs"
                >
                  عن المنصه
                </Link>
              </li>

              <li>
                <Link
                  className="py-3 px-2 md:py-0 md:px-0 w-full block transition hover:text-lime-600"
                  to="myOrders"
                >
                  حجوزاتى المفضله
                </Link>
              </li>

              <li>
                <Link
                  className="py-3 px-2 md:py-0 md:px-0 w-full block transition hover:text-lime-600"
                  to="contactUs"
                >
                  تواصل معنا
                </Link>
              </li>
              <li>
                {isAuthenticated === true && userData?.role === "admin" && (
                  <Link
                    className="py-3 px-2 md:py-0 md:px-0 w-full7 block transition hover:text-lime-600"
                    to="/Dashboard"
                  >
                    لوحه التحكم
                  </Link>
                )}
              </li>
              <li>
                {isAuthenticated === true && userData?.role === "provider" && (
                  <Link
                    className="py-3 md:py-0 md:px-0 w-full7 block transition hover:text-lime-600"
                    to="/myServices"
                  >
                    الخدمات الخاصة بي
                  </Link>
                )}
              </li>
            </ul>
          </nav>

          <div className="flex items-center gap-4">
            {isLoading ? (
              <Spin />
            ) : isAuthenticated === true ? (
              <>
                <Link
                  className="block rounded-md sm:text-[12px] bg-teal-600 px-5 py-2.5 text-sm font-medium text-white transition hover:bg-teal-700"
                  to="/profile"
                >
                  ملفى الشخصى
                </Link>
                <Button
                  loading={logoutLoading}
                  onClick={handleLogoutClick}
                  className=" rounded-md sm:text-[12px] bg-gray-100 px-5 py-2.5 text-sm font-medium text-teal-600 transition hover:text-teal-600/75 sm:block"
                >
                  تسجيل خروج
                </Button>
              </>
            ) : (
              <Link
                className="block rounded-md bg-teal-600 px-5 py-2.5 text-sm font-medium text-white transition hover:bg-teal-700"
                to="/Login"
              >
                تسجيل الدخول
              </Link>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}

export default Navbar;
