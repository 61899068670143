import Heading from "../../../Components/Heading/Heading";
import ModalOutgoingTransfer from "./Modal/ModalOutgoingTransfer";
import TableOutgoingTransfer from "./TableOutgoingTransfer";

function OutgoingTransfer() {
  return (
    <>
      <Heading heading="تحويل صادر" />
      <ModalOutgoingTransfer />
      <TableOutgoingTransfer />
    </>
  );
}

export default OutgoingTransfer;
