import React, { useState } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Button, message, Modal, Spin, Table } from "antd";
import {
  useDeleteArticleMutation,
  useGetAllArticlesQuery,
} from "../../../redux/Service/api";
import AddArticle from "./AddArticle";
import EditArticle from "./EditArticle";
import ServerError from "../../../Components/utils/ServerError";
import Heading from "../../../Components/Heading/Heading";

const { confirm } = Modal;

function ArticleSettings() {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [articleIdForEdit, setArticleIdForEdit] = useState(null);
  const [rerenderEditKey, setRerenderEditKey] = useState(0);

  const {
    data: allArticlesData,
    isError: isAllArticlesError,
    isLoading: isAllArticlesLoading,
  } = useGetAllArticlesQuery(undefined);

  const allArticles = allArticlesData?.data ?? [];

  const [deleteArticle, { isLoading: delIsLoading }] =
    useDeleteArticleMutation();

  const handleDeleteArticle = async (id: any) => {
    confirm({
      title: "هل تريد حذف هذا المقال؟",
      icon: <ExclamationCircleOutlined />,
      content: "سيتم حذف المقال عند الضغط على زر موافق.",
      async onOk() {
        try {
          const res = await deleteArticle(id);
          res.data.message
            ? message.info(res.data.message)
            : message.success("تم حذف المقال بنجاح");
        } catch (error) {
          console.error(error);
          message.error("فشل في حذف المقال.");
        }
      },
    });
  };

  const columns = [
    {
      title: "الصورة",
      dataIndex: "photo",
      key: "photo",
      render: (photo: any) => (
        <img src={photo} alt="article" className="w-24 h-16 object-cover" />
      ),
    },
    {
      title: "العنوان",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "المحتوى",
      dataIndex: "content",
      key: "content",
      ellipsis: true,
    },
    {
      title: "الإجراءات",
      key: "actions",
      render: (_: any, record: any) => (
        <div className="flex space-x-4 space-x-reverse">
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={() => handleDeleteArticle(record.id)}
          >
            حذف
          </Button>
          <Button
            icon={<EditOutlined />}
            onClick={() => {
              setOpenEditModal(true);
              setArticleIdForEdit(record.id);
              setRerenderEditKey((prev) => prev + 1);
            }}
          >
            تعديل
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="gap-3 relative p-4" dir="rtl">
      <Heading heading="المقالات" />

      {isAllArticlesError && <ServerError />}

      <div className="text-center my-3">
        <Button
          className="w-[150px]"
          type="primary"
          onClick={() => setOpenAddModal(true)}
        >
          إضافة مقال
        </Button>
      </div>

      <Table
        dataSource={allArticles}
        columns={columns}
        rowKey="id"
        pagination={{ pageSize: 5 }}
        loading={isAllArticlesLoading}
        className="bg-white shadow-md rounded-lg"
      />

      <EditArticle
        setOpenEditModal={setOpenEditModal}
        openEditModal={openEditModal}
        articleIdForEdit={articleIdForEdit}
        rerenderEditKey={rerenderEditKey}
      />

      <AddArticle
        setOpenAddModal={setOpenAddModal}
        openAddModal={openAddModal}
      />
    </div>
  );
}

export default ArticleSettings;
