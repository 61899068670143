import { Swiper, SwiperSlide } from "swiper/react";
import Hero3 from "../../assets/Hero3.svg";

import "swiper/css";
import "swiper/css/effect-fade";
// import "swiper/css/navigation";
import "swiper/css/pagination";
// import "./style.css";

import { Autoplay, EffectFade, Navigation, Pagination } from "swiper/modules";
function SliderHero() {
  const text = "نعدك بتجربه رائعه لاتنساها";
  const words = text.split(" ");

  return (
    <div className="container">
      <Swiper
        spaceBetween={30}
        effect={"fade"}
        loop={true}
        navigation={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
          dynamicBullets: true,
        }}
        modules={[Autoplay, EffectFade, Pagination]}
        className="mySwiper w-[80%] mt-5 md:mt-10"
      >
        <SwiperSlide className="">
          <div
            className="mx-auto rounded-xl grid items-center md:h-[400px]"
            style={{
              backgroundImage: `url(${Hero3})`,
              width: "80%",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <h1
              className="text-xl text-start md:text-5xl px-8 py-4 md:py-0 font-medium grid grid-cols-1 items-center md:gap-5 
          text-[#180337]"
            >
              {words.map((word, index) => (
                <span key={index} className="block">
                  {word}
                </span>
              ))}
            </h1>
          </div>
        </SwiperSlide>
        <SwiperSlide className="">
          <div
            className="mx-auto rounded-xl grid items-center md:h-[400px]"
            style={{
              backgroundImage: `url(${Hero3})`,
              width: "80%",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <h1
              className="text-xl text-start md:text-5xl px-8 py-4 md:py-0 font-medium grid grid-cols-1 items-center md:gap-5 
          text-[#180337]"
            >
              {words.map((word, index) => (
                <span key={index} className="block">
                  {word}
                </span>
              ))}
            </h1>
          </div>
        </SwiperSlide>
        <SwiperSlide className="">
          <div
            className="mx-auto rounded-xl grid items-center md:h-[400px]"
            style={{
              backgroundImage: `url(${Hero3})`,
              width: "80%",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <h1
              className="text-xl text-start md:text-5xl px-8 py-4 md:py-0 font-medium grid grid-cols-1 items-center md:gap-5 
          text-[#180337]"
            >
              {words.map((word, index) => (
                <span key={index} className="block">
                  {word}
                </span>
              ))}
            </h1>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

export default SliderHero;
