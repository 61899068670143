import {
  Button,
  Input,
  Modal,
  Space,
  Table,
  TableColumnsType,
  message,
} from "antd";
import { useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { SearchOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import {
  useDeleteCategoryMutation,
  useGetAllCategoriesQuery,
} from "../../../redux/Service/api";
const { confirm } = Modal;
const TableTypesOfServices = ({
  setOpenEditModal,
  setCategoryIdForEdit,
}: any) => {
  const {
    data: allCategoriessData,
    isError: isAllCategoriesError,
    isLoading: isAllCategoriesLoading,
  } = useGetAllCategoriesQuery(undefined);

  const [deleteCategory, { isLoading: delIsLoading }] =
    useDeleteCategoryMutation();

  const allCategory = allCategoriessData?.data?.data ?? [];

  const [filteredInfo, setFilteredInfo] = useState<any>({});
  const [sortedInfo, setSortedInfo] = useState<any>({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: () => void,
    dataIndex: string
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const handleDeleteServiceType = async (id: any) => {
    confirm({
      title: "هل أنت متأكد أنك تريد حذف هذا النوع من الخدمة؟",
      icon: <ExclamationCircleFilled />,
      content: "عند النقر على موافق، سيتم إزالة هذا النوع من الخدمة.",
      async onOk() {
        try {
          const res = await deleteCategory(id);
          res.data.message
            ? message.info(res.data.message)
            : message.success("تم حذف نوع الخدمة بنجاح.");
        } catch (error) {
          console.log(error);
        }
      },
    });
  };

  const getColumnSearchProps = (dataIndex: string): any => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }: any) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button type="link" size="small" onClick={() => close()}>
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value: string, record: any) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    render: (text: string) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text || ""}
        />
      ) : (
        text
      ),
  });

  const columns: TableColumnsType<any> = [
    {
      title: "م",
      dataIndex: "id",
      key: "id",
      filteredValue: filteredInfo.name || null,
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === "id" ? sortedInfo.order : null,
      ellipsis: true,
      width: 70,
    },
    {
      title: "نوع الخدمه",
      dataIndex: "name",
      key: "name",
      filteredValue: filteredInfo.name || null,
      ...getColumnSearchProps("name"),
      ellipsis: true,
      width: 150,
    },
    {
      title: "الصورة",
      dataIndex: "image",
      key: "image",
      width: 130,
      render: (image: string) =>
        image ? (
          <img
            src={image}
            alt="service"
            style={{ width: 50, height: 50, objectFit: "cover" }}
          />
        ) : (
          "لا توجد صورة"
        ),
      ellipsis: true,
    },

    // {
    //   title: "المدينه",
    //   dataIndex: "cities",
    //   key: "cities",
    //   render: (cities: any[]) => cities.map((city) => city.name).join(", "),
    //   ellipsis: true,
    //   width: 150,
    // },
    // {
    //   title: "الحكومة",
    //   dataIndex: "government",
    //   key: "government",
    //   render: (government: string | null) => government || "لا توجد حكومة",
    //   ellipsis: true,
    //   width: 150,
    // },
    {
      title: "تاريخ الإنشاء",
      dataIndex: "created_at",
      key: "created_at",
      ellipsis: true,
      width: 150,
    },
    {
      title: "تاريخ التحديث",
      dataIndex: "updated_at",
      key: "updated_at",
      ellipsis: true,
      width: 150,
    },
    {
      title: "الإجراءات",
      key: "actions",
      render: (_, category) => (
        <div className="grid grid-cols-2 items-center justify-center gap-2">
          <Button
            onClick={() => {
              setOpenEditModal(true);
              setCategoryIdForEdit(category.id);
            }}
            type="primary"
          >
            تعديل
          </Button>
          <Button
            type="primary"
            danger
            onClick={() => handleDeleteServiceType(category.id)}
          >
            حذف
          </Button>
        </div>
      ),
      width: 130,
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={allCategory}
      loading={isAllCategoriesLoading}
      onChange={(pagination, filters, sorter) => {
        setFilteredInfo(filters);
        setSortedInfo(sorter);
      }}
      bordered
      rowKey="id"
      pagination={{
        showSizeChanger: true,
        pageSizeOptions: [9, 10, 20, 30, 50],
        showTotal: (total) => `الإجمالي: ${total}`,
      }}
      scroll={{ y: 400, x: 500 }}
    />
  );
};

export default TableTypesOfServices;
