import React from "react";
import { useNavigate } from "react-router";
import { RightOutlined } from "@ant-design/icons";

const NavigateBack = ({ url = -1 }: any) => {
  const navigate = useNavigate();
  return (
    <RightOutlined
      className="text-[18px] cursor-pointer"
      onClick={() => navigate(url)}
    />
  );
};

export default NavigateBack;
