import React, { useState } from "react";
import { Modal, Upload, message, Button, Input } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useAddNewsMutation } from "../../../redux/Service/api";

const { TextArea } = Input;

const AddNews = ({ setOpenAddModal, openAddModal }) => {
  const [modalKey, setModalKey] = useState(0);
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState("");
  const [titleEN, setTitleEN] = useState("");
  const [content, setContent] = useState("");
  const [contentEN, setContentEN] = useState("");

  const [addNews, { isLoading, isError, error }] = useAddNewsMutation();

  const handleFileChange = (fileList) => {
    if (fileList.length > 0) {
      setFile(fileList[0].originFileObj);
    } else {
      setFile(null);
    }
  };

  const handleAddNews = async () => {
    if (!file) {
      message.error("يرجى تحميل الصورة");
      return;
    }
    if (!title) {
      message.error("يرجى إدخال عنوان الخبر");
      return;
    }
    if (!titleEN) {
      message.error("يرجى إدخال العنوان باللغة الإنجليزية");
      return;
    }
    if (!content) {
      message.error("يرجى إدخال محتوى الخبر");
      return;
    }
    if (!contentEN) {
      message.error("يرجى إدخال المحتوى باللغة الإنجليزية");
      return;
    }

    const formData = new FormData();
    formData.append("photo", file);
    formData.append("title", title);
    formData.append("title_en", titleEN);
    formData.append("content", content);
    formData.append("content_en", contentEN);

    try {
      // Replace with your addNews API call
      const res = await addNews(formData);
      setFile(null);
      setTitle("");
      setTitleEN("");
      setContent("");
      setContentEN("");
      setModalKey((prev) => prev + 1);
      res.data.message
        ? message.info(res.data.message)
        : message.success("تمت إضافة الخبر بنجاح");
      setOpenAddModal(false);
    } catch (error) {
      console.error("Error adding news:", error);
      message.error("حدث خطأ أثناء إضافة الخبر. حاول مرة أخرى.");
    }
  };

  return (
    <Modal
      key={modalKey}
      title="إضافة خبر"
      centered
      open={openAddModal}
      onCancel={() => setOpenAddModal(false)}
      width={800}
      footer={null}
    >
      <div className="space-y-6">
        <Input
          placeholder="عنوان الخبر"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="w-full"
        />
        <Input
          placeholder="العنوان باللغة الإنجليزية"
          value={titleEN}
          onChange={(e) => setTitleEN(e.target.value)}
          className="w-full"
        />
        <TextArea
          placeholder="محتوى الخبر"
          rows={4}
          value={content}
          onChange={(e) => setContent(e.target.value)}
          className="w-full"
        />
        <TextArea
          placeholder="المحتوى باللغة الإنجليزية"
          rows={4}
          value={contentEN}
          onChange={(e) => setContentEN(e.target.value)}
          className="w-full"
        />
        <Upload
          customRequest={({ file, onSuccess, onError }) => {
            try {
              onSuccess();
            } catch (error) {
              console.error("Error handling file:", error);
            }
          }}
          onChange={({ fileList }) => {
            handleFileChange(fileList);
          }}
          listType="picture"
          maxCount={1}
        >
          <Button icon={<UploadOutlined />}>تحميل صورة (حد أقصى: 1)</Button>
        </Upload>
        <div className="text-right">
          <Button type="primary" onClick={handleAddNews}>
            إضافة الخبر
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AddNews;
