import { Button, Modal } from "antd";
import { useState } from "react";
import AddCommissionSettings from "./AddCommissionSettings";

function ModalCommissionSettings() {
  const [openModalCustomerCodes, setOpenModalCustomerCodes] = useState(false);

  return (
    <>
      <div className="text-center my-3">
        <Button
          className="w-[150px]"
          type="primary"
          onClick={() => setOpenModalCustomerCodes(true)}
        >
          اضافه
        </Button>
      </div>
      <Modal
        title="اضافه مستخدام"
        style={{ top: 20 }}
        open={openModalCustomerCodes}
        onOk={() => setOpenModalCustomerCodes(false)}
        onCancel={() => setOpenModalCustomerCodes(false)}
        width={1200}
        footer={false}
      >
        <AddCommissionSettings />
      </Modal>
    </>
  );
}

export default ModalCommissionSettings;
