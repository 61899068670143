import React, { useState } from "react";
import { DeleteFilled } from "@ant-design/icons";
import { Button, Modal, Table, message } from "antd";
import {
  useGetProvidersQuery,
  useDeleteProvidersMutation,
} from "../../../redux/Service/api";
import { ExclamationCircleFilled } from "@ant-design/icons";
import Heading from "../../../Components/Heading/Heading";
import ServerError from "../../../Components/utils/ServerError";
import dayjs from "dayjs";

interface Provider {
  id: number;
  ProviderName: string;
  Address: string;
  Phone: string;
  Mobile: string;
  Email: string;
  FirstBalDate: string;
}

const { confirm } = Modal;

const ServiceProviders: React.FC = () => {
  const [deleteProvider, { isLoading: delIsLoading }] =
    useDeleteProvidersMutation();

  const {
    data: ProvidersData,
    isError: isProvidersError,
    isLoading: isProvidersLoading,
  } = useGetProvidersQuery(undefined);

  const Providers: Provider[] = ProvidersData?.data ?? [];

  const handleDeleteProvider = (id: number) => {
    confirm({
      title: "هل أنت متأكد أنك تريد حذف هذا العميل؟",
      icon: <ExclamationCircleFilled />,
      content: "بالضغط على موافق سيتم حذف العميل بشكل دائم.",
      async onOk() {
        try {
          await deleteProvider(id);
          message.success("تم حذف العميل بنجاح");
        } catch (error) {
          console.error(error);
          message.error("فشل في حذف العميل.");
        }
      },
    });
  };

  const columns = [
    {
      title: "رقم العميل",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "اسم العميل",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "العنوان",
      dataIndex: "Address",
      key: "Address",
    },
    {
      title: "الهاتف",
      dataIndex: "phone",
      key: "phone",
    },
    // {
    //   title: "الجوال",
    //   dataIndex: "Mobile",
    //   key: "Mobile",
    // },
    {
      title: "البريد الإلكتروني",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "تاريخ التسجيل",
      dataIndex: "created_at",
      key: "created_at",
      render: (date: string) => dayjs(date).format("YYYY-MM-DD"),
    },
    {
      title: "الإجراءات",
      key: "actions",
      render: (_: any, Provider: Provider) => (
        <div className="flex gap-2">
          <Button
            type="text"
            icon={<DeleteFilled />}
            danger
            onClick={() => handleDeleteProvider(Provider.id)}
          />
        </div>
      ),
    },
  ];

  return (
    <section className="p-4 section_padding mt-0 pt-0 relative">
      <Heading heading="مزودي الخدمات" />

      {isProvidersError && <ServerError />}

      <div className="mx-auto">
        <Table
          bordered
          columns={columns}
          dataSource={Providers}
          rowKey="ProviderID"
          loading={isProvidersLoading || delIsLoading}
          // pagination={{ pageSize: 5 }}
          scroll={{ y: 450 }}
        />
      </div>
    </section>
  );
};

export default ServiceProviders;
