import { Swiper, SwiperSlide } from "swiper/react";
import imgeCarousel from "../../assets/e56a6d16-c1c2-4d4b-9945-8359f8465a82 2.svg";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
// import "swiper/css/navigation";
import "swiper/css/pagination";
import "./style.css";
// import required modules
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper/modules";
import { useGetBannersQuery } from "../../redux/Service/api";

function Carousel() {
  const { data, isLoading, isError } = useGetBannersQuery(undefined);

  const allBanners = data?.data ?? [];

  return (
    <>
      <Swiper
        spaceBetween={30}
        effect={"fade"}
        loop={true}
        navigation={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
          dynamicBullets: true,
        }}
        modules={[Autoplay, EffectFade, Pagination]}
        className="mySwiper"
      >
        {(isLoading || isError) && (
          <SwiperSlide className="relative">
            <img src={imgeCarousel} alt="Image Slider" />
            <div className="clip bg-[#3E1F98A8] left-0 w-[50%] h-full absolute flex justify-center items-center">
              <h1 className="text-base md:text-4xl font-black text-white">
                حجوزاتى معاك .. لاتشيل هم
              </h1>
            </div>
            <div className="absolute top-[50px] right-[100px] bg-[#3E1F98A8] py-3 px-14 rounded-bl-3xl hidden md:flex">
              <h1 className="text-base md:text-4xl font-black text-white">
                جمعنا شمل العيله والشله
              </h1>
            </div>
          </SwiperSlide>
        )}
        {allBanners?.map((banner: any) => {
          return (
            <SwiperSlide className="relative" key={"banner" + banner.id}>
              <img src={banner.photo} alt="Image Slider" />
              <div className="clip bg-[#3E1F98A8] left-0 w-[50%] h-full absolute flex justify-center items-center">
                <h1 className="text-base md:text-4xl font-black text-white">
                  حجوزاتى معاك .. لاتشيل هم
                </h1>
              </div>
              <div className="absolute top-[50px] right-[100px] bg-[#3E1F98A8] py-3 px-14 rounded-bl-3xl hidden md:flex">
                <h1 className="text-base md:text-4xl font-black text-white">
                  {banner.title}
                </h1>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
}

export default Carousel;
