import about1 from '../../assets/Png/about1.png';
import about2 from '../../assets/Png/about2.png';
import about3 from '../../assets/Png/about3.png';
const AboutUsGallary = () => {
	return (
		<div className='mt-10 flex flex-col h-full gap-8 mid:gap-5 container mb-8'>
			<div className={`w-[100%] !h-full flex justify-between  miilg:gap-8 mid:gap-5`}>
				<div className=' w-[30%] h-[420px] mid:h-[250px] midd:h-[250px] miilg:w-full'>
					<img src={about2} className='w-full h-full midd:object-cover' alt="" />
				</div>
				<div className='w-[67%] h-[420px] mid:h-[250px] midd:h-[250px] miilg:w-full rounded-br-[80px] mid:rounded-br-[40px]'>
					<img src={about1} className='w-full h-full rounded-br-[80px] midd:object-cover mid:rounded-br-[40px]' alt="" />
				</div>
			</div>
			<div className='w-[100%] h-[300px] mid:h-[200px] midd:h-[170px] mid:rounded-bl-[80px]'>
				<img className='w-[100%] h-full  rounded-bl-[80px] mid:rounded-bl-[40px] midd:object-cover' src={about3} alt="" />
			</div>
		</div>
	)
}

export default AboutUsGallary