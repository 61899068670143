import { Button, Input, message, Modal, Space, Table, Spin } from "antd";
import { useRef, useState } from "react";
import {
  PlusOutlined,
  SearchOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {
  useAdminGetAllUserQuery,
  useDeleteUserMutation,
} from "../../../redux/Service/api";

const { confirm } = Modal;

const TableSystemUsers = () => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<any>(null);

  const [deleteUser, { isLoading: delIsLoading }] = useDeleteUserMutation();

  const {
    data: allUsersData,
    isError: isUsersDataError,
    isLoading: isUsersDataLoading,
  } = useAdminGetAllUserQuery(undefined);

  const allUsers = allUsersData?.data ?? [];

  const data = allUsers?.map((user: any) => ({
    key: user.id,
    name: user.name,
    email: user.email,
    status: user.role,
    phone: user.phone,
    address: user.address,
  }));

  const handleSearch = (
    selectedKeys: string[],
    confirm: () => void,
    dataIndex: string
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }: any) => (
      <div className="p-2" onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`بحث في ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          className="mb-2"
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
          >
            بحث
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
          >
            إعادة تعيين
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            تصفية
          </Button>
          <Button type="link" size="small" onClick={close}>
            إغلاق
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value: string, record: any) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text: any) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns: any = [
    {
      title: "الاسم",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
      width: 150,
    },
    {
      title: "البريد الإلكتروني",
      dataIndex: "email",
      key: "email",
      ...getColumnSearchProps("email"),
      width: 130,
    },
    {
      title: "الدور",
      dataIndex: "status",
      key: "status",
      ...getColumnSearchProps("status"),
      width: 100,
    },
    {
      title: "الهاتف",
      dataIndex: "phone",
      key: "phone",
      ...getColumnSearchProps("phone"),
      width: 100,
    },
    {
      title: "الإجراءات",
      key: "actions",
      render: (userDataMapper: any) => (
        <div className="flex gap-x-2">
          <Button
            type="primary"
            danger
            ghost
            loading={delIsLoading}
            onClick={() => handleDeleteUserClick(userDataMapper.key)}
          >
            حذف
          </Button>
        </div>
      ),
      width: 100,
    },
  ];

  const handleDeleteUserClick = (id: string) => {
    confirm({
      title: "هل ترغب في حذف هذا المستخدم؟",
      icon: <ExclamationCircleFilled />,
      content: "عند النقر على زر OK، سيتم إزالة هذا المستخدم",
      async onOk() {
        try {
          const res = await deleteUser(id).unwrap();
          message.success(res.message || "تم حذف المستخدم بنجاح");
        } catch (error) {
          console.error(error);
          message.error("فشل حذف المستخدم.");
        }
      },
    });
  };

  return (
    <Table
      columns={columns}
      dataSource={data}
      loading={isUsersDataLoading}
      bordered
      pagination={{
        showSizeChanger: true,
        pageSizeOptions: [9, 10, 20, 30, 50],
        showTotal: (total) => `الإجمالي: ${total}`,
      }}
      scroll={{ y: 400, x: 500 }}
    />
  );
};

export default TableSystemUsers;
