import React, { useState } from "react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Modal, Table, message } from "antd";
import {
  useGetCustomersQuery,
  useDeleteCustomersMutation,
  useGetRequestsToBeProviderQuery,
  useChangeRequestToBeProviderMutation,
} from "../../../redux/Service/api";
import { ExclamationCircleFilled } from "@ant-design/icons";
import Heading from "../../../Components/Heading/Heading";
import ServerError from "../../../Components/utils/ServerError";
import dayjs from "dayjs";

const { confirm } = Modal;

const ClientToProvider: React.FC = () => {
  const [chacngeStatus, { isLoading: delIsLoading }] =
    useChangeRequestToBeProviderMutation();

  const {
    data: customersRequests,
    isError: isCustomersError,
    isLoading: isCustomersLoading,
  } = useGetRequestsToBeProviderQuery(undefined);

  const customers: any = customersRequests?.data ?? [];

  const handleDeclineRequest = (id: number) => {
    confirm({
      title: "هل أنت متأكد أنك تريد رفض هذا العميل؟",
      icon: <ExclamationCircleFilled />,
      content: "بالضغط على موافق سيتم رفض العميل بشكل دائم.",
      async onOk() {
        try {
          const formData = new FormData();
          formData.append("approve", "refused");
          await chacngeStatus({ id, formData });
          message.success("تم رفض العميل بنجاح");
        } catch (error) {
          console.error(error);
          message.error("فشل في رفض العميل.");
        }
      },
    });
  };
  const handleApproveRequest = (id: number) => {
    confirm({
      title: "هل أنت متأكد أنك تريد قبول هذا العميل؟",
      icon: <ExclamationCircleFilled />,
      content: "بالضغط على موافق سيتم قبول العميل  .",
      async onOk() {
        try {
          const formData = new FormData();
          formData.append("approve", "accepted");
          await chacngeStatus({ id, formData });
          message.success("تم قبول العميل بنجاح");
        } catch (error) {
          console.error(error);
          message.error("فشل في قبول العميل.");
        }
      },
    });
  };

  const columns = [
    {
      title: "رقم الطلب",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "اسم العميل",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "العنوان",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "الهاتف",
      dataIndex: "phone",
      key: "phone",
      width: 150,
    },
    {
      title: "عن العميل",
      dataIndex: "about",
      key: "about",
      width: 150,
    },
    // {
    //   title: "البريد الإلكتروني",
    //   dataIndex: "email",
    //   key: "email",
    // },
    {
      title: " رقم الهوية",
      dataIndex: "id_number",
      key: "id_number",
      width: 150,
    },
    {
      title: "تاريخ الهوية",
      dataIndex: "id_issue_date",
      key: "id_issue_date",
    },
    {
      title: "مكان الهوية",
      dataIndex: "id_issue_place",
      key: "id_issue_place",
    },
    {
      title: "الحالة",
      dataIndex: "approve",
      key: "approve",
    },
    {
      title: "تاريخ التسجيل",
      dataIndex: "created_at",
      key: "created_at",
      render: (date: string) => dayjs(date).format("YYYY-MM-DD"),
    },
    {
      title: "تاريخ التحديث",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (date: string) => dayjs(date).format("YYYY-MM-DD"),
    },
    {
      title: "الإجراءات",
      key: "actions",
      render: (_: any, request: any) => (
        <div className="flex gap-2">
          <Button
            type="text"
            icon={<CheckOutlined />}
            className="text-green-500"
            onClick={() => handleApproveRequest(request.id)}
          />
          <Button
            type="text"
            icon={<CloseOutlined />}
            danger
            onClick={() => handleDeclineRequest(request.id)}
          />
        </div>
      ),
    },
  ];

  return (
    <section className="p-4 section_padding mt-0 pt-0 relative">
      <Heading heading=" طلبات العملاء" />

      {isCustomersError && <ServerError />}

      <div className="mx-auto">
        <Table
          bordered
          columns={columns}
          dataSource={customers}
          rowKey="CustomerID"
          loading={isCustomersLoading || delIsLoading}
          // pagination={{ pageSize: 5 }}
          scroll={{ y: 450 }}
        />
      </div>
    </section>
  );
};

export default ClientToProvider;
