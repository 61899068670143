import { useNavigate } from "react-router";
import { Button, Form, Input, Select, Space, message } from "antd";
import {
  useRequestToBeProviderMutation,
  useGetCitiesQuery,
} from "../../redux/Service/api";

const { Option } = Select;

const WorkerLogin = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [requestToBeProvider, { isLoading, isError }] =
    useRequestToBeProviderMutation();

  // Fetch cities
  const {
    data: allCities,
    isLoading: isDataLoading,
    isError: isDataError,
  } = useGetCitiesQuery(undefined);

  const cities = allCities?.data?.data ?? [];

  const handleRequest = async (values: any) => {
    try {
      // Prepare the data to match the API requirements
      const requestData = {
        id_number: values.id_number,
        name: values.name,
        address: values.address,
        id_issue_date: values.id_issue_date,
        about: values.about,
        city_id: values.city_id,
        email: values.email,
        phone: values.phone,
        id_issue_place: values.id_issue_place,
      };

      // Send the request
      const response = await requestToBeProvider(requestData)
        .unwrap()
        .then((res) => {
          if (res.status === 0) {
            message.error(
              res.message || "فشل إرسال الطلب. الرجاء المحاولة مرة أخرى."
            );
          } else {
            message.success(res.message || "تم إرسال الطلب بنجاح!");
            navigate("/"); // Redirect to a success page
          }
        });

      // Handle success
    } catch (error) {
      // Handle error
      message.error("فشل إرسال الطلب. الرجاء المحاولة مرة أخرى.");
      console.error("Error submitting request:", error);
    }
  };

  return (
    <div className="container pt-12 pb-12 flex flex-col items-center">
      <h2 className=" mb-10 mt-5 text-center  gap-2 font-bold text-[25px] miilg:text-[20px] mid:text-[18px]">
        <p className="ani bg-[#110A62] mid:text-[15px] px-10 text-center text-[#ffff] mid:w-[80%] pt-3 pb-3 inline-block">
          سعداء بوجودك
        </p>
      </h2>
      <div className="w-[50%]">
        <Form
          form={form}
          name="validateOnly"
          layout="vertical"
          onFinish={handleRequest}
          // className="grid gap-5"
          autoComplete="off"
        >
          {/* ID Number */}
          <Form.Item
            name="id_number"
            label="رقم الهوية"
            rules={[{ required: true, message: "الرجاء إدخال رقم الهوية" }]}
          >
            <Input />
          </Form.Item>

          {/* Name */}
          <Form.Item
            name="name"
            label="الاسم الكامل"
            rules={[{ required: true, message: "الرجاء إدخال الاسم الكامل" }]}
          >
            <Input />
          </Form.Item>

          {/* Address */}
          <Form.Item
            name="address"
            label="العنوان"
            rules={[{ required: true, message: "الرجاء إدخال العنوان" }]}
          >
            <Input />
          </Form.Item>

          {/* ID Issue Date */}
          <Form.Item
            name="id_issue_date"
            label="تاريخ إصدار الهوية"
            rules={[
              { required: true, message: "الرجاء إدخال تاريخ إصدار الهوية" },
            ]}
          >
            <Input type="date" />
          </Form.Item>

          {/* About */}
          <Form.Item
            name="about"
            label="نبذة عنك"
            rules={[{ required: true, message: "الرجاء إدخال نبذة عنك" }]}
          >
            <Input.TextArea />
          </Form.Item>

          {/* City ID */}
          <Form.Item
            name="city_id"
            label="المدينة"
            rules={[{ required: true, message: "الرجاء اختيار المدينة" }]}
          >
            <Select
              placeholder="اختار المدينه"
              loading={isDataLoading} // Show loading state while fetching cities
            >
              {cities?.map((city: any) => (
                <Option key={city.id} value={city.id}>
                  {city.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {/* Email */}
          <Form.Item
            name="email"
            label="البريد الإلكتروني"
            rules={[
              { required: true, message: "الرجاء إدخال البريد الإلكتروني" },
              { type: "email", message: "البريد الإلكتروني غير صالح" },
            ]}
          >
            <Input />
          </Form.Item>

          {/* Phone */}
          <Form.Item
            name="phone"
            label="رقم الجوال"
            rules={[{ required: true, message: "الرجاء إدخال رقم الجوال" }]}
          >
            <Input />
          </Form.Item>

          {/* ID Issue Place */}
          <Form.Item
            name="id_issue_place"
            label="مكان إصدار الهوية"
            rules={[
              { required: true, message: "الرجاء إدخال مكان إصدار الهوية" },
            ]}
          >
            <Input />
          </Form.Item>

          {/* Submit Button */}
          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                تأكيد
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default WorkerLogin;
