import Heading from "../../../Components/Heading/Heading";
import TableCustomer from "./TableServiceProviderStatement";

function ServiceProviderStatement() {
  return (
    <>
      <Heading heading="كشف حساب" />
      <TableCustomer />
    </>
  );
}

export default ServiceProviderStatement;
